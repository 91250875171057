import React, { useState } from "react";
import {
  Card,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Modal,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import { formatDateCotacao } from "../../utils/formatDate";
import { ArrowDropDown, ListAlt } from "@mui/icons-material";
import Button from "../../components/buttons/PrimaryButton";
import { TextField } from "../../components/inputs";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/administracao.actions";
import { getIntegrar } from "../../selectors/administracao.selectors";

import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
  }
`;

const StyledTable = styled(Table)`
  max-height: 10px !important;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  cursor: pointer;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #b4cfe7ff;
    max-height: 10px;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
`;

const StyledTableButtonCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  cursor: default;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
  width: 300px;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 600px;
  margin-top: -130px;
`;

const StyledGridItem = styled(Grid)`
  padding: 5px !important;
  margin-bottom: 10px !important;
`;

const StyledGridButton = styled(Grid)`
  padding: 5px !important;
  justify-content: center !important;
  align-items: center;
  text-align: center;
`;

const StyledListAlt = styled(ListAlt)`
  color: #324873;
`;

const StyledBox = styled(Card)`
  outline: none;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 650px;
  background-color: white;
  border: 2px solid #324873 !important;
  padding: 15px;
  padding-top: 30px;
`;

const StyledButton = styled(Button)`
  height: 28px;
  width: 140px;
  margin-left: 10px;
  margin-right: 20px;
  font-size: 13px;
`;

const StyledButtonModal = styled(Button)`
  width: 200px;
`;

const StyledTextField = styled(TextField)`
background-color: #ffffff;
margin-left: -6px;
margin-bottom:10px;
margin-top:10px;
flex: 1!important;
font-family: ${({ theme }) => theme.fonts.family.subTitle};
color: ${({ theme }) => theme.colors.primary};
font-size: 17px;



@media (max-width: 900px) {
  margin-bottom:5px;
  margin-top:5px;
}



& .MuiInputBase-input{
  font-family: ${({ theme }) => theme.fonts.family.subTitle} !important;
  color: ${({ theme }) => theme.colors.primary} !important;
  font-size: 17px;
  -webkit-text-fill-color: #324873 !important;
  padding: 15px;


  }


& .MuiInputLabel-root {
background-color: #ffffff;
color: #849dce !important;

padding: 8px;
padding-top: 0px;
padding-bottom: 0px;
}

& .MuiFormHelperText-root.Mui-error {
color: #D11F44!important;

}
& .MuiOutlinedInput-root {
& fieldset {
  border-color: #849dce;
},
&:hover fieldset {
  border-color: #849dce;
},
&.Mui-focused fieldset {
  border: 3px solid #849dce;
},
&.Mui-disabled fieldset {
  border-color: #849dce;
  color: #849dce !important;

},
&.Mui-error fieldset {
border: 2.5px solid #D11F44;
},`;

const TabelaIntegracao = ({ data, loading }) => {
  const [open, setOpen] = useState(false);
  const [selectValue, setSelectValue] = useState("A");
  const [idCotacao, setIdCotacao] = useState("");
  const [idLoading, setIdLoading] = useState("");
  const handleClose = () => setOpen(false);
  const dispatch = useDispatch();
  const { loading: loadingIntegrar } = useSelector(getIntegrar);

  const handleVoltar = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setSelectValue(e.target.value);
  };

  const handleConfirmar = () => {
    if (selectValue === "B") {
      dispatch(
        actions.integrarManualmente.request({ id: idCotacao.ID_COTACAO })
      );
    }
    setSelectValue("A");
    setOpen(false);
  };

  if (loading) {
    return <StyledSkeleton />;
  }

  return (
    <>
      {data.length > 0 && (
        <StyledCard>
          <StyledH2>Propostas não integradas</StyledH2>
          <StyledTable>
            <TableBody>
              {data &&
                data.map((item, index) => {
                  const handleOpen = () => {
                    setIdCotacao(item);
                    setSelectValue("A");
                    setOpen(true);
                  };

                  const handleEnviar = () => {
                    setIdLoading(index);
                    setSelectValue("A");
                    dispatch(
                      actions.integrar.request({
                        QT_VIDAS_00_18: item.QT_VIDAS_00_18,
                        QT_VIDAS_19_23: item.QT_VIDAS_19_23,
                        QT_VIDAS_24_28: item.QT_VIDAS_24_28,
                        QT_VIDAS_29_33: item.QT_VIDAS_29_33,
                        QT_VIDAS_34_38: item.QT_VIDAS_34_38,
                        QT_VIDAS_39_43: item.QT_VIDAS_39_43,
                        QT_VIDAS_44_48: item.QT_VIDAS_44_48,
                        QT_VIDAS_49_53: item.QT_VIDAS_49_53,
                        QT_VIDAS_54_58: item.QT_VIDAS_54_58,
                        QT_VIDAS_59_99: item.QT_VIDAS_59_99,
                        NR_CNPJ: item.NR_CNPJ,
                        NM_EMPRESA: item.NM_EMPRESA,
                        NM_CONTATO: item.NM_CONTATO,
                        NR_AREA_CONTATO: item.NR_AREA_CONTATO,
                        NR_TELEFONE_CONTATO: item.NR_TELEFONE_CONTATO,
                        CD_EMAIL_CONTATO: item.CD_EMAIL_CONTATO,
                        VL_PROPOSTA: item.VL_PROPOSTA,
                        ID_COTACAO: item.ID_COTACAO,
                      })
                    );
                  };

                  const handleIrCotacao = () => {
                    dispatch(
                      routeActions.redirectTo(routes.DETALHES_COTACAO, {
                        id: item.ID_COTACAO,
                      })
                    );
                  };
                  return (
                    <StyledTableRow key={index}>
                      <StyledTableCell onClick={handleIrCotacao}>
                        #{item.ID_COTACAO}
                      </StyledTableCell>
                      <StyledTableCell onClick={handleIrCotacao}>
                        {formatDateCotacao(item.DT_COTACAO)}
                      </StyledTableCell>
                      <StyledTableCell onClick={handleIrCotacao}>
                        {item.NM_EMPRESA}
                      </StyledTableCell>
                      <StyledTableCell onClick={handleIrCotacao}>
                        {item.NM_SEGURADORA}
                      </StyledTableCell>
                      <StyledTableButtonCell align={"right"}>
                        <StyledButton
                          loading={
                            index === idLoading ? loadingIntegrar : false
                          }
                          onClick={handleEnviar}
                        >
                          Reenviar
                        </StyledButton>
                        <Tooltip title="Detalhes" placement="right-start" arrow>
                          <IconButton onClick={handleOpen}>
                            <StyledListAlt />
                          </IconButton>
                        </Tooltip>
                      </StyledTableButtonCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </StyledTable>
        </StyledCard>
      )}
      <Modal open={open} onClose={handleClose}>
        <StyledBox>
          <Grid container>
            <StyledGridItem item xs={12} sm={12} md={12}>
              <StyledTextField
                disabled
                multiline
                value={idCotacao?.DS_ERRO_INTEGRACAO ?? ""}
                fullWidth
                name="erro"
                label="Descrição do Erro"
              />
            </StyledGridItem>
            <StyledGridItem item xs={12} sm={12} md={12}>
              <StyledTextField
                value={selectValue}
                onChange={(e) => handleChange(e)}
                fullWidth
                name="situação"
                label="Situação"
                select
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ArrowDropDown
                        style={{
                          fontSize: "35px",
                          color: "#324873",
                          pointerEvents: "none",
                          position: "absolute",
                          left: "90%",
                        }}
                      />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem key={"A"} value={"A"}>
                  Erro na Integração
                </MenuItem>
                <MenuItem key={"B"} value={"B"}>
                  Incluído Manualmente
                </MenuItem>
              </StyledTextField>
            </StyledGridItem>
            <StyledGridButton item xs={12} sm={6} md={6}>
              <StyledButtonModal onClick={handleVoltar}>
                Voltar
              </StyledButtonModal>
            </StyledGridButton>
            <StyledGridButton item xs={12} sm={6} md={6}>
              <StyledButtonModal onClick={handleConfirmar}>
                CONFIRMAR
              </StyledButtonModal>
            </StyledGridButton>
          </Grid>
        </StyledBox>
      </Modal>
    </>
  );
};

export default TabelaIntegracao;
