import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  LISTAR_SEGURADORAS: "@@seguradora/LISTAR_SEGURADORAS",
  EDITAR_ANEXO_SEGURADORA: "@@seguradora/EDITAR_ANEXO_SEGURADORA",
  MOSTRAR_SEGURADORA: "@@seguradora/MOSTRAR_SEGURADORA",
  ENVIAR_ANEXO: "@@segurad/ENVIAR_ANEXO",
  ENVIAR_LOGO_CARD: "@@segurad/ENVIAR_LOGO_CARD",
  ATUALIZAR_CODIGO_INTEGRACAO: "@@segurad/ATUALIZAR_CODIGO_INTEGRACAO",
};

export const actions = {
  listarSeguradoras: createAsyncAction(types.LISTAR_SEGURADORAS),
  editarAnexoSeguradora: createAsyncAction(types.EDITAR_ANEXO_SEGURADORA),
  mostrarSeguradora: createAsyncAction(types.MOSTRAR_SEGURADORA),
  enviarLogoCard: createAsyncAction(types.ENVIAR_LOGO_CARD),
  enviarAnexo: createAsyncAction(types.ENVIAR_ANEXO),
  atualizarCodigoIntegracao: createAsyncAction(
    types.ATUALIZAR_CODIGO_INTEGRACAO
  ),
};
