import BasePage from "../BasePage";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../components";
import { Card, Grid, IconButton, styled } from "@mui/material";
import VerticalLinearStepper from "./StepperPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getCardFinal,
  getCotacaoDados,
  getCotacaoFinal,
} from "../../selectors/cotacao.selectors";
import Spinner from "../../components/spinner/Spinner";
import PropostaPlanoCard from "./PropostaPlanoCard";
import Carousel from "react-elastic-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { actions } from "../../reducers/cotacao.actions";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 4.8px;
  font-size: 48px;
  margin-bottom: 40px;
  @media only screen and (max-width: 1192px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledGrid = styled(Grid)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledButton = styled(PrimaryButton)`
  min-width: 200px;
  margin-bottom: 20px;
  @media only screen and (max-width: 1192px) {
    margin-top: 0px !important;
    margin-bottom: 20px;
  }
`;

const StyledCarousel = styled(Carousel)`
  @media only screen and (max-width: 1192px) {
    display: none !important;
  }
`;

const StyledCarousel2 = styled(Carousel)`
  @media only screen and (min-width: 1191px) {
    display: none !important;
  }

  & .rec.rec-dot_active {
    background-color: #415781ff;
    box-shadow: 0 0 1px 3px #415781ff;
  }
`;

const StyledIconButtonLeft = styled(IconButton)`
  height: 50px;
  width: 50px;
  position: absolute;
  top: 55%;
  z-index: 10000000;
`;
const StyledIconButtonRight = styled(IconButton)`
  height: 50px;
  width: 50px;
  position: absolute;
  top: 55%;
  left: 82%;
  z-index: 10000000;
`;
const StyledIconButtonLeftHorizon = styled(IconButton)`
  height: 50px;
  width: 50px;
  margin-top: 175px;
`;
const StyledIconButtonRightHorizon = styled(IconButton)`
  height: 50px;
  width: 50px;
  margin-top: 175px;
`;

const StyledCard = styled(Card)`
  border-radius: 15px;
  width: 900px;
  max-height: 150px;
  height: auto;
  background-color: #849dce;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media only screen and (max-width: 1192px) {
    max-height: 225px;
  }
`;

const StyledH3 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: white;
  letter-spacing: 3.7px;
  font-size: 34px;
  margin-left: 10px;
  margin-right: 10px;

  @media only screen and (max-width: 1192px) {
    font-size: 24px;
  }
`;
const EnvioDaPropostaPage = ({ setStep }) => {
  const { loading, data } = useSelector(getCotacaoFinal);
  const { data: dataCotacao } = useSelector(getCotacaoDados);
  const { data: dataCardFinal } = useSelector(getCardFinal);
  const [buttonAnimation, setButtonAnimation] = useState(true);

  const [middleCard, setMiddleCard] = useState(1);
  const dispatch = useDispatch();

  useEffect(() => {
    const cardData = data[1];
    if (cardData) {
      dispatch(actions.armazenarCardFinal(cardData[0]));
    }
    // eslint-disable-next-line
  }, [data]);

  if (loading) {
    return <Spinner />;
  }

  const handleClick = () => {
    if (middleCard > 0) {
      setButtonAnimation(false);
      setTimeout(() => {
        setButtonAnimation(true);
      }, 900);
    }
  };

  const handleSubmit = () => {
    dispatch(
      actions.criarCotacaoResultado.request({
        ID_COTACAO: dataCotacao.ID_COTACAO,
        ID_SEGURADORA_PLANO_VALOR: dataCardFinal.id_seguradora_plano_valor,
        TP_ENVIO_PROPOSTA: "E",
        VL_PROPOSTA: dataCardFinal.custo_total,
        ID_SEGURADORA_PROPOSTA: dataCardFinal.id_seguradora,
      })
    );
    dispatch(
      actions.enviarProposta.request({
        id_seguradora_plano_valor: dataCardFinal.id_seguradora_plano_valor,
        vidas_por_faixa: dataCardFinal.vidas_por_faixa,
        email: dataCotacao.CD_EMAIL_CONTATO,
        nome_cliente: dataCotacao.NM_CONTATO,
      })
    );
    setStep(4);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 500, itemsToShow: 1 },
    { width: 1, itemsToShow: 1 },
    { width: 1, itemsToShow: 1 },
    { width: 1, itemsToShow: 1 },
    { width: 1097, itemsToShow: 4, itemsToScroll: 4 },
  ];

  return (
    <BasePage isCard={true}>
      <Grid container>
        <StyledGrid item xs={12} md={2}>
          <VerticalLinearStepper setStep={setStep} activeStep={3} />
        </StyledGrid>
        {data.length > 0 && (
          <>
            <StyledGrid item xs={12} md={10}>
              <StyledH1>Melhores Opções</StyledH1>
              <Grid container spacing={0}>
                <StyledCarousel
                  initialActiveIndex={1}
                  easing={"cubic-bezier(0.11, 0, 0.5, 0)"}
                  focusOnSelect={true}
                  transitionMs={900}
                  showArrows={true}
                  renderArrow={({ type, onClick, isEdge }) => {
                    const handleLeftClick = () => {
                      setMiddleCard(middleCard - 1);
                      onClick();
                    };
                    const handleRightClick = () => {
                      setMiddleCard(middleCard + 1);
                      onClick();
                    };
                    const Pointer =
                      type === "PREV" ? (
                        <StyledIconButtonLeftHorizon
                          onClick={handleLeftClick}
                          disabled={isEdge}
                        >
                          <ChevronLeftIcon />
                        </StyledIconButtonLeftHorizon>
                      ) : (
                        <StyledIconButtonRightHorizon
                          onClick={handleRightClick}
                          disabled={isEdge}
                        >
                          <ChevronRightIcon />
                        </StyledIconButtonRightHorizon>
                      );
                    return Pointer;
                  }}
                  pagination={false}
                  breakPoints={breakPoints}
                  outerSpacing={280}
                >
                  {data.map((item, index) => {
                    return (
                      <PropostaPlanoCard
                        setMiddleCard={setMiddleCard}
                        buttonAnimation={buttonAnimation}
                        isMiddle={index === middleCard ? true : false}
                        key={index}
                        handleClick={handleClick}
                        data={item}
                        index={index}
                      />
                    );
                  })}
                </StyledCarousel>
                <StyledCarousel2
                  initialActiveIndex={1}
                  easing={"cubic-bezier(0.11, 0, 0.5, 0)"}
                  padding={[0, 10]}
                  focusOnSelect={true}
                  transitionMs={900}
                  showArrows={true}
                  pagination={false}
                  itemPosition={"center"}
                  breakPoints={breakPoints}
                  renderArrow={({ type, onClick, isEdge }) => {
                    const handleLeftClick = () => {
                      setMiddleCard(middleCard - 1);
                      onClick();
                    };
                    const handleRightClick = () => {
                      setMiddleCard(middleCard + 1);
                      onClick();
                    };

                    const Pointer =
                      type === "PREV" ? (
                        <StyledIconButtonLeft
                          onClick={handleLeftClick}
                          disabled={isEdge}
                        >
                          <ChevronLeftIcon fontSize={"large"} />
                        </StyledIconButtonLeft>
                      ) : (
                        <StyledIconButtonRight
                          onClick={handleRightClick}
                          disabled={isEdge}
                        >
                          <ChevronRightIcon fontSize={"large"} />
                        </StyledIconButtonRight>
                      );
                    return Pointer;
                  }}
                >
                  {data.map((item, index) => {
                    return (
                      <PropostaPlanoCard
                        setMiddleCard={setMiddleCard}
                        buttonAnimation={buttonAnimation}
                        isMiddle={index === middleCard ? true : false}
                        key={index}
                        handleClick={handleClick}
                        data={item}
                        index={index}
                      />
                    );
                  })}
                </StyledCarousel2>
              </Grid>
            </StyledGrid>
            <StyledGrid item md={9} />
            <Grid item xs={12} md={3}>
              <StyledButton onClick={handleSubmit}>
                ENVIAR PROPOSTA
              </StyledButton>
            </Grid>
          </>
        )}
        {data.length === 0 && (
          <StyledCard>
            <StyledH3>
              Não foram encontradas opções para a quantidade de vidas informadas
            </StyledH3>
          </StyledCard>
        )}
      </Grid>
    </BasePage>
  );
};

export default EnvioDaPropostaPage;
