import BasePage from "../BasePage";
import React, { useState } from "react";
import { Card, PrimaryButton } from "../../components";
import { ControlledAutocomplete } from "../../components/inputs";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import hospitalLogo from "../../assets/hospital.png";
import VerticalLinearStepper from "./StepperPage";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/cotacao.actions";
import { getCotacaoInicial } from "../../selectors/cotacao.selectors";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 4.8px;
  font-size: 48px;
  margin-bottom: 40px;
  @media (max-width: 900px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledGrid = styled(Grid)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledCard = styled(Card)`
  height: 350px;
  padding: 25px;

  & .MuiGrid-container {
    align-items: center;
    height: 350px;
  }
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: -100px;
  @media (max-width: 900px) {
    margin-top: -25px;
  }
`;

const StyleHospital = styled("img")`
  filter: invert(100%);

  @media (max-width: 900px) {
    align-items: center;
    width: 200px;
    height: 200px;
  }

  @media (min-width: 900px) {
    display: inline-block;
    width: 250px;
    height: 250px;
  }
`;

const StyledControlledAutocomplete = styled(ControlledAutocomplete)`
  & .MuiInputLabel-root {
    background-color: #415781ff;
    color: #ffffff !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff!important;
    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    },
    &:hover fieldset {
      border-color: #ffffff;
    },
    &.Mui-focused fieldset {
      border: 3px solid #ffffff;
    },
    &.Mui-error fieldset {
    border: 2.5px solid #f0e500ff;?
    },
        `;

const HospitalPage = ({ formProps, hospitais, hospitaisLoading, setStep }) => {
  const dispatch = useDispatch();
  const { getValues, setError, clearErrors } = formProps;
  const { data } = useSelector(getCotacaoInicial);
  const { hospital } = getValues();
  const initialId = data[0]?.id_rede_credenciada ?? "";
  const [redeId, setRedeId] = useState(initialId);

  const handleClick = () => {
    if (!hospital && redeId === "") {
      return setError("hospital", {
        type: "manual",
        message: "Selecione um Hospital para realizar sua cotação",
      });
    }
    hospital
      ? dispatch(
          actions.obterCotacaoInicial.request(hospital.ID_REDE_CREDENCIADA)
        )
      : dispatch(actions.obterCotacaoInicial.request(redeId));
    setStep(1);
  };

  const handleClear = () => {
    clearErrors("hospital");
  };

  return (
    <BasePage isCotacao={true}>
      <Grid container>
        <StyledGrid item xs={12} md={2}>
          <VerticalLinearStepper setStep={setStep} activeStep={0} />
        </StyledGrid>
        <StyledGrid item xs={12} md={10}>
          <StyledH1>Vamos começar?</StyledH1>
          <StyledCard>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <StyleHospital src={hospitalLogo} alt="hospital" />
              </Grid>
              <Grid item xs={12} md={6}>
                <StyledControlledAutocomplete
                  setRedeId={setRedeId}
                  secondLabelField={true}
                  defaultValue={initialId}
                  onChange={handleClear}
                  onClick={handleClear}
                  name={"hospital"}
                  domain={hospitais}
                  labelField={"NM_REDE_CREDENCIADA"}
                  idField={"ID_REDE_CREDENCIADA"}
                  formProps={formProps}
                  label={"Qual hospital não pode faltar em sua rede?"}
                  loading={hospitaisLoading.toString()}
                />
              </Grid>
            </Grid>
          </StyledCard>
        </StyledGrid>
        <StyledGrid item md={10} />
        <StyledGrid item xs={12} md={2}>
          <StyledButton onClick={handleClick}>COTAR</StyledButton>
        </StyledGrid>
      </Grid>
    </BasePage>
  );
};

export default HospitalPage;
