import { useSelector } from "react-redux";
import { getUsuario } from "../../selectors/usuarioAdm.selectors";
import FormUsuarioPage from "./FormUsuarioPage";
import { useForm } from "react-hook-form";
import validators from "../../utils/validators";
import { useEffect } from "react";

const FormUsuarioPageConnected = () => {
  const { loading, data } = useSelector(getUsuario);

  const rules = {
    nome: validators.string({ required: true }),
    email: validators.email({ required: true }),
    codigo: validators.string({ required: true }),
    situacao: validators.string({ required: true }),
    perfil: validators.string({ required: true }),
  };

  const initialValues = {
    nome: "",
    email: "",
    codigo: "",
    situacao: "",
    perfil: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("nome", data.nome);
    formProps.setValue("email", data.email);
    formProps.setValue("codigo", data.codigo);
    formProps.setValue("situacao", data?.situacao ?? "A");
    formProps.setValue("perfil", data?.perfil ?? "A");
    // eslint-disable-next-line
  }, [data]);

  return (
    <FormUsuarioPage formProps={formProps} data={data} loading={loading} />
  );
};

export default FormUsuarioPageConnected;
