import React, { useCallback, useState } from "react";
import { PrimaryButton } from "../../components";
import Reaptcha from "reaptcha";
import { ControlledTextField } from "../../components/inputs";
import { Grid, InputAdornment, Link } from "@mui/material";
import { Lock, Person } from "@mui/icons-material";
import { styled } from "@mui/system";

const StyledButton = styled(PrimaryButton)`
  margin-top: 20px;
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  cursor: pointer;
  color: #ffffff;
`;

const StyledFormGrid = styled(Grid)`
  place-self: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;
const StyledLinkGrid = styled(Grid)`
  text-align: right;
  align-items: right;
  margin-bottom: 10px;
  margin-top: -10px;
`;

const StyledControlledTextField = styled(ControlledTextField)`

margin-bottom:1px;
margin-top:1px;

flex: 1!important;

@media (max-width: 900px) {
  margin-bottom:5px;
  margin-top:5px;
}


  & .MuiInputLabel-root {
    background-color: #415781ff;
    color: #ffffff !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  
  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    },
    &:hover fieldset {
      border-color: #ffffff;
    },
    &.Mui-focused fieldset {
      border: 3px solid #ffffff;
    },
    &.Mui-error fieldset {
    border: 2.5px solid #f0e500ff;
    },`;

const LoginForm = ({
  loading,
  formProps,
  handleSubmit,
  handleClickForgotPassword,
}) => {
  const [token, setToken] = useState(null);

  const formSubmitHandler = useCallback((values) => {
    if (!!token) {
      handleSubmit(values);
    }
    // eslint-disable-next-line
  }, [handleSubmit, token][handleSubmit]);
  const verifyCallback = useCallback((t) => {
    setToken(t ?? null);
  }, []);

  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(formSubmitHandler)}
    >
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Usuário"
          name={"user"}
          formProps={formProps}
          margin={"none"}
          InputProps={{
            style: { textTransform: "lowercase" },
            startAdornment: (
              <InputAdornment position="start" className={"ml-2 mr-3"}>
                <Person style={{ color: "#ffffff" }} />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Senha"
          name="password"
          formProps={formProps}
          type={"password"}
          margin={"none"}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className={"ml-2 mr-3"}>
                <Lock style={{ color: "#ffffff" }} />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>

      <StyledLinkGrid item xs={12} md={12}>
        <StyledLink underline="hover" onClick={handleClickForgotPassword}>
          Esqueci minha senha
        </StyledLink>
      </StyledLinkGrid>
      <Reaptcha
        onVerify={verifyCallback}
        onExpire={verifyCallback}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      />
      <Grid item xs={12} md={12}>
        <StyledButton loading={loading} type={"submit"}>
          ACESSAR
        </StyledButton>
      </Grid>
    </form>
  );
};

export default LoginForm;
