import React from "react";
import FileInput from "./FileInput";
import { Controller } from "react-hook-form";
import { getValueFromObject } from "../../utils/basic";

const ControlledFileInput = ({
  name,
  onChange,
  onBlur,
  maxFiles,
  maxFileSize = 0,
  onMaxFilesReached,
  onFileAlreadyExists,
  onFilesRejected,
  onFileLengthExceeded,
  formProps,
  children,
  ...otherProps
}) => {
  const { control, errors, rules, initialValues } = formProps;
  const isError = getValueFromObject(errors, name) !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      render={({ field: { onChange, onBlur, value } }) => {
        const handleDrop = (acceptedFiles, rejectedFiles) => {
          if (rejectedFiles.length > 0 && onFilesRejected) {
            onFilesRejected(rejectedFiles, acceptedFiles);
          }
          if (acceptedFiles.length === 0) {
            return;
          }
          const files = acceptedFiles
            .filter(
              (item) =>
                !value?.some(
                  (file) =>
                    item.name === file.name &&
                    item.type === file.type &&
                    item.size === file.size &&
                    item.lastModified === file.lastModified
                )
            )
            .map((file) => {
              file.id = `${new Date().getTime()}${Math.floor(
                Math.random() * 1000
              )}`;
              return file;
            });

          if (files.length !== acceptedFiles.length && !!onFileAlreadyExists) {
            onFileAlreadyExists();
            return;
          }

          if (
            !!maxFiles &&
            maxFiles > 0 &&
            value?.concat(files).length > maxFiles
          ) {
            if (!!onMaxFilesReached) {
              onMaxFilesReached();
            }
            return;
          }

          const fileLengthExceeded =
            maxFileSize === 0
              ? []
              : files.filter((item) => item.size > maxFileSize * 1024 * 1024);
          if (fileLengthExceeded.length > 0) {
            onFileLengthExceeded(fileLengthExceeded);
            return;
          }

          onChange(value?.concat(files));
        };

        const handleDeleteFile = (file) => {
          onChange(value?.filter((item) => item.id !== file.id));
        };

        const handleFileDialogCancel = () => {
          onBlur();
        };

        return (
          <FileInput
            name={name}
            disabled={!!maxFiles && maxFiles > 0 && value?.length >= maxFiles}
            onDrop={handleDrop}
            error={isError}
            helperText={getValueFromObject(errors, name)?.message}
            files={value}
            onFileDialogCancel={handleFileDialogCancel}
            onDeleteFile={handleDeleteFile}
            {...otherProps}
          >
            {children}
          </FileInput>
        );
      }}
    />
  );
};

export default ControlledFileInput;
