import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { actions } from "../../reducers/usuarioAdm.actions";
import { getUsuarios } from "../../selectors/usuarioAdm.selectors";
import UsuarioPage from "./UsuarioPage";

const UsuarioPageConnected = () => {
  const { loading, data } = useSelector(getUsuarios);
  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.obterUsuarios.request({
        pagina: page,
      })
    );
  };

  return (
    <UsuarioPage
      data={data}
      loading={loading}
      handlePagination={handlePagination}
    />
  );
};

export default UsuarioPageConnected;
