import BasePage from "../BasePage";
import LoginForm from "./LoginForm";
import { ReactComponent as Logo } from "../../assets/Pick-curvas.svg";
import { Card, Grid } from "@mui/material";
import { styled } from "@mui/system";

const StyledH1 = styled("h2")`
  text-align: center;
  margin-top: -10px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.8px;
  text-align: left;
  margin-bottom: 10px;

  color: #ffffff;
  font-size: 18px;
  @media (max-width: 900px) {
    font-size: 15px;
    margin-bottom: 10px;
  }
`;

const StyledLogo = styled(Logo)`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 30%;
  height: 30%;
`;

const StyledLogoGrid = styled(Grid)`
  text-align: center;
`;

const StyledCard = styled(Card)`
  margin-top: 40px;
  height: auto;
  padding: 25px;
  margin-bottom: 25px;
  background-color: #415781ff;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const LoginPage = (props) => {
  return (
    <BasePage isLightBlue={true}>
      <Grid alignItems="center" justifyContent="center" container>
        <StyledCard>
          <StyledLogoGrid item xs={12} md={12}>
            <StyledLogo />
          </StyledLogoGrid>
          <StyledH1 style={{ textAlign: "center" }}>
            Bem-vindo!
            <br />
            Faça seu Login
          </StyledH1>
          <LoginForm {...props} />
        </StyledCard>
      </Grid>
    </BasePage>
  );
};

export default LoginPage;
