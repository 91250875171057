import { actions } from "./seguradora.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  id: null,
  seguradoras: {
    loading: false,
    data: [],
  },
  seguradora: {
    loading: false,
    data: [],
  },
  processing: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarSeguradoras.REQUEST:
    case actions.listarSeguradoras.SUCCESS:
    case actions.listarSeguradoras.FAILURE:
      return {
        ...state,
        seguradoras: {
          ...state.seguradoras,
          loading: action.type === actions.listarSeguradoras.REQUEST,
          data:
            action.type === actions.listarSeguradoras.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.mostrarSeguradora.REQUEST:
    case actions.mostrarSeguradora.SUCCESS:
    case actions.mostrarSeguradora.FAILURE:
      return {
        ...state,
        seguradora: {
          ...state.seguradora,
          loading: action.type === actions.mostrarSeguradora.REQUEST,
          data:
            action.type === actions.mostrarSeguradora.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.enviarAnexo.REQUEST:
    case actions.enviarAnexo.SUCCESS:
    case actions.enviarAnexo.FAILURE:
      return {
        ...state,
        processing: action.type === actions.enviarAnexo.REQUEST,
      };
    case actions.enviarLogoCard.REQUEST:
    case actions.enviarLogoCard.SUCCESS:
    case actions.enviarLogoCard.FAILURE:
      return {
        ...state,
        processing: action.type === actions.enviarLogoCard.REQUEST,
      };
    case routes.EDITAR_SEGURADORA:
      return {
        ...state,
        id: action.payload.id,
      };

    default:
      return state;
  }
};

export default reducer;
