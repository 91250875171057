import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_COTACOES: "@@cotacao/OBTER_COTACOES",
  OBTER_DETALHES_COTACAO: "@@cotacao/OBTER_DETALHES_COTACAO",
  FILTRO_REFRESH: "@@cotacao/FILTRO_REFRESH",
  INTEGRAR_MANUALMENTE: "@@cotacao/INTEGRAR_MANUALMENTE",
  INTEGRAR: "@@cotacao/INTEGRAR",
};

export const actions = {
  obterCotacoes: createAsyncAction(types.OBTER_COTACOES),
  obterDetalhesCotacao: createAsyncAction(types.OBTER_DETALHES_COTACAO),
  integrarManualmente: createAsyncAction(types.INTEGRAR_MANUALMENTE),
  integrar: createAsyncAction(types.INTEGRAR),
  filtroRefresh: (number) => createSyncAction(types.FILTRO_REFRESH, { number }),
};
