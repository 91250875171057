import BasePage from "../BasePage";
import React from "react";
import { Grid, Pagination, styled } from "@mui/material";
import TabelaSeguradoras from "./TabelaSeguradoras";

const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
`;

const StyledPagination = styled(Pagination)`
  .MuiButtonBase-root {
    background-color: #849dce !important;
    color: #ffffff;
  }

  & .Mui-selected {
    background-color: #415781 !important;
  }
`;

const SeguradoraPage = ({ loading, data, handlePagination }) => {
  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <TabelaSeguradoras
        data={data}
        loading={loading}
        handlePagination={handlePagination}
      />
      {!loading && (
        <StyledGridPagination item xs={12} md={12}>
          <StyledPagination
            count={Math.ceil(data.total / 10)}
            page={data.paginaAtual}
            variant="outlined"
            shape="rounded"
            onChange={handlePagination}
          />
        </StyledGridPagination>
      )}
    </StyledBasePage>
  );
};

export default SeguradoraPage;
