import React from "react";
import { Card } from "../../components";
import { Divider, Grid, styled } from "@mui/material";
import { formatCNPJ } from "../../utils/formatCNPJ";
import PropostaCotacaoCard from "./PropostaCotacaoCard";
import { formatTelefone } from "../../utils/formatTelefone";

const StyledH1 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  text-align: left;
  letter-spacing: 2px;
  font-size: 21px;
  margin: 10px;
  color: ${({ theme }) => theme.colors.primary};
  white-space: normal;
`;
const StyledLabel = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  text-align: left;
  letter-spacing: 1.2px;
  font-size: 14px;
  margin: 10px;
  margin-bottom: -13px;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
`;

const StyledGridContainer = styled(Grid)`
  padding: 20px;
`;

const StyledGrid = styled(Grid)`
  margin-top: -10px;
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 10px;
`;
const StyledDivider = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledH1Title = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  text-align: left;
  letter-spacing: 3px;
  font-size: 23px;
  font-weight: bold;
  margin: 10px;
  color: ${({ theme }) => theme.colors.primary};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DadosCard = ({ data, dataEnviado }) => {
  return (
    <>
      <StyledCard style={{ marginTop: "30px" }}>
        <StyledGridContainer container>
          <Grid item xs={12} sm={12} md={12}>
            <StyledH1Title>Empresa</StyledH1Title>
          </Grid>

          <Grid item xs={12} sm={3} md={3}>
            <StyledLabel>CNPJ</StyledLabel>
            <StyledH1>{formatCNPJ(data.NR_CNPJ)}</StyledH1>
          </Grid>
          <Grid item xs={12} sm={9} md={9}>
            <StyledLabel>Razão Social</StyledLabel>
            <StyledH1>{data.NM_EMPRESA.toUpperCase()}</StyledH1>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <StyledLabel>Nome</StyledLabel>
            <StyledH1>{data.NM_CONTATO.toUpperCase()}</StyledH1>
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <StyledLabel>Telefone</StyledLabel>
            <StyledH1>
              ({data.NR_AREA_CONTATO}){" "}
              {data.NR_TELEFONE_CONTATO &&
                formatTelefone(data.NR_TELEFONE_CONTATO)}
            </StyledH1>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <StyledLabel>Email</StyledLabel>
            <StyledH1>{data.CD_EMAIL_CONTATO}</StyledH1>
          </Grid>
        </StyledGridContainer>
      </StyledCard>
      <StyledCard>
        <StyledGridContainer container>
          <Grid item xs={12} sm={12} md={12}>
            <StyledH1Title>Cotação</StyledH1Title>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <StyledLabel>Hospital que não pode faltar na rede</StyledLabel>
            <StyledH1>
              {data.redeCredenciada[0].NM_REDE_CREDENCIADA.toUpperCase()}
            </StyledH1>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <StyledLabel>Cidade</StyledLabel>
            <StyledH1>
              {data.redeCredenciada[0].Municipio.NM_MUNICIPIO.toUpperCase()}
            </StyledH1>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <StyledDivider />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <StyledH1>Vidas de acordo com as faixas de idade</StyledH1>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <StyledH1>Total {data.TOTAL_VIDAS}</StyledH1>
          </Grid>
        </StyledGridContainer>
        <StyledGrid container columns={{ xs: 12, md: 10 }}>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>0 a 18</StyledLabel>
            <StyledH1>{data.QT_VIDAS_00_18}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>19 a 23</StyledLabel>
            <StyledH1>{data.QT_VIDAS_19_23}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>24 a 28</StyledLabel>
            <StyledH1>{data.QT_VIDAS_24_28}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>29 a 33</StyledLabel>
            <StyledH1>{data.QT_VIDAS_29_33}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>34 a 38</StyledLabel>
            <StyledH1>{data.QT_VIDAS_34_38}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>39 a 43</StyledLabel>
            <StyledH1>{data.QT_VIDAS_39_43}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>44 a 48</StyledLabel>
            <StyledH1>{data.QT_VIDAS_44_48}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>49 a 53</StyledLabel>
            <StyledH1>{data.QT_VIDAS_49_53}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>54 a 58</StyledLabel>
            <StyledH1>{data.QT_VIDAS_54_58}</StyledH1>
          </Grid>
          <Grid item xs={6} sm={2} md={1}>
            <StyledLabel>59 ou +</StyledLabel>
            <StyledH1>{data.QT_VIDAS_59_99}</StyledH1>
          </Grid>
        </StyledGrid>
      </StyledCard>
      {dataEnviado.length > 0 && (
        <PropostaCotacaoCard dados={data} data={dataEnviado[0]} />
      )}
    </>
  );
};

export default DadosCard;
