import { createSelector } from "reselect";

const getCotacao = (state) => state.cotacao;

export const getHospitais = createSelector(
  getCotacao,
  (state) => state.hospitais
);
export const getCotacaoInicial = createSelector(
  getCotacao,
  (state) => state.cotacaoInicial
);
export const getCotacaoFinal = createSelector(
  getCotacao,
  (state) => state.cotacaoFinal
);
export const getCotacaoPdf = createSelector(
  getCotacao,
  (state) => state.cotacaoPDF
);
export const getCotacaoDados = createSelector(
  getCotacao,
  (state) => state.cotacaoDados
);
export const getCardFinal = createSelector(
  getCotacao,
  (state) => state.cardFinal
);
export const getCotacaoResultado = createSelector(
  getCotacao,
  (state) => state.CotacaoResultado
);
export const getEnviarProposta = createSelector(
  getCotacao,
  (state) => state.enviarProposta
);
