import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import brLocale from "date-fns/locale/pt-BR";

const DatePickers = ({
  InputProps,
  variant = "filled",
  uppercase = true,
  margin,
  error,
  InputLabelProps,
  FormHelperTextProps,
  search = false,
  ...other
}) => (
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={brLocale}>
    <DatePicker
      InputLabelProps={{
        classes: {
          root: "label-input",
        },
        shrink: true,
        ...InputLabelProps,
      }}
      error={error}
      variant={variant}
      InputProps={{
        ...InputProps,
      }}
      FormHelperTextProps={{
        classes: {
          error: "label-error",
        },
        ...FormHelperTextProps,
      }}
      fullWidth
      margin={margin}
      {...other}
    />
  </MuiPickersUtilsProvider>
);

export default DatePickers;
