import { Header } from "./pages";
import React from "react";
import { styled } from "@mui/material";
import { Container } from "@mui/material";

const StyledContainer = styled(Container)`
  width: 100%;
  min-height: 100vh;
  margin-right: auto;
  margin-left: auto;
  header + & {
    padding-top: 60px;
  }
`;
const StyledContainerLightBlue = styled(Container)`
  position: absolute;
  background-color: #ecf3f9ff;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  min-height: 100vh;
  min-width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  header + & {
    padding-top: 60px;
  }
`;

const StyledH5 = styled("h5")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #849dce;
  font-size: 14px;
  margin: 10px;
  margin-top: 0px;
  text-align: center;
  letter-spacing: 1px;
`;

const BasePage = ({ children, isCotacao, isLoggedIn, isLightBlue, isCard }) => {
  return (
    <>
      <Header isCotacao={isCotacao} isLoggedIn={isLoggedIn} />

      {isLightBlue ? (
        <StyledContainerLightBlue>
          <StyledContainer>{children}</StyledContainer>
        </StyledContainerLightBlue>
      ) : (
        <StyledContainer>{children}</StyledContainer>
      )}
      {isCard && (
        <StyledH5>
          * O preço e a rede de prestadores estão sujeitos a alterações, por
          parte da operadora de saúde.
        </StyledH5>
      )}
    </>
  );
};
export default BasePage;
