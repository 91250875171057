import { createSelector } from "reselect";

export const getCarga = (state) => state.carga;
export const getProcessing = createSelector(
  getCarga,
  (carga) => carga.processing
);

export const getHistorico = createSelector(
  getCarga,
  (carga) => carga.historico
);
export const getDownload = createSelector(getCarga, (carga) => carga.download);

export const getLabel = createSelector(getProcessing, (carga) => carga.label);
