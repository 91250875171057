import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as administracao } from "../reducers/administracao.actions";
import { put, select } from "redux-saga/effects";
import { toast } from "react-toastify";
import { actions } from "../reducers/dashboard.actions";

const ListarCotacoesApi = (values) => {
  return authenticatedRequest({
    url: `/cotacao/listar`,
    isResourceService: true,
    method: "post",
    body: {
      seguradora: values?.seguradora ?? "",
      razao_social: values?.empresa ?? "",
      situacao: values?.situacao ?? "E",
      pagina: values?.pagina ?? 1,
      ordem: values?.ordem ?? "DH_CRIACAO",
      limite: 12,
    },
  });
};

const ListarCotacoes = basicFlow({
  actionGenerator: administracao.obterCotacoes,
  api: ListarCotacoesApi,
});

const ListarDetalhesCotacaoApi = (values) => {
  return authenticatedRequest({
    url: `/cotacao/show`,
    isResourceService: true,
    method: "post",
    body: {
      id_cotacao: values.id,
    },
  });
};

const ListarDetalhesCotacao = basicFlow({
  actionGenerator: administracao.obterDetalhesCotacao,
  transform: function* (payload) {
    const id = yield select((state) => state.administracao.id);
    return { id, ...payload };
  },

  api: ListarDetalhesCotacaoApi,
});

function* ListarCotacoesRouteWatcher() {
  yield routeWatcher(routes.COTACOES, function* () {
    yield put(administracao.obterCotacoes.request());
  });
}
function* ListarDetalhesCotacaoRouteWatcher() {
  yield routeWatcher(routes.DETALHES_COTACAO, function* () {
    yield put(administracao.obterDetalhesCotacao.request({ reload: true }));
  });
}

const IntegrarManualmenteApi = (values) => {
  return authenticatedRequest({
    url: `/cotacao/integrar-manualmente`,
    isResourceService: true,
    method: "post",
    body: {
      id_cotacao: values.id,
    },
  });
};

const IntegrarManualmente = basicFlow({
  actionGenerator: administracao.integrarManualmente,
  api: IntegrarManualmenteApi,
  postSuccess: function* ({ values }) {
    yield put(administracao.obterDetalhesCotacao.request({ id: values.id }));
    yield put(actions.obterPropostasNaoIntegradas.request({ reload: true }));

    toast.success(`Integração realizada manualmente.`, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

const IntegrarApi = (values) => {
  return authenticatedRequest({
    url: `/cotacao/integrar`,
    isResourceService: true,
    method: "post",
    body: {
      QT_VIDAS_00_18: values.QT_VIDAS_00_18,
      QT_VIDAS_19_23: values.QT_VIDAS_19_23,
      QT_VIDAS_24_28: values.QT_VIDAS_24_28,
      QT_VIDAS_29_33: values.QT_VIDAS_29_33,
      QT_VIDAS_34_38: values.QT_VIDAS_34_38,
      QT_VIDAS_39_43: values.QT_VIDAS_39_43,
      QT_VIDAS_44_48: values.QT_VIDAS_44_48,
      QT_VIDAS_49_53: values.QT_VIDAS_49_53,
      QT_VIDAS_54_58: values.QT_VIDAS_54_58,
      QT_VIDAS_59_99: values.QT_VIDAS_59_99,
      NR_CNPJ: values.NR_CNPJ,
      NM_EMPRESA: values.NM_EMPRESA,
      NM_CONTATO: values.NM_CONTATO,
      NR_AREA_CONTATO: values.NR_AREA_CONTATO,
      NR_TELEFONE_CONTATO: values.NR_TELEFONE_CONTATO,
      CD_EMAIL_CONTATO: values.CD_EMAIL_CONTATO,
      VL_PROPOSTA: values.VL_PROPOSTA,
      ID_COTACAO: values.ID_COTACAO,
    },
  });
};

const Integrar = basicFlow({
  actionGenerator: administracao.integrar,
  api: IntegrarApi,
  postSuccess: function* ({ values }) {
    yield put(
      administracao.obterDetalhesCotacao.request({ id: values.ID_COTACAO })
    );
    yield put(actions.obterPropostasNaoIntegradas.request({ reload: true }));

    toast.success(`Integração realizada com sucesso.`, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
  postFailure: function* ({ values }) {
    yield put(
      administracao.obterDetalhesCotacao.request({ id: values.ID_COTACAO })
    );
    yield put(actions.obterPropostasNaoIntegradas.request({ reload: true }));

    toast.error(`Ocorreu um erro durante a integração.`, {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#990a26" },
    });
  },
});

export const sagas = [
  ListarCotacoesRouteWatcher(),
  ListarDetalhesCotacaoRouteWatcher(),
  ListarCotacoes.watcher(),
  ListarDetalhesCotacao.watcher(),
  IntegrarManualmente.watcher(),
  Integrar.watcher(),
];
