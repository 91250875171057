import { Grid, Skeleton, styled } from "@mui/material";
import React from "react";

const StyledSkeleton = styled(Skeleton)`
  border-radius: 15px;
`;

const CardsGridSkeleton = () => {
  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <StyledSkeleton variant="rectangular" width={275} height={350} />
      </Grid>
    </>
  );
};

export default CardsGridSkeleton;
