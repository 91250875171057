import { Chip } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";

const StyledChip = styled(Chip)`
  color: white;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.family.default};
`;

const StyledContainer = styled("div")`
  display: flex;
  justify-content: space-between;
`;

const StyledItem = styled("div")`
  display: inline-block;
  margin-bottom: 30px;
`;

const StyledH1 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  text-align: left;
  margin: auto;
  margin-top: 2px;
  color: white;
  letter-spacing: 1.4px;
  font-size: 19px;

  @media (max-width: 1110px) {
    font-size: 18px;
  }
`;

const FichaSituacao = ({ situacao, data }) => {
  return (
    <StyledContainer>
      <StyledItem>
        <StyledH1>{data}</StyledH1>
      </StyledItem>
      <StyledItem>
        <StyledChip
          label={`${situacao === "F" ? "não concluída" : "enviada"}`}
          style={{
            backgroundColor: `${situacao === "F" ? "#F24F13" : "#018E06"}`,
            width: `${situacao === "F" ? "110px" : "80px"}`,
          }}
        />
      </StyledItem>
    </StyledContainer>
  );
};

export default FichaSituacao;
