import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  CARGA_SEGURADORAS: "@@carga/CARGA_SEGURADORAS",
  CARGA_UPDATE_LABEL: "@@carga/CARGA_UPDATE_LABEL",
  HISTORICO_CARGA: "@@carga/HISTORICO_CARGA",
  DOWNLOAD_PLANILHA: "@@carga/DOWNLOAD_PLANILHA",
};

export const actions = {
  carga_seguradoras: createAsyncAction(types.CARGA_SEGURADORAS),
  listar_historico: createAsyncAction(types.HISTORICO_CARGA),
  download_planilha: createAsyncAction(types.DOWNLOAD_PLANILHA),
  carga_update_label: (label) =>
    createSyncAction(types.CARGA_UPDATE_LABEL, label),
};
