import BasePage from "../BasePage";
import React from "react";
import { Box, Card, Grid, styled } from "@mui/material";
import { CloudUpload, Delete, Description } from "@mui/icons-material";
import ControlledFileInput from "../../components/inputs/ControlledFileInput";
import formatters from "../../utils/formatters";
import Button from "../../components/buttons/PrimaryButton";
import { useState } from "react";

const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  margin-top: 75px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
    margin-top: 50px;
  }
`;
const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledH5 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.4px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledButton = styled(Button)`
  width: 200px !important;
  text-align: center;
  @media (max-width: 900px) {
    width: 120px !important;
  }
`;
const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background-color: #ebf2f8ff;

  border: 2px solid #b4cfe7ff;
  border-radius: 15px;
`;
const ButtonContainer = styled("div")`
  margin-top: 20px;
  width: 100% !important;
  text-align: center;
`;

const filesize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return `${formatters.numbers.nFixed(
      Math.round(size / (1024 * 1024), 1),
      1
    )} Mb`;
  }
  if (size > 1024) {
    // Mb
    return `${formatters.numbers.nFixed(size / 1024, 1)} Kb`;
  }
  return `${size} bytes`;
};

const CargaSeguradoraPage = ({
  formProps,
  submitting,
  handleSubmit,
  label,
}) => {
  const [isFile, setIsFile] = useState(true);

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledCard>
        <StyledH2>Carga Seguradoras</StyledH2>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <form onSubmit={formProps.handleSubmit(handleSubmit)}>
              <div className={"row"}>
                <ControlledFileInput
                  name={"fileSeguradoras"}
                  formProps={formProps}
                  maxFiles={1}
                  accept={".xlsx"}
                  multiple={false}
                  renderFile={(file, index, handleClickDeleteIcon) => {
                    setIsFile(false);
                    return (
                      <StyledBoxFile
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                        key={index}
                      >
                        <StyledH4>
                          <Description
                            style={{
                              fontSize: "60px",
                              marginTop: "5px",
                              color: "#33c481",
                              marginBottom: "-5px",
                            }}
                          />
                        </StyledH4>
                        <StyledH4
                          style={{
                            marginLeft: "15px",
                            marginRight: "15px",
                            fontSize: `${
                              file.name.length > 25 ? "10px" : "12px"
                            }`,
                          }}
                        >
                          {file.name}
                        </StyledH4>
                        <StyledH4>{filesize(file.size)}</StyledH4>
                        {!submitting && (
                          <StyledH4>
                            <Delete
                              onClick={() => {
                                handleClickDeleteIcon(file);
                                setIsFile(true);
                              }}
                              style={{
                                color: "#f00e0e",
                                cursor: "pointer",
                                fontSize: "30px",
                              }}
                            />
                          </StyledH4>
                        )}
                        {submitting && <StyledH5>{label}</StyledH5>}
                      </StyledBoxFile>
                    );
                  }}
                >
                  {isFile && (
                    <StyledBox
                      sx={{
                        cursor: "pointer",
                        width: "100%",
                        height: "100%",
                        "&:hover": {
                          opacity: [0.9, 0.8, 0.7],
                        },
                      }}
                    >
                      <StyledH4 style={{ marginTop: "50px" }}>
                        Arraste o arquivo para cá
                      </StyledH4>
                      <StyledH4>ou clique para selecioná-lo</StyledH4>
                      <StyledH4>(somente arquivos com extensão .xlsx)</StyledH4>
                      <StyledH4>
                        <CloudUpload
                          style={{ fontSize: "30px", marginTop: "-5px" }}
                        />
                      </StyledH4>
                    </StyledBox>
                  )}
                </ControlledFileInput>
              </div>
              <ButtonContainer>
                <StyledButton
                  disabled={isFile}
                  loading={submitting}
                  type="submit"
                >
                  PROCESSAR
                </StyledButton>
              </ButtonContainer>
            </form>
          </Grid>
        </Grid>
      </StyledCard>
    </StyledBasePage>
  );
};

export default CargaSeguradoraPage;
