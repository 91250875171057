import React from "react";
import { Card } from "../../components";
import { Grid, styled } from "@mui/material";
import Button from "../../components/buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/administracao.actions";
import Spinner from "../../components/spinner/Spinner";
import { format } from "date-fns";
import {
  getIntegrar,
  getIntegrarManualmente,
} from "../../selectors/administracao.selectors";

const StyledH1 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  text-align: left;
  letter-spacing: 2px;
  font-size: 21px;
  margin: 10px;
  color: ${({ theme }) => theme.colors.primary};
  white-space: normal;
`;
const StyledLabel = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  text-align: left;
  letter-spacing: 1.2px;
  font-size: 14px;
  margin: 10px;
  margin-bottom: -13px;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
`;

const StyledGridContainer = styled(Grid)`
  padding: 20px;
`;

const StyledButton = styled(Button)`
  margin-top: 12.5px;
  margin-bottom: 12.5px;
  font-size: 12px;
  width: 200px;
`;

const mostrarSituacao = (valor) => {
  switch (valor) {
    case "N":
      return "Não integrar";
    case "P":
      return "Pendente de Integração";
    case "E":
      return "Erro na Integração";
    case "S":
      return "Integrado com Sucesso";
    case "M":
      return "Incluído Manualmente";
    default:
      return "-";
  }
};

const IntegracaoCard = ({ data, loading }) => {
  const dispatch = useDispatch();

  const { loading: loadingManualmente } = useSelector(getIntegrarManualmente);
  const { loading: loadingIntegrar } = useSelector(getIntegrar);

  const handleIntegrar = () => {
    dispatch(
      actions.integrar.request({
        QT_VIDAS_00_18: data.QT_VIDAS_00_18,
        QT_VIDAS_19_23: data.QT_VIDAS_19_23,
        QT_VIDAS_24_28: data.QT_VIDAS_24_28,
        QT_VIDAS_29_33: data.QT_VIDAS_29_33,
        QT_VIDAS_34_38: data.QT_VIDAS_34_38,
        QT_VIDAS_39_43: data.QT_VIDAS_39_43,
        QT_VIDAS_44_48: data.QT_VIDAS_44_48,
        QT_VIDAS_49_53: data.QT_VIDAS_49_53,
        QT_VIDAS_54_58: data.QT_VIDAS_54_58,
        QT_VIDAS_59_99: data.QT_VIDAS_59_99,
        NR_CNPJ: data.NR_CNPJ,
        NM_EMPRESA: data.NM_EMPRESA,
        NM_CONTATO: data.NM_CONTATO,
        NR_AREA_CONTATO: data.NR_AREA_CONTATO,
        NR_TELEFONE_CONTATO: data.NR_TELEFONE_CONTATO,
        CD_EMAIL_CONTATO: data.CD_EMAIL_CONTATO,
        VL_PROPOSTA: data.VL_PROPOSTA,
        ID_COTACAO: data.ID_COTACAO,
      })
    );
  };

  const handleIntegrarManualmente = () => {
    dispatch(actions.integrarManualmente.request({ id: data.ID_COTACAO }));
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <>
      <StyledCard style={{ marginTop: "30px" }}>
        <StyledGridContainer container>
          <Grid item xs={12} sm={3} md={3}>
            <StyledLabel>Data/Hora</StyledLabel>
            <StyledH1>
              {data.DH_INTEGRACAO
                ? format(new Date(data.DH_INTEGRACAO), "dd/MM/yyyy HH:mm")
                : "-"}
            </StyledH1>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <StyledLabel>Situação</StyledLabel>
            <StyledH1>{mostrarSituacao(data.TP_SITUACAO_INTEGRACAO)}</StyledH1>
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            {data.TP_SITUACAO_INTEGRACAO === "E" && (
              <StyledButton loading={loadingIntegrar} onClick={handleIntegrar}>
                Reenviar
              </StyledButton>
            )}
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            {data.TP_SITUACAO_INTEGRACAO === "E" && (
              <StyledButton
                loading={loadingManualmente}
                onClick={handleIntegrarManualmente}
              >
                Inserir Manualmente
              </StyledButton>
            )}
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <StyledLabel>Descrição do Erro</StyledLabel>
            <StyledH1>{data?.DS_ERRO_INTEGRACAO ?? "-"}</StyledH1>
          </Grid>
        </StyledGridContainer>
      </StyledCard>
    </>
  );
};

export default IntegracaoCard;
