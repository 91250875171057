import BasePage from "../BasePage";
import React, { useEffect } from "react";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import TabelaCotacoes from "./TabelaCotacoes";
import TabelaHospitais from "./TabelaHospitails";
import { useState } from "react";
import { actions } from "../../reducers/dashboard.actions";
import { useDispatch, useSelector } from "react-redux";
import GraficoCard from "./GraficoCard";
import DashboardCardDia from "./DashboardCardDia";
import DashboardCardAno from "./DashboardCardAno";
import DashboardCardMes from "./DashboardCardMes";
import { getReload } from "../../selectors/administracao.selectors";
import TabelaIntegracao from "./TabelaIntegracao";

const StyledContainer = styled(Grid)`
  margin-bottom: 10px;
  padding-right: 5px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledContainerHospital = styled(Grid)`
  margin-top: -10px;
  padding-right: 5px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledContainerIntegracao = styled(Grid)`
  margin-bottom: -10px;
  padding-right: 5px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledContainerTabela = styled(Grid)`
  padding-right: 5px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledContainerTop = styled(Grid)`
  padding-right: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
  @media (max-width: 900px) {
    padding-left: 0px;
    padding-right: 0px;
  }
`;
const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const DashboardPage = ({
  dataPorcentagemDia,
  dataPorcentagemMes,
  dataPorcentagemAno,
  dataHospitais,
  dataCotacoes,
  dataSeguradoras,
  dataPropostasNaoIntegradas,
  loadingPorcentagemDia,
  loadingPorcentagemMes,
  loadingPorcentagemAno,
  loadingCotacoes,
  loadingSeguradoras,
  loadingHospitais,
  loadingPropostasNaoIntegradas,
}) => {
  const [tituloPeriodo, setTituloPeriodo] = useState("Hoje");
  const [filtroPeriodo, setFiltroPeriodo] = useState("dia");
  const [valuePeriodo, setValuePeriodo] = useState(new Date());
  const [displayCotacoes, setDisplayCotacoes] = useState(false);
  const { data: dataReload } = useSelector(getReload);
  const dispatch = useDispatch();

  useEffect(() => {
    setDisplayCotacoes(false);
  }, [dataReload]);

  const handleChangePeriodo = (titulo, periodo, dia) => {
    setTituloPeriodo(titulo);
    setFiltroPeriodo(periodo);
    setValuePeriodo(dia);
    dispatch(
      actions.obterCotacoes.request({
        pagina: 1,
        filtro: periodo,
        dia: dia,
      })
    );
  };

  const handlePagination = (e, page) => {
    setDisplayCotacoes(true);
    dispatch(
      actions.obterCotacoes.request({
        filtro: filtroPeriodo,
        pagina: page,
        dia: valuePeriodo,
      })
    );
  };

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainerTop container spacing={4}>
        <Grid item xs={12} sm={12} md={4}>
          <DashboardCardDia
            refresh={dataReload}
            setDisplayCotacoes={setDisplayCotacoes}
            setValuePeriodo={setValuePeriodo}
            loading={loadingPorcentagemDia}
            periodo={"dia"}
            setFiltroPeriodo={setFiltroPeriodo}
            handleChangePeriodo={handleChangePeriodo}
            titulo={"Cotações realizadas em"}
            data={{
              cotacoes: dataPorcentagemDia.cotacoesDia,
              porcentagem: dataPorcentagemDia.porcentagemDia,
              valor: dataPorcentagemDia.valorDia,
              porcentagemValor: dataPorcentagemDia.porcentagemValorDia,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <DashboardCardMes
            refresh={dataReload}
            setDisplayCotacoes={setDisplayCotacoes}
            setValuePeriodo={setValuePeriodo}
            loading={loadingPorcentagemMes}
            periodo={"mês"}
            setFiltroPeriodo={setFiltroPeriodo}
            handleChangePeriodo={handleChangePeriodo}
            titulo={"Cotações realizadas em"}
            data={{
              cotacoes: dataPorcentagemMes.cotacoesMes,
              porcentagem: dataPorcentagemMes.porcentagemMes,
              valor: dataPorcentagemMes.valorMes,
              porcentagemValor: dataPorcentagemMes.porcentagemValorMes,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <DashboardCardAno
            refresh={dataReload}
            setDisplayCotacoes={setDisplayCotacoes}
            setValuePeriodo={setValuePeriodo}
            loading={loadingPorcentagemAno}
            periodo={"ano"}
            setFiltroPeriodo={setFiltroPeriodo}
            handleChangePeriodo={handleChangePeriodo}
            titulo={"Cotações realizadas em"}
            data={{
              cotacoes: dataPorcentagemAno.cotacoesAno,
              porcentagem: dataPorcentagemAno.porcentagemAno,
              valor: dataPorcentagemAno.valorAno,
              porcentagemValor: dataPorcentagemAno.porcentagemValorAno,
            }}
          />
        </Grid>
      </StyledContainerTop>
      {displayCotacoes && (
        <StyledContainerTabela container spacing={4}>
          <Grid item xs={12} sm={12} md={12}>
            <TabelaCotacoes
              tituloPeriodo={tituloPeriodo}
              dataCotacoes={dataCotacoes}
              handlePagination={handlePagination}
              loading={loadingCotacoes}
            />
          </Grid>
        </StyledContainerTabela>
      )}
      <StyledContainerIntegracao container>
        <Grid item xs={12} sm={12} md={12}>
          <TabelaIntegracao
            loading={loadingPropostasNaoIntegradas}
            data={dataPropostasNaoIntegradas}
          />
        </Grid>
      </StyledContainerIntegracao>

      <StyledContainer container>
        <Grid item xs={12} sm={12} md={12}>
          <GraficoCard loading={loadingSeguradoras} data={dataSeguradoras} />
        </Grid>
      </StyledContainer>
      <StyledContainerHospital container>
        <Grid item xs={12} sm={12} md={12}>
          <TabelaHospitais loading={loadingHospitais} data={dataHospitais} />
        </Grid>
      </StyledContainerHospital>
    </StyledBasePage>
  );
};

export default DashboardPage;
