import React from "react";
import { useSelector } from "react-redux";
import {
  getCotacoes,
  getReload,
} from "../../selectors/administracao.selectors";
import CotacoesPage from "./CotacoesPage";
import { actions } from "../../reducers/administracao.actions";
import { useDispatch } from "react-redux";
import validators from "../../utils/validators";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

const CotacoesPageConnected = () => {
  const { loading, data } = useSelector(getCotacoes);
  const { data: dataReload } = useSelector(getReload);

  const dispatch = useDispatch();

  const initialValues = {
    empresa: "",
    seguradora: "",
    situacao: "Enviada",
    ordem: "Data",
  };

  const rules = {
    empresa: validators.string({ required: false }),
    seguradora: validators.string({ required: false }),
    situacao: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm({
      defaultValues: initialValues,
      initialValues,
    }),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("empresa", "");
    formProps.setValue("seguradora", "");
    formProps.setValue("situacao", "Enviada");
    formProps.setValue("ordem", "Data");
    // eslint-disable-next-line
  }, [dataReload]);

  const handleSearch = () => {
    const empresa = formProps.getValues("empresa");
    const seguradora = formProps.getValues("seguradora");
    const situacao = formProps.getValues("situacao");
    const ordem = formProps.getValues("ordem");
    dispatch(
      actions.obterCotacoes.request({
        empresa,
        seguradora,
        ordem: ordem === "Data" ? "DH_CRIACAO" : "VL_PROPOSTA",
        situacao:
          situacao === "Não concluída"
            ? "F"
            : situacao === "Enviada"
            ? "E"
            : "",
        pagina: 1,
      })
    );
  };

  const handlePagination = (e, page) => {
    const empresa = formProps.getValues("empresa");
    const seguradora = formProps.getValues("seguradora");
    const situacao = formProps.getValues("situacao");
    const ordem = formProps.getValues("ordem");
    dispatch(
      actions.obterCotacoes.request({
        empresa,
        seguradora,
        ordem: ordem === "Data" ? "DH_CRIACAO" : "VL_PROPOSTA",
        situacao:
          situacao === "Não concluída"
            ? "F"
            : situacao === "Enviada"
            ? "E"
            : "",
        pagina: page,
      })
    );
  };

  return (
    <CotacoesPage
      handlePagination={handlePagination}
      data={data}
      loading={loading}
      formProps={formProps}
      handleSearch={handleSearch}
    />
  );
};

export default CotacoesPageConnected;
