import basicFlow from "./asyncHandler";
import { unauthenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as cotacao } from "../reducers/cotacao.actions";
import { put } from "redux-saga/effects";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

const listarHospitaisApi = () => {
  return unauthenticatedRequest({
    url: "/rede-credenciada/hospitais",
    method: "get",
  });
};

const listarHospitais = basicFlow({
  actionGenerator: cotacao.obterHospitais,
  api: listarHospitaisApi,
});

const listarCotacaoInicialApi = (value) => {
  return unauthenticatedRequest({
    url: `/plano/cotacao`,
    isResourceService: true,
    method: "post",
    body: {
      rede_credenciada: value,
      vidas_por_faixa: {
        entre_0_18: 1,
        entre_19_23: 0,
        entre_24_28: 0,
        entre_29_33: 0,
        entre_34_38: 0,
        entre_39_43: 0,
        entre_44_48: 0,
        entre_49_53: 0,
        entre_54_58: 0,
        entre_59_99: 0,
      },
    },
  });
};

const listarCotacaoInicial = basicFlow({
  actionGenerator: cotacao.obterCotacaoInicial,
  api: listarCotacaoInicialApi,
});

const listarCotacaoFinalApi = (value) => {
  return unauthenticatedRequest({
    url: `/plano/cotacao/final`,
    isResourceService: true,
    method: "post",
    body: {
      rede_credenciada: value.ID_REDE_CREDENCIADA,
      todos_resultados: true,
      vidas_por_faixa: {
        entre_0_18: value.QT_VIDAS_00_18,
        entre_19_23: value.QT_VIDAS_19_23,
        entre_24_28: value.QT_VIDAS_24_28,
        entre_29_33: value.QT_VIDAS_29_33,
        entre_34_38: value.QT_VIDAS_34_38,
        entre_39_43: value.QT_VIDAS_39_43,
        entre_44_48: value.QT_VIDAS_44_48,
        entre_49_53: value.QT_VIDAS_49_53,
        entre_54_58: value.QT_VIDAS_54_58,
        entre_59_99: value.QT_VIDAS_59_99,
      },
    },
  });
};

const listarCotacaoFinal = basicFlow({
  actionGenerator: cotacao.obterCotacaoFinal,
  api: listarCotacaoFinalApi,
});

const criarCotacaoApi = (value) => {
  return unauthenticatedRequest({
    url: `/cotacao`,
    isResourceService: true,
    method: "post",
    body: {
      DT_COTACAO: value.DT_COTACAO,
      ID_REDE_CREDENCIADA: value.ID_REDE_CREDENCIADA,
      ID_SEGURADORA_PLANO_VALOR_OPCAO1_INICIAL:
        value.ID_SEGURADORA_PLANO_VALOR_OPCAO1_INICIAL,
      ID_SEGURADORA_PLANO_VALOR_OPCAO2_INICIAL:
        value.ID_SEGURADORA_PLANO_VALOR_OPCAO2_INICIAL,
      ID_SEGURADORA_PLANO_VALOR_OPCAO3_INICIAL:
        value.ID_SEGURADORA_PLANO_VALOR_OPCAO3_INICIAL,
      QT_VIDAS_00_18: value.QT_VIDAS_00_18 ? value.QT_VIDAS_00_18 : 0,
      QT_VIDAS_19_23: value.QT_VIDAS_19_23 ? value.QT_VIDAS_19_23 : 0,
      QT_VIDAS_24_28: value.QT_VIDAS_24_28 ? value.QT_VIDAS_24_28 : 0,
      QT_VIDAS_29_33: value.QT_VIDAS_29_33 ? value.QT_VIDAS_29_33 : 0,
      QT_VIDAS_34_38: value.QT_VIDAS_34_38 ? value.QT_VIDAS_34_38 : 0,
      QT_VIDAS_39_43: value.QT_VIDAS_39_43 ? value.QT_VIDAS_39_43 : 0,
      QT_VIDAS_44_48: value.QT_VIDAS_44_48 ? value.QT_VIDAS_44_48 : 0,
      QT_VIDAS_49_53: value.QT_VIDAS_49_53 ? value.QT_VIDAS_49_53 : 0,
      QT_VIDAS_54_58: value.QT_VIDAS_54_58 ? value.QT_VIDAS_54_58 : 0,
      QT_VIDAS_59_99: value.QT_VIDAS_59_99 ? value.QT_VIDAS_59_99 : 0,
      NR_CNPJ: value.NR_CNPJ,
      NM_EMPRESA: value.NM_EMPRESA,
      NM_CONTATO: value.NM_CONTATO,
      NR_AREA_CONTATO: value.NR_AREA_CONTATO,
      NR_TELEFONE_CONTATO: value.NR_TELEFONE_CONTATO,
      CD_EMAIL_CONTATO: value.CD_EMAIL_CONTATO,
      TP_SITUACAO_FLUXO: "F",
    },
  });
};

const criarCotacao = basicFlow({
  actionGenerator: cotacao.criarCotacao,
  api: criarCotacaoApi,
});

const criarCotacaoResultadoApi = (value) => {
  return unauthenticatedRequest({
    url: `/cotacao-resultado`,
    isResourceService: true,
    method: "post",
    body: {
      ID_COTACAO: value.ID_COTACAO,
      ID_SEGURADORA_PLANO_VALOR: value.ID_SEGURADORA_PLANO_VALOR,
      TP_ENVIO_PROPOSTA: value.TP_ENVIO_PROPOSTA,
      VL_PROPOSTA: value.VL_PROPOSTA,
      ID_SEGURADORA_PROPOSTA: value.ID_SEGURADORA_PROPOSTA,
    },
  });
};

const criarCotacaoResultado = basicFlow({
  actionGenerator: cotacao.criarCotacaoResultado,
  api: criarCotacaoResultadoApi,
});

const obterCotacaoPDFApi = (value) => {
  return unauthenticatedRequest({
    url: `/plano/cotacao-pdf`,
    isResourceService: true,
    method: "post",
    responseType: "blob",
    body: {
      id_seguradora_plano_valor: value.id_seguradora_plano_valor,
      nome_cliente: value.nome_cliente,
      vidas_por_faixa: value.vidas_por_faixa,
    },
  });
};

const obterAnexoPDFApi = (value) => {
  return unauthenticatedRequest({
    url: `/seguradora/baixar/anexo`,
    isResourceService: true,
    method: "post",
    responseType: "blob",
    body: {
      id_seguradora_plano_valor: value.id_seguradora_plano_valor,
    },
  });
};

const obterCotacaoPDF = basicFlow({
  actionGenerator: cotacao.obterCotacaoPDF,
  api: obterCotacaoPDFApi,
  postSuccess: ({ response, original }) => {
    downloadFile(response, original);
  },
});
const obterAnexoPDF = basicFlow({
  actionGenerator: cotacao.obterAnexoPDF,
  api: obterAnexoPDFApi,
  postSuccess: ({ response, original }) => {
    if (response.status !== 203) {
      downloadAnexoFile(response, original);
    }
  },
});

const downloadAnexoFile = (response, original) => {
  const blob = new Blob([response.data], {
    type: "application/pdf",
  });
  saveAs(blob, "AnexoSeguradora.pdf");
};

const downloadFile = (response, original) => {
  const blob = new Blob([response.data], {
    type: "application/pdf",
  });
  saveAs(blob, "Proposta_Pick_Seguros.pdf");
};

function* listarHospitaisRouteWatcher() {
  yield routeWatcher(routes.COTACAO, function* () {
    yield put(cotacao.obterHospitais.request());
  });
}

const enviarPropostaApi = (value) => {
  return unauthenticatedRequest({
    url: `/plano/enviar-proposta`,
    isResourceService: true,
    method: "post",
    body: {
      id_seguradora_plano_valor: value.id_seguradora_plano_valor,
      nome_cliente: value.nome_cliente,
      email: value.email,
      vidas_por_faixa: value.vidas_por_faixa,
      reenvio: value?.reenvio,
    },
  });
};

const enviarProposta = basicFlow({
  actionGenerator: cotacao.enviarProposta,
  api: enviarPropostaApi,
  postSuccess: function () {
    toast.success("Proposta enviada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  },
});

export const sagas = [
  listarHospitaisRouteWatcher(),
  listarHospitais.watcher(),
  listarCotacaoInicial.watcher(),
  criarCotacao.watcher(),
  listarCotacaoFinal.watcher(),
  obterCotacaoPDF.watcher(),
  criarCotacaoResultado.watcher(),
  enviarProposta.watcher(),
  obterAnexoPDF.watcher(),
];
