import { isValid as isValidCpf } from "@fnando/cpf";
import { isValid as isValidCnpj } from "@fnando/cnpj";
import { removeNonDigitsFromString } from "./basic";

const requiredValidation = (value) => {
  if (value !== undefined && value !== null && value !== "") return true;
  return "Campo obrigatório";
};
const validDate = (value) => {
  try {
    if (value instanceof Date) return true;
    if (typeof value !== "string") return false;
    if (value.length !== 10) return false;
    const [dia, mes, ano] = value.split("/");
    if (!dia || !mes | !ano) return false;
    const diaNumero = parseInt(dia, 10);
    const mesNumero = parseInt(mes, 10);
    const anoNumero = parseInt(ano, 10);
    const date = new Date(anoNumero, mesNumero - 1, diaNumero, 0, 0, 0);
    return (
      date.getFullYear() === anoNumero &&
      date.getMonth() === mesNumero - 1 &&
      date.getDate() === diaNumero
    );
  } catch (e) {
    return false;
  }
};

const validarCpfCpnjRepetido = (value, participantes) => {
  if (value === undefined || value === null || value === "") {
    return true;
  } else {
    if (
      participantes.comprador.filter(
        (c) => c.nr_cpf_cnpj === removeNonDigitsFromString(value)
      ).length > 0
    ) {
      return "CPF/CNPJ já utilizado na proposta";
    }
    if (
      participantes.vendedor.filter(
        (v) => v.nr_cpf_cnpj === removeNonDigitsFromString(value)
      ).length > 0
    ) {
      return "CPF/CNPJ já utilizado na proposta";
    }
  }
  return true;
};

const validators = {
  object: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) =>
          !required ||
          (value !== undefined && value !== null && value !== {}) ||
          "Campo obrigatório",
        ...custom,
      },
    };
  },
  array: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) =>
          !required ||
          (value !== undefined && Array.isArray(value) && value.length > 0) ||
          "Campo obrigatório",
        ...custom,
      },
    };
  },
  string: ({ required = false, maxLength, length, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        length: (value) =>
          !length || value.length === length || `Tamanho inválido (${length})`,
        ...custom,
      },
    };
  },
  cpf: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) => isValidCpf(value) || "CPF inválido",
      },
      ...custom,
    };
  },
  cpfCnpj: ({
    required = false,
    participantes,
    validarRepetido = false,
    custom = {},
  }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) => {
          if (value !== null && value.length > 0) {
            if (parseInt(removeNonDigitsFromString(value)) === 0)
              return "CPF/CNPJ inválido";
            if (value.length <= 14) {
              return isValidCpf(value) || "CPF inválido";
            }
            return isValidCnpj(value) || "CNPJ inválido";
          }
        },
        isRepetido: (value) =>
          !validarRepetido || validarCpfCpnjRepetido(value, participantes),
        ...custom,
      },
    };
  },
  email: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) =>
          !!value
            ? /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                value
              ) || "E-mail inválido"
            : true,
        ...custom,
      },
    };
  },
  cnpj: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) => isValidCnpj(value) || "CNPJ inválido",
        ...custom,
      },
    };
  },
  phone: ({ required = false, custom = {} }) => {
    return {
      validate: {
        required: (value) =>
          !required || requiredValidation(removeNonDigitsFromString(value)),
        valid: (value) =>
          !!value && value.length > 0
            ? (removeNonDigitsFromString(value)?.length >= 10 &&
                removeNonDigitsFromString(value)?.length <= 11) ||
              "Telefone inválido"
            : true,
        ...custom,
      },
    };
  },
  date: ({ required = false, valid = false, custom = {} }) => {
    if (required) valid = true;

    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        valid: (value) =>
          value.length === 0 || !valid || validDate(value) || "Data inválida",
        ...custom,
      },
    };
  },
  number: ({ required = false, length, custom = {} }) => {
    return {
      validate: {
        required: (value) => !required || requiredValidation(value),
        length: (value) =>
          !length || value.length === length || `Tamanho inválido (${length})`,
        ...custom,
      },
    };
  },
};

export default validators;
