import { combineReducers } from "redux";
import { reducer as routesReducer } from "../routes";
import { default as cotacao } from "./cotacao.reducer";
import { default as autenticacao } from "./autenticacao.reducer";
import { default as administracao } from "./administracao.reducer";
import { default as usuario } from "./usuario.reducer";
import { default as dashboard } from "./dashboard.reducer";
import { default as usuarioAdm } from "./usuarioAdm.reducer";
import { default as carga } from "./carga.reducer";
import { default as seguradora } from "./seguradora.reducer";

export default combineReducers({
  location: routesReducer,
  autenticacao,
  cotacao,
  administracao,
  usuario,
  dashboard,
  usuarioAdm,
  carga,
  seguradora,
});
