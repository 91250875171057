import { removeNonDigitsFromString } from "./basic";

export const formatCNPJ = (value) => {
  const stripped = removeNonDigitsFromString(value);
  const size = !!stripped ? stripped.length : 0;

  if (size > 11) {
    let finalValue = stripped.slice(0, 2);
    if (stripped.length > 2) {
      finalValue += "." + stripped.slice(2, 5);
    }
    if (stripped.length > 5) {
      finalValue += "." + stripped.slice(5, 8);
    }
    if (stripped.length > 8) {
      finalValue += "/" + stripped.slice(8, 12);
    }
    if (stripped.length > 12) {
      finalValue += "-" + stripped.slice(12, 14);
    }
    return finalValue;
  }
};
