import React, { useState, useEffect } from "react";
import { Card, PrimaryButton } from "../../components";
import { styled, Slide } from "@mui/material";
import { Grid, IconButton } from "@mui/material";
import Cadeado from "../../assets/Ei-lock.svg.png";
import Hands from "../../assets/hands.png";
import Cama from "../../assets/cama-hospital.png";
import { formatCoin } from "../../utils/formatCoin";
import DownloadIcon from "@mui/icons-material/Download";
import Carousel from "react-elastic-carousel";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PlanoLogo from "./PlanoLogo";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/cotacao.actions";
import {
  getCotacaoDados,
  getCotacaoPdf,
} from "../../selectors/cotacao.selectors";
import { createRef } from "react";

const StyledH2 = styled("h2")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 28px;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 1.5px;
  font-size: ${({ size }) => size};
`;
const StyledH3 = styled("h3")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: #ffffff;
  font-size: 20px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: #ffffff;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH5 = styled("h5")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: #ffffff;
  font-size: 10px;
  margin: 0px;
  margin-top: -5px;
  text-align: center;
`;

const StyledMonyLabel = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 33px;
  margin-top: -10px;
  margin-bottom: 25px;
  letter-spacing: 1px;
`;

const StyledLogoDiv = styled("div")`
  color: #ffffff;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCadeadoIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: invert(100%);
`;
const StyledHandsIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
`;
const StyledCamaIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
`;

const StyledSVGDiv = styled("div")`
  padding: 8px;
  align-items: center;
  margin-bottom: 10px;
`;

const StyledCard = styled(Card)`
  width: 260px !important;
  margin-right: auto;
  margin-left: auto;
  min-height: 100%;

  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px white !important;

  & .rec.rec-dot_active {
    background-color: #415781ff;
    box-shadow: 0 0 1px 3px #415781ff;
  }
  & .rec.rec-dot {
    box-shadow: 0 0 1px 3px #415781ff;
  }

  & .rec-pagination {
    margin-bottom: 15px;
    margin-top: -15px;
    z-index: 3333333;
  }
`;

const StyledCardInside = styled(Card)`
  padding: 0px;
  position: bottom;
  bottom: 0;
  width: 100%;
  background-color: #849dce;
  box-shadow: 0px 0px white !important;
`;

const StyledGrid = styled(Grid)``;

const StyledGridContainer = styled(Grid)`
  min-width: 300px !important;
  box-shadow: 0px 0px white !important;
`;
const StyledGrid1 = styled(Grid)`
  min-height: 123px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(PrimaryButton)`
  margin-bottom: 20px;
  background-color: #415781ff;
  width: 85%;
`;

const StyledIconButtonLeft = styled(IconButton)`
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  z-index: 10000000;
`;
const StyledIconButtonRight = styled(IconButton)`
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  left: 81%;
  z-index: 10000000;
`;

const PropostaPlanoCard = ({
  data,
  isMiddle,
  buttonAnimation,
  setMiddleCard,
  index,
}) => {
  const handleClick = () => {
    setMiddleCard(index);
  };
  const { data: dataFinal } = useSelector(getCotacaoDados);
  const { loading } = useSelector(getCotacaoPdf);
  const [dataPDF, setDataPDF] = useState([]);
  var carousel = createRef();

  const dispatch = useDispatch();

  useEffect(() => {
    setDataPDF(data[0]);
  }, [data, index]);

  const handleClickPrint = async (e) => {
    dispatch(
      actions.criarCotacaoResultado.request({
        ID_COTACAO: dataFinal.ID_COTACAO,
        ID_SEGURADORA_PLANO_VALOR: dataPDF.id_seguradora_plano_valor,
        TP_ENVIO_PROPOSTA: "D",
      })
    );
    dispatch(
      actions.obterCotacaoPDF.request({
        ...dataPDF,
        nome_cliente: dataFinal.NM_CONTATO,
      })
    );
    dispatch(
      actions.obterAnexoPDF.request({
        id_seguradora_plano_valor: dataPDF.id_seguradora_plano_valor,
      })
    );
  };

  useEffect(() => {
    if (isMiddle) {
      dispatch(actions.armazenarCardFinal(dataPDF));
      carousel.goTo(0);
    }
    // eslint-disable-next-line
  }, [isMiddle]);

  const handleChange = (index) => {
    setDataPDF(data[index]);
    dispatch(actions.armazenarCardFinal(data[index]));
  };

  return (
    <StyledGridContainer item xs={12} md={4}>
      <StyledCard
        style={{
          cursor: !isMiddle ? "pointer" : "auto",
          opacity: !isMiddle ? 0.6 : 1,
        }}
        onClick={handleClick}
      >
        <StyledLogoDiv>
          <PlanoLogo
            url={data[0].DS_CAMINHO_LOGO_CARD}
            seguradora={data[0].nome_seguradora}
          />
        </StyledLogoDiv>
        <StyledCardInside>
          <Carousel
            ref={(ref) => (carousel = ref)}
            pagination={true}
            onChange={({ index }) => handleChange(index)}
            renderArrow={({ type, onClick, isEdge }) => {
              const Pointer =
                type === "PREV" ? (
                  <StyledIconButtonLeft onClick={onClick} disabled={isEdge}>
                    <ChevronLeftIcon />
                  </StyledIconButtonLeft>
                ) : (
                  <StyledIconButtonRight onClick={onClick} disabled={isEdge}>
                    <ChevronRightIcon />
                  </StyledIconButtonRight>
                );
              return Pointer;
            }}
          >
            {data.map((item, index) => {
              return (
                <Grid key={index} container>
                  <StyledGrid1 item xs={12} md={12}>
                    <StyledH2
                      size={item.nome_seguradora.length > 30 ? "22px" : "28px"}
                    >
                      {item.nome_seguradora}
                    </StyledH2>
                  </StyledGrid1>
                  <StyledGrid item xs={12} md={12}>
                    <StyledH3>{item.nome_plano}</StyledH3>
                  </StyledGrid>
                  <StyledGrid item xs={12} md={12}>
                    <StyledH4>{item.nome_rede_credenciada}</StyledH4>
                  </StyledGrid>
                  <StyledGrid item xs={4} md={4}>
                    <StyledSVGDiv>
                      <StyledCadeadoIMG src={Cadeado} alt="Cadeado" />
                      <StyledH5>
                        {item.tipo_adesao === "L" ? "Livre" : "Compulsória"}
                      </StyledH5>
                    </StyledSVGDiv>
                  </StyledGrid>
                  <StyledGrid item xs={4} md={4}>
                    <StyledSVGDiv>
                      <StyledCamaIMG src={Cama} alt="Cama hospital" />
                      <StyledH5>
                        {item.tipo_acomodacao === "A"
                          ? "Apartamento"
                          : "Enfermaria"}
                      </StyledH5>
                    </StyledSVGDiv>
                  </StyledGrid>
                  <StyledGrid item xs={4} md={4}>
                    <StyledSVGDiv>
                      <StyledHandsIMG src={Hands} alt="Mapa do Brasil" />
                      <StyledH5>
                        {item.tipo_coparticipacao === "C"
                          ? item.porcentagem_coparticipacao
                            ? `${item.porcentagem_coparticipacao}% de`
                            : "Com"
                          : "Sem"}{" "}
                        coparticipação{" "}
                      </StyledH5>
                    </StyledSVGDiv>
                  </StyledGrid>
                  <StyledGrid item xs={12} md={12}>
                    <StyledMonyLabel>
                      {formatCoin(item.custo_total)}*
                    </StyledMonyLabel>
                  </StyledGrid>
                </Grid>
              );
            })}
          </Carousel>
          {isMiddle && (
            <Slide direction="up" in={buttonAnimation} mountOnEnter>
              <StyledGrid item xs={12} md={12}>
                <StyledButton
                  loading={loading}
                  onClick={handleClickPrint}
                  startIcon={!loading ? <DownloadIcon /> : <></>}
                >
                  BAIXAR PROPOSTA
                </StyledButton>
              </StyledGrid>
            </Slide>
          )}
        </StyledCardInside>
      </StyledCard>
    </StyledGridContainer>
  );
};

export default PropostaPlanoCard;
