import React from "react";
import PropostaCotacaoCard from "./PropostaCotacaoCard";
import PropostaDownloadCotacaoCard from "./PropostaDownloadCotacaoCard";

const DetalhesCard = ({ data, dataTotal, dataEnviado }) => {
  return (
    <>
      {dataEnviado.length > 0 && (
        <PropostaCotacaoCard
          dados={dataTotal}
          key={dataEnviado[0].ID_COTACAO_RESULTADO}
          data={dataEnviado[0]}
          index={0}
        />
      )}
      {data.length > 0 &&
        data.map((item, index) => {
          return (
            <PropostaDownloadCotacaoCard
              key={item.ID_COTACAO_RESULTADO}
              data={item}
              dataTotal={dataTotal}
              index={index}
            />
          );
        })}
    </>
  );
};
export default DetalhesCard;
