import { getJwt } from "./localStorage";

const axios = require("axios");

const baseAPI = process.env.REACT_APP_API_BASE_URL;

const instance = axios.create({
  timeout: 0,
  withCredentials: false,
});

export const unauthenticatedRequest = async ({
  url,
  method,
  queryParams,
  headers = {},
  body,
  ...other
}) => {
  return await _request({
    url,
    method,
    ...other,
    headers: {
      ...headers,
    },
    queryParams,
    body,
  });
};

const getAuthorizationHeaders = async () => {
  const accessToken = await getJwt();
  return {
    Authorization: `Bearer ${accessToken}`,
  };
};

export const authenticatedRequest = async ({
  isResourceService,
  url,
  method,
  queryParams,
  body,
  headers,
  base,
  ...other
}) => {
  return await _request({
    url,
    method,
    base,
    ...other,
    headers: {
      ...(await getAuthorizationHeaders()),
      ...headers,
    },
    queryParams,
    body,
  });
};

const _request = async ({
  url,
  base = baseAPI,
  method,
  headers = undefined,
  queryParams = {},
  onDownloadProgress,
  body,
  ...other
}) => {
  url = base + url;

  Object.keys(queryParams).forEach(
    (key) =>
      queryParams.hasOwnProperty(key) &&
      !queryParams[key] &&
      delete queryParams[key]
  );

  return instance.request({
    url,
    method,
    headers,
    ...other,
    onDownloadProgress,
    params: queryParams,
    data: body,
  });
};

export default instance;

export const handleApiErrors = (response) => response;
