import { createAsyncAction, createSyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_HOSPITAIS: "@@cotacao/OBTER_HOSPITAIS",
  OBTER_COTACAO_INICIAL: "@@cotacao/OBTER_COTACAO_INICIAL",
  OBTER_COTACAO_FINAL: "@@cotacao/OBTER_COTACAO_FINAL",
  CRIAR_COTACAO: "@@cotacao/CRIAR_COTACAO",
  CRIAR_COTACAO_RESULTADO: "@@cotacao/CRIAR_COTACAO_RESULTADO",
  OBTER_COTACAO_PDF: "@@cotacao/OBTER_COTACAO_PDF",
  OBTER_ANEXO_PDF: "@@cotacao/OBTER_ANEXO_PDF",
  ARMAZENAR_CARD_FINAL: "@@cotacao/ARMAZENAR_CARD_FINAL",
  ENVIAR_PROPOSTA: "@@cotacao/ENVIAR_PROPOSTA",
};

export const actions = {
  obterHospitais: createAsyncAction(types.OBTER_HOSPITAIS),
  obterCotacaoInicial: createAsyncAction(types.OBTER_COTACAO_INICIAL),
  obterCotacaoFinal: createAsyncAction(types.OBTER_COTACAO_FINAL),
  criarCotacao: createAsyncAction(types.CRIAR_COTACAO),
  criarCotacaoResultado: createAsyncAction(types.CRIAR_COTACAO_RESULTADO),
  obterCotacaoPDF: createAsyncAction(types.OBTER_COTACAO_PDF),
  obterAnexoPDF: createAsyncAction(types.OBTER_ANEXO_PDF),
  armazenarCardFinal: (card) =>
    createSyncAction(types.ARMAZENAR_CARD_FINAL, { card }),
  enviarProposta: createAsyncAction(types.ENVIAR_PROPOSTA),
};
