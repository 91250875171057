import React from "react";
import { styled, TextField as MTextField } from "@mui/material";

const StyledTextField = styled(MTextField)`
  & .MuiFormLabel-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.title};
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white};
    letter-spacing: 1.4px;
  }
  & .MuiFormLabel-root.Mui-focused {
    color: ${({ theme }) => theme.colors.white};
  }
  & .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: ${({ theme }) => theme.fonts.size.inputs.content};
    font-weight: 400;
    text-align: left;
    border-radius: 15px;
    color: ${({ theme }) => theme.colors.white};
  }
  & .MuiFilledInput-underline:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.gray["800"]};
  }
  & .Mui-error:after {
    border-bottom: 2px solid ${({ theme }) => theme.colors.error};
  }
  & .MuiFormLabel-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiFormHelperText-root.Mui-error {
    color: ${({ theme }) => theme.colors.error};
  }
  & .MuiOutlinedInput-notchedOutline {
    border-bottom-color: #9FB3D9;
    border-left-color: #9FB3D9;
    border-right-color: #9FB3D9;
    border-top-color: #9FB3D9;
  }
  & .MuiSelect-icon {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const TextField = ({
  shrinked = true,
  fullWidth = true,
  InputLabelProps,
  ...other
}) => (
  <StyledTextField
    InputLabelProps={{
      shrink: shrinked,
      ...InputLabelProps,
    }}
    fullWidth={fullWidth}
    {...other}
  />
);

export default TextField;
