import React from "react";
import { Card, PrimaryButton } from "../../components";
import { Grid, styled } from "@mui/material";
import { formatCoin } from "../../utils/formatCoin";
import DownloadIcon from "@mui/icons-material/Download";
import { getCotacaoPdf } from "../../selectors/cotacao.selectors";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/cotacao.actions";

const StyledH1 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  text-align: left;
  letter-spacing: 2px;
  font-size: 21px;
  margin: 10px;
  color: ${({ theme }) => theme.colors.primary};
`;
const StyledLabel = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  text-align: left;
  letter-spacing: 1.2px;
  font-size: 14px;
  margin: 10px;
  margin-bottom: -13px;
  color: ${({ theme }) => theme.colors.primary};
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
`;

const StyledGridContainer = styled(Grid)`
  padding: 20px;
`;

const StyledButton = styled(PrimaryButton)`
  width: 220px;
  margin-top: 20px;
`;

const PropostaDownloadCotacaoCard = ({ data, index, dataTotal }) => {
  const { loading } = useSelector(getCotacaoPdf);
  const dispatch = useDispatch();

  const handleClickPrint = async (e) => {
    dispatch(
      actions.obterCotacaoPDF.request({
        id_seguradora_plano_valor: data.ID_SEGURADORA_PLANO_VALOR,
        nome_cliente: dataTotal.NM_CONTATO,
        vidas_por_faixa: {
          entre_0_18: dataTotal.QT_VIDAS_00_18,
          entre_19_23: dataTotal.QT_VIDAS_19_23,
          entre_24_28: dataTotal.QT_VIDAS_24_28,
          entre_29_33: dataTotal.QT_VIDAS_29_33,
          entre_34_38: dataTotal.QT_VIDAS_34_38,
          entre_39_43: dataTotal.QT_VIDAS_39_43,
          entre_44_48: dataTotal.QT_VIDAS_44_48,
          entre_49_53: dataTotal.QT_VIDAS_49_53,
          entre_54_58: dataTotal.QT_VIDAS_54_58,
          entre_59_99: dataTotal.QT_VIDAS_59_99,
        },
      })
    );
    dispatch(
      actions.obterAnexoPDF.request({
        id_seguradora_plano_valor: data.ID_SEGURADORA_PLANO_VALOR,
      })
    );
  };

  return (
    <StyledCard key={data.ID_COTACAO_RESULTADO}>
      <StyledGridContainer container>
        <Grid item xs={12} sm={6} md={6}>
          <StyledLabel>Seguradora</StyledLabel>
          <StyledH1>
            {data.SeguradoraPlanoValor.SeguradoraPlano.Seguradora.NM_SEGURADORA.toUpperCase()}
          </StyledH1>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <StyledLabel>Plano</StyledLabel>
          <StyledH1>
            {data.SeguradoraPlanoValor.SeguradoraPlano.NM_PLANO.toUpperCase()}
          </StyledH1>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <StyledLabel>Adesão</StyledLabel>
          <StyledH1>
            {data.SeguradoraPlanoValor.TP_ADESAO === "L"
              ? "LIVRE"
              : "COMPULSÓRIA"}
          </StyledH1>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <StyledLabel>Acomodação</StyledLabel>
          <StyledH1>
            {data.SeguradoraPlanoValor.TP_ACOMODACAO === "E"
              ? "ENFERMARIA"
              : "APARTAMENTO"}
          </StyledH1>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <StyledLabel>Coparticipação</StyledLabel>
          <StyledH1>
            {data.SeguradoraPlanoValor.TP_COPARTICIPACAO === "C"
              ? `SIM${
                  data.SeguradoraPlanoValor.PC_COPARTICIPACAO
                    ? ` - ${data.SeguradoraPlanoValor.PC_COPARTICIPACAO}%`
                    : ""
                }`
              : "NÃO"}
          </StyledH1>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <StyledLabel>Valor</StyledLabel>
          <StyledH1>{formatCoin(data.CUSTO_TOTAL)}</StyledH1>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <StyledButton
            loading={loading}
            onClick={handleClickPrint}
            startIcon={!loading ? <DownloadIcon /> : <></>}
          >
            BAIXAR PROPOSTA
          </StyledButton>
        </Grid>
      </StyledGridContainer>
    </StyledCard>
  );
};

export default PropostaDownloadCotacaoCard;
