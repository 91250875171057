import BasePage from "../BasePage";
import React from "react";
import { Card, Grid, styled } from "@mui/material";
import VerticalLinearStepper from "./StepperPage";
import SendIcon from "@mui/icons-material/Send";
import PropostaEnviadaCard from "./PropostaEnviadaCard";
import { getEnviarProposta } from "../../selectors/cotacao.selectors";
import { useSelector } from "react-redux";
import Spinner from "../../components/spinner/Spinner";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: white;
  letter-spacing: 3.7px;
  font-size: 34px;
  text-align: center;
  margin-bottom: -20px;
  @media only screen and (max-width: 1192px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;
const StyledH3 = styled("h3")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: white;
  letter-spacing: 2.1px;
  font-size: 19px;
  text-align: center;
  @media only screen and (max-width: 1192px) {
    font-size: 18px;
    margin-bottom: 10px;
  }
`;

const StyledGrid = styled(Grid)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledCard = styled(Card)`
  border-radius: 15px;
  padding: 5px;
  position: bottom;
  bottom: 0;
  width: 100%;
  background-color: #849dce;
`;

const StyledChevronRightIcon = styled(SendIcon)`
  margin-top: 15px;
  margin-bottom: -5px;
  margin-right: 20px;
  width: 43px;
  height: 39px;
  fill: #ffffff;
  @media only screen and (max-width: 1192px) {
    width: 22px;
    height: 20px;
    margin-bottom: -2px;
  }
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

const PropostaEnviadaPage = ({ setStep }) => {
  const { loading } = useSelector(getEnviarProposta);

  if (loading) {
    return <Spinner />;
  }

  return (
    <BasePage isCard={true}>
      <Grid container>
        <StyledGrid item xs={12} md={2}>
          <VerticalLinearStepper
            isLast={true}
            setStep={setStep}
            activeStep={4}
          />
        </StyledGrid>
        <StyledGrid item xs={12} md={10}>
          <StyledGrid item xs={12} md={12}>
            <StyledCard>
              <StyledH1>
                <StyledChevronRightIcon fontSize="large" />
                PROPOSTA ENVIADA COM SUCESSO!
              </StyledH1>
              <StyledH3>
                Em breve, um dos nossos especialistas entrará em contato com
                você.
              </StyledH3>
            </StyledCard>
          </StyledGrid>
          <StyledGrid
            direction="column"
            alignItems="center"
            justifyContent="center"
            container
          >
            <PropostaEnviadaCard />
          </StyledGrid>
        </StyledGrid>
      </Grid>
    </BasePage>
  );
};

export default PropostaEnviadaPage;
