import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as dashboard } from "../reducers/dashboard.actions";
import { put } from "redux-saga/effects";

const listarPorcentagemDiaApi = (values) => {
  return authenticatedRequest({
    url: "/dashboard/dia",
    method: "post",
    body: {
      dia: values?.dia ?? new Date(),
    },
  });
};

const listarPorcentagemDia = basicFlow({
  actionGenerator: dashboard.obterPorcentagemDia,
  api: listarPorcentagemDiaApi,
});
const listarPorcentagemMesApi = (values) => {
  return authenticatedRequest({
    url: "/dashboard/mes",
    method: "post",
    body: {
      dia: values?.dia ?? new Date(),
    },
  });
};

const listarPorcentagemMes = basicFlow({
  actionGenerator: dashboard.obterPorcentagemMes,
  api: listarPorcentagemMesApi,
});
const listarPorcentagemAnoApi = (values) => {
  return authenticatedRequest({
    url: "/dashboard/ano",
    method: "post",
    body: {
      dia: values?.dia ?? new Date(),
    },
  });
};

const listarPorcentagemAno = basicFlow({
  actionGenerator: dashboard.obterPorcentagemAno,
  api: listarPorcentagemAnoApi,
});

const listarCotacoesApi = (values) => {
  return authenticatedRequest({
    url: "/dashboard/cotacoes",
    method: "post",
    body: {
      dia: values?.dia ?? new Date(),
      filtro: values?.filtro ?? "",
      pagina: values?.pagina ?? 1,
      limite: values?.limite ?? 10,
    },
  });
};

const listarCotacoes = basicFlow({
  actionGenerator: dashboard.obterCotacoes,
  api: listarCotacoesApi,
});

const listarPropostasNaoIntegradasApi = (values) => {
  return authenticatedRequest({
    url: "/dashboard/propostas-nao-integradas",
    method: "post",
  });
};

const listarPropostasNaoIntegradas = basicFlow({
  actionGenerator: dashboard.obterPropostasNaoIntegradas,
  api: listarPropostasNaoIntegradasApi,
});

const listarHospitaisApi = () => {
  return authenticatedRequest({
    url: "/dashboard/hospitais",
    method: "post",
  });
};

const listarHospitais = basicFlow({
  actionGenerator: dashboard.obterHospitais,
  api: listarHospitaisApi,
});

const listarSeguradorasApi = () => {
  return authenticatedRequest({
    url: "/dashboard/seguradoras",
    method: "post",
  });
};

const listarSeguradoras = basicFlow({
  actionGenerator: dashboard.obterSeguradoras,
  api: listarSeguradorasApi,
});

const listarSeguradoraPlanosApi = (values) => {
  return authenticatedRequest({
    url: "/dashboard/seguradora-planos",
    method: "post",
    body: {
      id_seguradora: values.id_seguradora,
    },
  });
};

const listarSeguradoraPlanos = basicFlow({
  actionGenerator: dashboard.obterSeguradoraPlanos,
  api: listarSeguradoraPlanosApi,
});

const listarSeguradoraPeriodoApi = (values) => {
  return authenticatedRequest({
    url: "/dashboard/seguradora-periodo",
    method: "post",
    body: {
      id_seguradora: values.id_seguradora,
    },
  });
};

const listarSeguradoraPeriodo = basicFlow({
  actionGenerator: dashboard.obterSeguradoraPeriodo,
  api: listarSeguradoraPeriodoApi,
});

function* DashboardRouteWatcher() {
  yield routeWatcher(routes.DASHBOARD, function* () {
    yield put(dashboard.obterPorcentagemDia.request());
    yield put(dashboard.obterPorcentagemMes.request());
    yield put(dashboard.obterPorcentagemAno.request());
    yield put(dashboard.obterHospitais.request());
    yield put(dashboard.obterSeguradoras.request());
    yield put(dashboard.obterPropostasNaoIntegradas.request());
  });
}

export const sagas = [
  DashboardRouteWatcher(),
  listarHospitais.watcher(),
  listarPorcentagemDia.watcher(),
  listarPorcentagemMes.watcher(),
  listarPorcentagemAno.watcher(),
  listarCotacoes.watcher(),
  listarSeguradoras.watcher(),
  listarSeguradoraPlanos.watcher(),
  listarSeguradoraPeriodo.watcher(),
  listarPropostasNaoIntegradas.watcher(),
];
