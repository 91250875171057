import React, { useState } from "react";
import {
  CircularProgress,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Download } from "@mui/icons-material";
import { actions } from "../../reducers/carga.actions";
import { getDownload } from "../../selectors/carga.selectors";
import { formatDataHora } from "../../utils/formatDataHora";

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #b4cfe7ff;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
  height: 40px !important;
`;
const StyledTableNomeCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  min-width: 250px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
`;

const StyledTableCellTitle = styled(TableCell)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;

  border-bottom: none !important;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: #849dce;
`;

const TabelaHistórico = ({ data, handlePagination }) => {
  const [downloadId, setDownloadId] = useState("");
  const dispatch = useDispatch();

  const { loading } = useSelector(getDownload);

  return (
    <>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCellTitle></StyledTableCellTitle>
            <StyledTableCellTitle align="center"></StyledTableCellTitle>
            <StyledTableCellTitle align="center"></StyledTableCellTitle>
            <StyledTableCellTitle align="center"></StyledTableCellTitle>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.resultado.map((item, index) => {
              const handleDownload = () => {
                setDownloadId(index);
                dispatch(
                  actions.download_planilha.request({
                    CD_ARTEFATO: item.CD_ARTEFATO,
                    NM_ARQUIVO: item.NM_ARQUIVO,
                  })
                );
              };
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center">
                    #{item.ID_CARGA_CONFIGURACAO}
                  </StyledTableCell>
                  <StyledTableNomeCell align="center">
                    {item.Usuario.nome}
                  </StyledTableNomeCell>
                  <StyledTableCell align="center">
                    {formatDataHora(item.DT_CARGA)}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Tooltip
                      title={"Baixar Planilha"}
                      placement="right-start"
                      arrow
                    >
                      <IconButton disabled={loading} onClick={handleDownload}>
                        {!loading ? (
                          <Download />
                        ) : downloadId === index ? (
                          <StyledCircularProgress
                            thickness={6}
                            disableShrink
                            variant={"indeterminate"}
                            size={23}
                          />
                        ) : (
                          <Download />
                        )}
                      </IconButton>
                    </Tooltip>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </>
  );
};

export default TabelaHistórico;
