import React from "react";
import { Grid, styled } from "@mui/material";
import formatters from "../../utils/formatters";
import LogoCard from "./LogoCard";

const StyledH3 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 1.6px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 20px !important;
`;

const filesize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return `${formatters.numbers.nFixed(
      Math.round(size / (1024 * 1024), 1),
      1
    )} Mb`;
  }
  if (size > 1024) {
    // Mb
    return `${formatters.numbers.nFixed(size / 1024, 1)} Kb`;
  }
  return `${size} bytes`;
};

const LogoCardContainer = ({
  data,
  isFile,
  isAtualizar,
  setIsAtualizar,
  setIsFile,
  formProps,
  setFileToRender,
}) => {
  return (
    <StyledGrid>
      <StyledH3>Logo Card</StyledH3>
      <LogoCard
        data={data}
        isFile={isFile}
        isAtualizar={isAtualizar}
        setIsAtualizar={setIsAtualizar}
        setIsFile={setIsFile}
        filesize={filesize}
        formProps={formProps}
        setFileToRender={setFileToRender}
      />
    </StyledGrid>
  );
};

export default LogoCardContainer;
