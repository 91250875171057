import { Grid } from "@mui/material";
import React from "react";
import CardsGridSkeleton from "./CardsGridSkeleton";
import CotacoesCard from "./CotacoesCard";

const CardsGrid = ({ data, loading }) => {
  return (
    <>
      {loading ? (
        <CardsGridSkeleton />
      ) : (
        <>
          {data.resultado &&
            data.resultado.map((item) => {
              return (
                <Grid key={item.ID_COTACAO} item xs={12} sm={6} md={3}>
                  <CotacoesCard data={item} />
                </Grid>
              );
            })}
        </>
      )}
    </>
  );
};

export default CardsGrid;
