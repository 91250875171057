import { utcToZonedTime, format } from "date-fns-tz";

export const formatDataHora = (dateToFormat) => {
  const date = new Date(dateToFormat);
  const timeZone = "America/Sao_Paulo";
  const zonedDate = utcToZonedTime(date, timeZone);
  const pattern = "dd/MM/yyyy";
  const pattern2 = "HH:mm:ss";
  const output = format(zonedDate, pattern, {
    timeZone: "America/Sao_Paulo",
  });
  const output2 = format(zonedDate, pattern2, {
    timeZone: "America/Sao_Paulo",
  });

  return output + " - " + output2;
};
