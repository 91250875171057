import BasePage from "../BasePage";
import React, { useEffect, useState } from "react";
import { Card, Grid, Skeleton, styled } from "@mui/material";
import PDFContainer from "./PDFContainer";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/buttons/PrimaryButton";

import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { getSeguradoraProcessing } from "../../selectors/seguradora.selectors";
import LogoCardContainer from "./LogoCardContainer";
import LogoTableContainer from "./LogoTableContainer";
import validators from "../../utils/validators";
import { useForm } from "react-hook-form";
import { actions } from "../../reducers/seguradora.actions";
import { toast } from "react-toastify";
import { ControlledTextField, TextField } from "../../components/inputs";

const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  margin-top: 75px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  margin-bottom: 50px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
    margin-top: 50px;
  }
`;
const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const ButtonContainer = styled("div")`
  display: flex;
  justify-content: flex-end;

  @media (max-width: 900px) {
    display: flex;
    justify-content: center !important;
    margin-right: 80px;
    margin-left: 100px;
  }
`;

const StyledButton = styled(Button)`
  width: 200px !important;
  margin-right: 5px;
  @media (max-width: 900px) {
    width: 120px !important;
    margin-right: 0px;
  }
`;

const StyledLeftButton = styled(Button)`
  width: 200px !important;
  margin-right: 20px;
  @media (max-width: 900px) {
    width: 120px !important;
    margin-right: 0px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 850px;
  margin-top: -110px;
`;

const StyledControlledTextField = styled(ControlledTextField)`
    background-color: #ffffff;
    margin-bottom:4px;
    margin-top:4px;
    margin-left: -6px;

    flex: 1!important;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
    color: ${({ theme }) => theme.colors.primary};
    font-size: 17px;



    @media (max-width: 900px) {
      margin-bottom:5px;
      margin-top:5px;
    }
    


    & .MuiInputBase-input{
      font-family: ${({ theme }) => theme.fonts.family.subTitle};
      color: ${({ theme }) => theme.colors.primary};
      font-size: 17px;
      }


  & .MuiInputLabel-root {
    background-color: #ffffff;
    color: #849dce !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #D11F44!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #849dce;
    },
    &:hover fieldset {
      border-color: #849dce;
    },
    &.Mui-focused fieldset {
      border: 3px solid #849dce;
    },
    &.Mui-disabled fieldset {
      border-color: #849dce;
    },
    &.Mui-error fieldset {
    border: 2.5px solid #D11F44;
    },`;

const StyledTextField = styled(TextField)`
    background-color: #ffffff;
    margin-left: -6px;
    margin-bottom:4px;
    margin-top:4px;
    flex: 1!important;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
    color: ${({ theme }) => theme.colors.primary};
    font-size: 17px;



    @media (max-width: 900px) {
      margin-bottom:5px;
      margin-top:5px;
    }
    


    & .MuiInputBase-input{
      font-family: ${({ theme }) => theme.fonts.family.subTitle} !important;
      color: ${({ theme }) => theme.colors.primary} !important;
      font-size: 17px;
      -webkit-text-fill-color: #324873 !important;
  
      }


  & .MuiInputLabel-root {
    background-color: #ffffff;
    color: #849dce !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #D11F44!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #849dce;
    },
    &:hover fieldset {
      border-color: #849dce;
    },
    &.Mui-focused fieldset {
      border: 3px solid #849dce;
    },
    &.Mui-disabled fieldset {
      border-color: #849dce;
      color: #849dce !important;

    },
    &.Mui-error fieldset {
    border: 2.5px solid #D11F44;
    },`;

const StyledGrid = styled(Grid)`
  padding: 5px;
`;

const EditarSeguradoraPage = ({ data, loading }) => {
  const processing = useSelector(getSeguradoraProcessing);
  const handleClickVoltar = () => {
    dispatch(routeActions.redirectTo(routes.SEGURADORA));
  };
  const [isFileLogoCard, setIsFileLogoCard] = useState(true);
  const [isAtualizarLogoCard, setIsAtualizarLogoCard] = useState(false);
  const [isFileAnexo, setIsFileAnexo] = useState(true);
  const [isAtualizarAnexo, setIsAtualizarAnexo] = useState(false);
  const [fileToRender, setFileToRender] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    if (data.NM_ARQUIVO_ANEXO_PROPOSTA) {
      setIsAtualizarAnexo(true);
    }
    if (data.DS_CAMINHO_LOGO_CARD) {
      setIsAtualizarLogoCard(true);
    }
  }, [data]);

  const rules = {
    LogoCardSeguradoras: validators.array({ required: false }),
    fileSeguradoras: validators.array({ required: false }),
    CD_INTEGRACAO: validators.string({ required: false }),
  };
  const defaultValues = {
    LogoCardSeguradoras: [],
    fileSeguradoras: [],
    CD_INTEGRACAO: data.CD_INTEGRACAO,
  };
  const initialValues = {
    LogoCardSeguradoras: [],
    fileSeguradoras: [],
    CD_INTEGRACAO: data.CD_INTEGRACAO,
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
    initialValues,
  };

  useEffect(() => {
    formProps.setValue("CD_INTEGRACAO", data.CD_INTEGRACAO);
    // eslint-disable-next-line
  }, [data]);

  const handleSubmit = (values) => {
    dispatch(
      actions.atualizarCodigoIntegracao.request({
        id: data.ID_SEGURADORA,
        CD_INTEGRACAO: values.CD_INTEGRACAO,
      })
    );
    if (!isAtualizarLogoCard) {
      dispatch(
        actions.enviarLogoCard.request({
          LogoCardSeguradoras: values.LogoCardSeguradoras,
          id: data.ID_SEGURADORA,
        })
      );
    }
    if (!isAtualizarAnexo) {
      dispatch(
        actions.enviarAnexo.request({
          fileSeguradoras: values.fileSeguradoras,
          id: data.ID_SEGURADORA,
        })
      );
    }
    dispatch(routeActions.redirectTo(routes.SEGURADORA));
    toast.success("Seguradora atualizada com sucesso", {
      theme: "colored",
      icon: false,
      style: { backgroundColor: "#203d8b" },
    });
  };

  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />;
      </StyledBasePage>
    );
  }

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <form onSubmit={formProps.handleSubmit(handleSubmit)}>
        <StyledCard>
          <StyledH2>#{data.ID_SEGURADORA}</StyledH2>
          <Grid container>
            <StyledGrid item xs={12} sm={8} md={8}>
              <StyledTextField
                disabled
                value={data.NM_SEGURADORA}
                fullWidth
                name="seguradora"
                label="Seguradora"
              />
            </StyledGrid>
            <StyledGrid item xs={12} sm={4} md={4}>
              <StyledControlledTextField
                formProps={formProps}
                fullWidth
                name="CD_INTEGRACAO"
                label="Código de integração"
                inputProps={{ maxLength: 10 }}
              />
            </StyledGrid>
            <Grid item xs={12} sm={6} md={4}>
              <PDFContainer
                isAtualizar={isAtualizarAnexo}
                setIsAtualizar={setIsAtualizarAnexo}
                formProps={formProps}
                setIsFile={setIsFileAnexo}
                isFile={isFileAnexo}
                data={data}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LogoCardContainer
                isAtualizar={isAtualizarLogoCard}
                setIsAtualizar={setIsAtualizarLogoCard}
                formProps={formProps}
                setIsFile={setIsFileLogoCard}
                isFile={isFileLogoCard}
                data={data}
                setFileToRender={setFileToRender}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <LogoTableContainer
                isAtualizar={isAtualizarLogoCard}
                fileToRender={fileToRender}
                data={data}
              />
            </Grid>
          </Grid>
          <ButtonContainer>
            <StyledLeftButton onClick={handleClickVoltar}>
              Voltar
            </StyledLeftButton>
            <StyledButton type={"submit"} loading={processing}>
              Atualizar
            </StyledButton>
          </ButtonContainer>
        </StyledCard>
      </form>
    </StyledBasePage>
  );
};

export default EditarSeguradoraPage;
