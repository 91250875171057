import { ExpandMoreSharp, Search } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
  styled,
} from "@mui/material";
import React from "react";
import { MenuItem, PrimaryButton } from "../../components";
import { ControlledTextField } from "../../components/inputs";

const StyledTypography = styled(Typography)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.4px;
  font-size: 16px;
  text-align: right;
`;

const StyledButton = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 6px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledControlledTextField = styled(ControlledTextField)`

margin-bottom:1px;
margin-top:1px;

flex: 1!important;

@media (max-width: 900px) {
  margin-bottom:5px;
  margin-top:5px;
}


& .MuiInputLabel-root {
  background-color: #415781ff;
  color: #ffffff !important;

  padding: 8px;
  padding-top: 0px;
  padding-bottom: 0px;
}

  
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    },
    &:hover fieldset {
      border-color: #ffffff;
    },
    &.Mui-focused fieldset {
      border: 3px solid #ffffff;
    },
   `;

const StyledAccordion = styled(Accordion)`
  background-color: #415781ff;
  color: white;
`;

const Filtro = ({ formProps, handleSearch }) => {
  return (
    <StyledAccordion>
      <AccordionSummary
        expandIcon={<ExpandMoreSharp style={{ color: "white" }} />}
      >
        <StyledTypography>Filtros</StyledTypography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} columns={{ xs: 12, md: 10 }}>
          <Grid item xs={12} md={2}>
            <StyledControlledTextField
              formProps={formProps}
              fullWidth
              name="seguradora"
              label="Seguradora"
            />
          </Grid>

          <Grid item xs={12} md={2}>
            <StyledControlledTextField
              formProps={formProps}
              fullWidth
              name="empresa"
              label="Empresa"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <StyledControlledTextField
              name={"situacao"}
              formProps={formProps}
              label={"Situação"}
              select
              margin={"none"}
            >
              <MenuItem key={""} value={"Todos"}>
                Todas
              </MenuItem>
              <MenuItem key={"E"} value={"Enviada"}>
                Enviada
              </MenuItem>
              <MenuItem key={"F"} value={"Não concluída"}>
                Não concluída
              </MenuItem>
            </StyledControlledTextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <StyledControlledTextField
              name={"ordem"}
              formProps={formProps}
              label={"Ordenação"}
              select
              margin={"none"}
            >
              <MenuItem key={""} value={"Data"}>
                Data
              </MenuItem>
              <MenuItem key={"F"} value={"Valor"}>
                Valor
              </MenuItem>
            </StyledControlledTextField>
          </Grid>
          <Grid item xs={12} md={2}>
            <StyledButton startIcon={<Search />} onClick={handleSearch}>
              Aplicar
            </StyledButton>
          </Grid>
        </Grid>
      </AccordionDetails>
    </StyledAccordion>
  );
};

export default Filtro;
