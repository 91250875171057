import { Card, Grid, Skeleton, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSeguradoraPeriodo,
  getSeguradoraPlanos,
} from "../../selectors/dashboard.selectors";
import { actions } from "../../reducers/dashboard.actions";
import GraficoBarra from "./GraficoBarra";
import GraficoLinha from "./GraficoLinha";
import GraficoBarraPrincipal from "./GraficoBarraPrincipal";

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
  }
`;

const StyledGrid = styled(Grid)`
  width: 100%;
`;

const StyledGridItem = styled(Grid)`
  width: 100%;
  margin-bottom: 30px;
`;

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;
const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 1000px;
  margin-top: -220px;
`;

const GraficoCard = ({ data, loading }) => {
  const [dataAtual, setDataAtual] = useState([]);
  const [displayGraficos, setDisplayGraficos] = useState(false);
  const [colorGraficos, setColorGraficos] = useState("#b4cfe7ff");
  const dispatch = useDispatch();

  const { data: dataPlanos } = useSelector(getSeguradoraPlanos);

  const { data: dataPeriodo } = useSelector(getSeguradoraPeriodo);

  useEffect(() => {
    setDisplayGraficos(false);
  }, [data]);

  useEffect(() => {
    setDataAtual(data[0]);
  }, [data]);
  useEffect(() => {
    if (dataAtual?.id_seguradora) {
      dispatch(
        actions.obterSeguradoraPlanos.request({
          id_seguradora: dataAtual.id_seguradora,
        })
      );
      dispatch(
        actions.obterSeguradoraPeriodo.request({
          id_seguradora: dataAtual.id_seguradora,
        })
      );
    }
    // eslint-disable-next-line
  }, [dataAtual]);

  const handleClick = (index) => {
    setDataAtual(index);
    setColorGraficos(index.fill);
    setDisplayGraficos(true);
  };

  if (loading) {
    return <StyledSkeleton />;
  }

  return (
    <StyledGrid container>
      <StyledGridItem item xs={12} sm={12} md={12}>
        <StyledCard>
          <StyledH2>Cotações por Seguradora nos últimos 12 meses </StyledH2>
          <GraficoBarraPrincipal data={data} handleClick={handleClick} />
        </StyledCard>
      </StyledGridItem>
      {displayGraficos && (
        <StyledGridItem item xs={12} sm={12} md={12}>
          <StyledCard>
            <StyledH2>
              Cotações por Plano da Seguradora - {dataAtual?.name}
            </StyledH2>
            <GraficoBarra colorGraficos={colorGraficos} data={dataPlanos} />
          </StyledCard>
        </StyledGridItem>
      )}
      {displayGraficos && (
        <StyledGridItem item xs={12} sm={12} md={12}>
          <StyledCard>
            <StyledH2>Evolução nos últimos 12 meses</StyledH2>
            <GraficoLinha colorGraficos={colorGraficos} data={dataPeriodo} />
          </StyledCard>
        </StyledGridItem>
      )}
    </StyledGrid>
  );
};
export default GraficoCard;
