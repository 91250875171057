import { actions } from "./cotacao.actions";

const initialState = {
  hospitais: {
    loading: false,
    data: [],
  },
  cotacaoInicial: {
    loading: false,
    data: [],
  },
  cotacaoFinal: {
    loading: false,
    data: [],
  },
  cotacaoPDF: {
    loading: false,
    data: [],
  },
  cotacaoDados: {
    loading: false,
    data: [],
  },
  cardFinal: {
    data: [],
  },
  cotacaoResultado: {
    loading: false,
    data: [],
  },
  enviarProposta: {
    loading: false,
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@cotacao/ARMAZENAR_CARD_FINAL":
      return {
        ...state,
        cardFinal: {
          ...state.cardFinal,
          data: action.payload.card,
        },
      };

    case actions.obterHospitais.REQUEST:
    case actions.obterHospitais.SUCCESS:
    case actions.obterHospitais.FAILURE:
      return {
        ...state,
        hospitais: {
          ...state.hospitais,
          loading: action.type === actions.obterHospitais.REQUEST,
          data:
            action.type === actions.obterHospitais.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterCotacaoInicial.REQUEST:
    case actions.obterCotacaoInicial.SUCCESS:
    case actions.obterCotacaoInicial.FAILURE:
      return {
        ...state,
        cotacaoInicial: {
          ...state.cotacaoInicial,
          loading: action.type === actions.obterCotacaoInicial.REQUEST,
          data:
            action.type === actions.obterCotacaoInicial.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterCotacaoFinal.REQUEST:
    case actions.obterCotacaoFinal.SUCCESS:
    case actions.obterCotacaoFinal.FAILURE:
      return {
        ...state,
        cotacaoFinal: {
          ...state.cotacaoFinal,
          loading: action.type === actions.obterCotacaoFinal.REQUEST,
          data:
            action.type === actions.obterCotacaoFinal.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterCotacaoPDF.REQUEST:
    case actions.obterCotacaoPDF.SUCCESS:
    case actions.obterCotacaoPDF.FAILURE:
      return {
        ...state,
        cotacaoPDF: {
          ...state.cotacaoPDF,
          loading: action.type === actions.obterCotacaoPDF.REQUEST,
          data:
            action.type === actions.obterCotacaoPDF.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterAnexoPDF.REQUEST:
      return {
        ...state,
        cotacaoPDF: {
          ...state.cotacaoPDF,
          loading: action.type === actions.obterAnexoPDF.REQUEST,
        },
      };
    case actions.criarCotacao.REQUEST:
    case actions.criarCotacao.SUCCESS:
    case actions.criarCotacao.FAILURE:
      return {
        ...state,
        cotacaoDados: {
          ...state.cotacaoDados,
          loading: action.type === actions.criarCotacao.REQUEST,
          data:
            action.type === actions.criarCotacao.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.criarCotacaoResultado.REQUEST:
    case actions.criarCotacaoResultado.SUCCESS:
    case actions.criarCotacaoResultado.FAILURE:
      return {
        ...state,
        cotacaoResultado: {
          ...state.cotacaoResultado,
          loading: action.type === actions.criarCotacaoResultado.REQUEST,
          data:
            action.type === actions.criarCotacaoResultado.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.enviarProposta.REQUEST:
    case actions.enviarProposta.SUCCESS:
    case actions.enviarProposta.FAILURE:
      return {
        ...state,
        enviarProposta: {
          ...state.enviarProposta,
          loading: action.type === actions.enviarProposta.REQUEST,
        },
      };

    default:
      return state;
  }
};

export default reducer;
