import HospitalPage from "./HospitalPage";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { getHospitais } from "../../selectors/cotacao.selectors";
import Spinner from "../../components/spinner/Spinner";

const HospitalPageConnected = ({ setStep }) => {
  const { loading, data } = useSelector(getHospitais);

  const rules = {};
  const initialValues = {
    hospital: "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <HospitalPage
      formProps={formProps}
      hospitais={data}
      hospitaisLoading={loading}
      setStep={setStep}
    />
  );
};

export default HospitalPageConnected;
