import { createSelector } from "reselect";

const getDashboard = (state) => state.dashboard;

export const getPorcentagemDia = createSelector(
  getDashboard,
  (state) => state.porcentagemDia
);
export const getPorcentagemMes = createSelector(
  getDashboard,
  (state) => state.porcentagemMes
);
export const getPorcentagemAno = createSelector(
  getDashboard,
  (state) => state.porcentagemAno
);

export const getCotacoes = createSelector(
  getDashboard,
  (state) => state.cotacoes
);

export const getHospitais = createSelector(
  getDashboard,
  (state) => state.hospitais
);

export const getSeguradoras = createSelector(
  getDashboard,
  (state) => state.seguradoras
);

export const getSeguradoraPlanos = createSelector(
  getDashboard,
  (state) => state.seguradoraPlanos
);

export const getSeguradoraPeriodo = createSelector(
  getDashboard,
  (state) => state.seguradoraPeriodo
);

export const getPropostasNaoIntegradas = createSelector(
  getDashboard,
  (state) => state.propostasNaoIntegradas
);
