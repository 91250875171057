import BasePage from "../BasePage";
import React from "react";
import { PrimaryButton } from "../../components";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import VerticalLinearStepper from "./StepperPage";
import PlanoCard from "./PlanoCard";
import { useSelector } from "react-redux";
import { getCotacaoInicial } from "../../selectors/cotacao.selectors";
import Spinner from "../../components/spinner/Spinner";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 4.8px;
  font-size: 48px;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledGrid = styled(Grid)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledButton = styled(PrimaryButton)`
  min-width: 200px;
  margin-bottom: 20px;
  @media only screen and (max-width: 801px) {
    margin-top: 0px !important;
    margin-bottom: 20px;
  }
`;

const CotacaoInicialPage = ({ setStep }) => {
  const { loading, data } = useSelector(getCotacaoInicial);

  const handleClick = () => {
    setStep(2);
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <BasePage isCard={true}>
      <Grid container>
        <StyledGrid item xs={12} md={2}>
          <VerticalLinearStepper setStep={setStep} activeStep={1} />
        </StyledGrid>
        <StyledGrid item xs={12} md={10}>
          <StyledH1>Melhores Opções</StyledH1>
          <Grid
            alignItems="center"
            justifyContent="center"
            container
            spacing={3}
          >
            {data[0] && <PlanoCard data={data[0]} />}
            {data[1] && <PlanoCard data={data[1]} />}
            {data[2] && <PlanoCard data={data[2]} />}
          </Grid>
        </StyledGrid>
        <StyledGrid item md={9} />
        <Grid item xs={12} md={3}>
          <StyledButton onClick={handleClick}>DADOS CADASTRAIS</StyledButton>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default CotacaoInicialPage;
