import { createSyncAction } from "../utils/actionCreators";
import { NOT_FOUND } from "redux-first-router";

export const types = {
  NOT_FOUND: NOT_FOUND,
  OPEN_WINDOW: "@@route/OPEN_WINDOW",
  COTACAO: "@@route/COTACAO",
  LOGIN: "@@route/LOGIN",
  ESQUECI_SENHA: "@@route/ESQUECI_SENHA",
  TROCAR_SENHA: "@@route/TROCAR_SENHA",
  COTACOES: "@@route/COTACOES",
  DETALHES_COTACAO: "@@route/DETALHES_COTACAO",
  DASHBOARD: "@@route/DASHBOARD",
  USUARIO: "@@route/USUARIO",
  EDIT_USUARIOS: "@@route/EDIT_USUARIOS",
  CRIAR_USUARIOS: "@@route/CRIAR_USUARIOS",
  CARGA_SEGURADORA: "@@route/CARGA_SEGURADORA",
  HISTORICO_CARGA: "@@route/HISTORICO_CARGA",
  SEGURADORA: "@@route/SEGURADORA",
  EDITAR_SEGURADORA: "@@route/EDITAR_SEGURADORA",
};

export const actions = {
  redirectTo: (route, params = {}) => createSyncAction(route, params),
  openNewWindowWith: (route, params = {}) =>
    createSyncAction(types.OPEN_WINDOW, {
      route,
      params,
    }),
  rejectTo: (route, params = {}) =>
    createSyncAction(route, params, {
      meta: {
        location: {
          kind: "redirect",
        },
      },
    }),
};
