import BasePage from "../BasePage";
import React from "react";
import { Pagination, styled } from "@mui/material";
import { Grid } from "@mui/material";
import Filtro from "./Filtro";
import CardsGrid from "./CardsGrid";

const StyledGrid = styled(Grid)``;
const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
  margin: 25px;
`;
const StyledContainer = styled(Grid)`
  margin-top: 20px;
`;
const StyledPagination = styled(Pagination)`
  .MuiButtonBase-root {
    background-color: #849dce !important;
    color: #ffffff;
  }
  
  & .Mui-selected{
    background-color: #415781 !important;
  }
`;



const CotacoesPage = ({
  data,
  handlePagination,
  loading,
  formProps,
  handleSearch,
}) => {
  return (
    <BasePage isLoggedIn={true} isLightBlue={true}>
      <StyledContainer container spacing={2}>
        <StyledGrid item xs={12} md={12}>
          <Filtro formProps={formProps} handleSearch={handleSearch} />
        </StyledGrid>
        <CardsGrid loading={loading} data={data} />
        {data.total > 0 && (
          <>
            <StyledGridPagination item xs={12} md={12}>
              <StyledPagination
                count={Math.ceil(data.total / 12)}
                page={data.paginaAtual}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </StyledGridPagination>
          </>
        )}
      </StyledContainer>
    </BasePage>
  );
};

export default CotacoesPage;
