import { styled } from "@mui/material";

const CardDiv = styled("div")`
  background: #415781;
  border-radius: 15px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-shadow: ${({ theme }) => theme.shadows};
  opacity: 1;
`;

const Card = ({ children, ...otherProps }) => {
  return <CardDiv {...otherProps}>{children}</CardDiv>;
};

export default Card;
