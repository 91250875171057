import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/carga.actions";
import { getHistorico } from "../../selectors/carga.selectors";
import HistoricoCargaPage from "./HistoricoCargaPage";

const HistoricoCargaPageConnected = () => {
  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.listar_historico.request({
        pagina: page,
      })
    );
  };

  const { data, loading } = useSelector(getHistorico);

  return (
    <HistoricoCargaPage
      data={data}
      loading={loading}
      handlePagination={handlePagination}
    />
  );
};

export default HistoricoCargaPageConnected;
