export const formatDate = (date) => {
  const formatted =
    ("0" + date.getDate()).slice(-2) +
    "/" +
    ("0" + (date.getMonth() + 1)).slice(-2) +
    "/" +
    date.getFullYear();
  return formatted;
};

export const formatDateCotacao = (date) => {
  const formatted =
    date.slice(8) + "/" + date.slice(5, 7) + "/" + date.slice(0, 4);
  return formatted;
};
