import { all } from "redux-saga/effects";
import { sagas as cotacaoSagas } from "./cotacao.saga";
import { sagas as authSagas } from "./autenticacao.saga";
import { sagas as routeSagas } from "./rotas.saga";
import { sagas as adminstracaoSagas } from "./administracao.saga";
import { sagas as dashboardSagas } from "./dashboard.saga";
import { sagas as usuarioAdmSagas } from "./usuarioAdm.saga";
import { sagas as cargaSagas } from "./carga.saga";
import { sagas as seguradoraSagas } from "./seguradora.saga";

export default function* foo() {
  yield all([
    ...routeSagas,
    ...authSagas,
    ...cotacaoSagas,
    ...adminstracaoSagas,
    ...dashboardSagas,
    ...usuarioAdmSagas,
    ...cargaSagas,
    ...seguradoraSagas,
  ]);
}
