import React from "react";
import { Card } from "../../components";
import { Grid, styled } from "@mui/material";
import FichaSituacao from "./FichaSituacao";
import { formatCoin } from "../../utils/formatCoin";
import { useDispatch } from "react-redux";

import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  text-align: left;
  color: white;
  letter-spacing: 1.4px;
  font-size: 18px;
  margin: 10px;
  margin-top: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const StyledH3 = styled("h3")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: #ffffff;
  font-weight: 300;
  font-size: 22px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH4 = styled("h3")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 20px;
  font-weight: bold;
  margin: 0px;
  overflow: hidden;
  letter-spacing: 1.5px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-clamp: 2;
`;

const StyledCard = styled(Card)`
  min-height: 310px;
  max-width: 275px;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    transform: scale(1.01);
  }
`;

const StyledLogoDiv = styled("div")`
  color: #ffffff;
  padding: 20px;
  margin-bottom: -30px;
`;

const StyledHr = styled("hr")`
  margin-left: 10px;
  margin-right: 10px;
  border: 0;
  border-bottom: 2px solid white;
`;

const StyledMonyLabel = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 32px;
  margin-top: 20px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
`;

const StyledCardInside = styled(Card)`
  min-height: 280px;
  flex: 1;
  padding: 0px;
  position: bottom;
  bottom: 0;
  width: 100%;
  background-color: #849dce;
`;

const StyledGrid = styled(Grid)`
  min-height: 100%;
  align-items: center;
`;
const StyledGridSeguradora = styled(Grid)`
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CotacoesCard = ({ data }) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(
      routeActions.redirectTo(routes.DETALHES_COTACAO, { id: data.ID_COTACAO })
    );
  };

  return (
    <StyledCard onClick={handleClick}>
      <StyledLogoDiv>
        <FichaSituacao
          data={new Date(data.DH_CRIACAO).toLocaleDateString("pt-BR")}
          situacao={data.TP_SITUACAO_FLUXO}
        />
      </StyledLogoDiv>
      <StyledCardInside>
        <Grid container>
          <StyledGrid item xs={12} md={12}>
            <StyledH2>{data.NM_EMPRESA.toUpperCase()}</StyledH2>
          </StyledGrid>
          <StyledGrid item xs={12} md={12}>
            <StyledHr></StyledHr>
          </StyledGrid>
          <StyledGridSeguradora item xs={12} md={12}>
            <StyledH4>{data.NM_SEGURADORA}</StyledH4>
          </StyledGridSeguradora>
          <StyledGrid item xs={12} md={12}>
            <StyledH3>{data.NM_PLANO}</StyledH3>
          </StyledGrid>
          <StyledGrid item xs={12} md={12}>
            <StyledMonyLabel>
              {data.CUSTO_TOTAL !== "" ? formatCoin(data.CUSTO_TOTAL) : ""}
            </StyledMonyLabel>
          </StyledGrid>
        </Grid>
      </StyledCardInside>
    </StyledCard>
  );
};

export default CotacoesCard;
