import React, { useEffect, useState } from "react";
import { styled } from "@mui/material";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { formatCoin } from "../../utils/formatCoin";

const colors = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];

const StyledBarChart = styled(BarChart)`
  height: 1050px;
  width: 100%;
  .recharts-bar-rectangle {
    min-height: 344px;
  }
  .recharts-layer recharts-cartesian-axis-tick {
    padding: 30px;
  }
`;

const SyledContainer = styled("div")`
  min-height: 100px;

  height: ${({ size }) => size * 60}px;
  .recharts-label-list {
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
    font-size: 17px;
  }
  ,
  .recharts-cartesian-axis-tick-value {
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
    font-size: 12px;
  }
`;

const StyledToolTipDiv = styled("div")`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
`;

const StyledTip = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: black;
  text-align: left;
  letter-spacing: 1.2px;
  font-size: 15px;
  font-weight: bold;
  margin: 5px;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledToolTipDiv className="custom-tooltip">
        <StyledTip>{`${label}`}</StyledTip>
        <StyledTip>{`Quantidade : ${payload[0].value}`}</StyledTip>
        <StyledTip>{`Valor : ${formatCoin(
          payload[0].payload.Valor
        )}`}</StyledTip>
      </StyledToolTipDiv>
    );
  }

  return null;
};

const GraficoBarraPrincipal = ({ data, handleClick }) => {
  const [sizePadding, setSizePadding] = useState(0);

  useEffect(() => {
    var labelSize = 0;
    var lineSize = 0;

    if (data.length > 0) {
      data.forEach((item) => {
        if (item.Quantidade >= lineSize) {
          lineSize = item.Quantidade;
          if (labelSize < item.name.length) labelSize = item.name.length;
        }
      });
      setSizePadding(labelSize * 10);
    }
  }, [data]);

  return (
    <SyledContainer size={data.length}>
      <ResponsiveContainer width="100%" height="100%">
        <StyledBarChart
          barGap={20}
          stackOffset={"expand"}
          width={100}
          height={1000}
          layout="vertical"
          data={data}
          margin={{
            top: 5,
            right: 20,
            left: 20,
            bottom: 5,
          }}
        >
          <Tooltip
            content={<CustomTooltip />}
            labelStyle={{ color: "black" }}
            itemStyle={{ color: "black" }}
            cursor={{ fill: "transparent" }}
          />
          <XAxis
            scale={"auto"}
            tickMargin={5}
            type="number"
            padding={{ right: sizePadding }}
          />
          <YAxis scale={"auto"} type="category" dataKey="name" width={0} />
          <Bar fill="#324873" dataKey="Quantidade" onClick={handleClick}>
            <LabelList
              width={300}
              textBreakAll={false}
              offset={5}
              dataKey="name"
              position="right"
            />
            {data.map((entry, index) => (
              <Cell
                fill={colors[index > 7 ? index - 8 : index]}
                cursor="pointer"
                key={`cell-${index}`}
              />
            ))}
          </Bar>
        </StyledBarChart>
      </ResponsiveContainer>
    </SyledContainer>
  );
};
export default GraficoBarraPrincipal;
