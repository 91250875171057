import React from "react";
import { Card } from "../../components";
import { Box, styled } from "@mui/material";
import { Grid } from "@mui/material";
import Cadeado from "../../assets/Ei-lock.svg.png";
import Hands from "../../assets/hands.png";
import { formatCoin } from "../../utils/formatCoin";
import Cama from "../../assets/cama-hospital.png";
import { CloudUpload, Delete } from "@mui/icons-material";
import ControlledFileInput from "../../components/inputs/ControlledFileInput";

const StyledH2 = styled("h2")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 28px;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 1.5px;
`;
const StyledH3 = styled("h3")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: #ffffff;
  font-size: 22px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: #ffffff;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH5 = styled("h5")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: #ffffff;
  font-size: 10px;
  margin: 0px;
  margin-top: -5px;
  text-align: center;
`;

const StyledMonyLabel = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 38px;
  margin-top: 0px;
  margin-bottom: 10px;
  letter-spacing: 1.5px;
`;

const StyledH6 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 1px;
  margin-bottom: 1px;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBox = styled(Box)`
  width: 100% !important;
  min-height: 90px !important;
  max-height: 90px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;
const StyledBoxImg = styled(Box)`
  width: 100% !important;
  min-height: 90px !important;
  max-height: 90px !important;
  text-align: center;
  border: 2px #b4cfe7ff;
  border-radius: 15px;
  position: relative;
`;

const StyledCadeadoIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: invert(100%);
`;
const StyledHandsIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
`;
const StyledCamaIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
`;
const StyledSVGDiv = styled("div")`
  padding: 15px;
  align-items: center;
`;

const StyledCard = styled(Card)`
  min-height: 100%;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledCardInside = styled(Card)`
  flex: 1;
  padding: 0px;
  position: bottom;
  bottom: 0;
  width: 100%;
  background-color: #849dce;
`;

const StyledGrid = styled(Grid)`
  min-height: 100%;
  max-width: 300px !important;
  align-items: center;
  margin-bottom: 10px;
`;
const StyledGridPNG = styled(Grid)``;
const StyledGrid1 = styled(Grid)`
  min-height: 123px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledDelete = styled(Delete)`
  color: #f00e0e;
  cursor: pointer;
  fontsize: 30px;
  position: absolute;
  margin-top: 5px;
`;

const StyledIMG = styled("img")`
  max-height: 90px !important;
  padding: 20px;
`;
const LogoCard = ({
  data,
  isAtualizar,
  setIsAtualizar,
  isFile,
  setIsFile,
  fileSize,
  formProps,
  setFileToRender,
}) => {
  return (
    <StyledGrid item xs={12} md={12}>
      <StyledCard>
        {isAtualizar && (
          <StyledBoxImg>
            <StyledIMG src={data.DS_CAMINHO_LOGO_CARD} />
            <StyledDelete
              onClick={() => {
                setIsAtualizar(false);
                setFileToRender("");
                formProps.setValue("LogoCardSeguradoras", undefined);
              }}
            />
          </StyledBoxImg>
        )}
        {!isAtualizar && (
          <ControlledFileInput
            name={"LogoCardSeguradoras"}
            formProps={formProps}
            maxFiles={1}
            accept={".png"}
            multiple={false}
            renderFile={(file, index, handleClickDeleteIcon) => {
              setIsFile(false);
              setFileToRender(file);
              return (
                <StyledBoxImg>
                  <StyledIMG src={URL.createObjectURL(file)} />
                  <StyledDelete
                    onClick={() => {
                      setFileToRender("");

                      handleClickDeleteIcon(file);
                      setIsFile(true);
                    }}
                  />
                </StyledBoxImg>
              );
            }}
          >
            {isFile && (
              <StyledBox
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  "&:hover": {
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              >
                <StyledH6 style={{ marginTop: "5px" }}>
                  Arraste o arquivo para cá
                </StyledH6>
                <StyledH6>ou clique para selecioná-lo</StyledH6>
                <StyledH6>(somente arquivos com extensão .png)</StyledH6>
                <StyledH6>
                  <CloudUpload style={{ fontSize: "25px" }} />
                </StyledH6>
              </StyledBox>
            )}
          </ControlledFileInput>
        )}
        <StyledCardInside>
          <Grid container>
            <StyledGrid1 item xs={12} md={12}>
              <StyledH2>{data.NM_SEGURADORA}</StyledH2>
            </StyledGrid1>
            <StyledGrid item xs={12} md={12}>
              <StyledH3>Plano da Seguradora</StyledH3>
            </StyledGrid>
            <StyledGrid item xs={12} md={12}>
              <StyledH4>Rede Credenciada</StyledH4>
            </StyledGrid>
            <StyledGrid item xs={4} md={4}>
              <StyledSVGDiv>
                <StyledCadeadoIMG src={Cadeado} alt="Cadeado" />
                <StyledH5>Compulsória</StyledH5>
              </StyledSVGDiv>
            </StyledGrid>
            <StyledGridPNG item xs={4} md={4}>
              <StyledSVGDiv>
                <StyledCamaIMG src={Cama} alt="Cama hospital" />
                <StyledH5>Apartamento</StyledH5>
              </StyledSVGDiv>
            </StyledGridPNG>
            <StyledGridPNG item xs={4} md={4}>
              <StyledSVGDiv>
                <StyledHandsIMG src={Hands} alt="Mapa do Brasil" />
                <StyledH5>Com coparticipação</StyledH5>
              </StyledSVGDiv>
            </StyledGridPNG>
          </Grid>
          <StyledGrid item xs={12} md={12}>
            <StyledMonyLabel>{formatCoin(999.99)}</StyledMonyLabel>
          </StyledGrid>
        </StyledCardInside>
      </StyledCard>
    </StyledGrid>
  );
};

export default LogoCard;
