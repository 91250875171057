import React, { useState } from "react";
import HospitalPage from "./HospitalPage.connected";
import CotacaoInicialPage from "./CotacaoInicialPage";
import DadosCadastraisPage from "./DadosCadastraisPage";
import EnvioDaPropostaPage from "./EnvioDaPropostaPage";
import PropostaEnviadaPage from "./PropostaEnviadaPage";

const Homepage = () => {
  const [step, setStep] = useState(0);

  switch (step) {
    case 0:
      return <HospitalPage setStep={setStep} />;
    case 1:
      return <CotacaoInicialPage setStep={setStep} />;
    case 2:
      return <DadosCadastraisPage setStep={setStep} />;
    case 3:
      return <EnvioDaPropostaPage setStep={setStep} />;
    case 4:
      return <PropostaEnviadaPage setStep={setStep} />;
    default:
      return <HospitalPage setStep={setStep} />;
  }
};

export default Homepage;
