import React from "react";
import { Collapse, List, ListItem, ListItemText, Divider } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const StyledListItemText = styled(ListItemText)`
  .MuiTypography-root {
    font-family: ${({ theme }) => theme.fonts.family.title};
    color: #324873;
    font-size: 18px !important;
  }
`;

const DrawerMenu = ({ menu, level }) => {
  const [open, setOpen] = React.useState(false);
  const onClick = React.useCallback(() => {
    if (!!menu.menus) {
      setOpen(!open);
    } else {
      menu.action(menu.route);
    }
  }, [menu, open, setOpen]);

  return (
    <>
      <ListItem button onClick={onClick}>
        <StyledListItemText primary={menu.label} />
        {!!menu.menus ? !open ? <ExpandMore /> : <ExpandLess /> : null}
      </ListItem>
      {!!menu.menus && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Divider />
          <List component="div" disablePadding>
            {menu.menus.map((subMenu) => {
              return (
                <DrawerMenu key={subMenu.id} menu={subMenu} level={level + 1} />
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
};

export default DrawerMenu;
