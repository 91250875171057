import React from "react";
import { Card } from "../../components";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import Cadeado from "../../assets/Ei-lock.svg.png";
import Hands from "../../assets/hands.png";
import { formatCoin } from "../../utils/formatCoin";
import PlanoLogo from "./PlanoLogo";
import Cama from "../../assets/cama-hospital.png";

const StyledH2 = styled("h2")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 28px;
  margin: auto;
  margin-left: 5px;
  margin-right: 5px;
  letter-spacing: 1.5px;
`;
const StyledH3 = styled("h3")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: #ffffff;
  font-size: 22px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH4 = styled("h4")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: #ffffff;
  font-size: 14px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH5 = styled("h5")`
  font-family: ${({ theme }) => theme.fonts.family.default};
  color: #ffffff;
  font-size: 10px;
  margin: 0px;
  margin-top: -5px;
  text-align: center;
`;

const StyledH6 = styled("h6")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 18px;
  margin: 0px;
  letter-spacing: 1.5px;
`;
const StyledH6Bottom = styled("h6")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 18px;
  margin: 0px;
  margin-bottom: 20px;
  letter-spacing: 1.5px;
`;

const StyledMonyLabel = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 38px;
  margin-top: -10px;
  margin-bottom: -10px;
  letter-spacing: 1.5px;
`;

const StyledLogoDiv = styled("div")`
  color: #ffffff;
  align-items: center;
  justify-content: center;
  min-height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCadeadoIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: invert(100%);
`;
const StyledHandsIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
`;
const StyledCamaIMG = styled("img")`
  width: 30px;
  height: 30px;
  margin-bottom: 5px;
  filter: brightness(0) invert(1);
`;
const StyledSVGDiv = styled("div")`
  padding: 15px;
  align-items: center;
`;

const StyledCard = styled(Card)`
  min-height: 100%;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const StyledCardInside = styled(Card)`
  flex: 1;
  padding: 0px;
  position: bottom;
  bottom: 0;
  width: 100%;
  background-color: #849dce;
`;

const StyledGrid = styled(Grid)`
  min-height: 100%;
  max-width: 300px !important;
  align-items: center;
`;

const StyledGridPNG = styled(Grid)``;
const StyledGrid1 = styled(Grid)`
  min-height: 123px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PlanoCard = ({ data }) => {
  return (
    <StyledGrid item xs={12} md={4}>
      <StyledCard>
        <StyledLogoDiv>
          <PlanoLogo
            url={data.DS_CAMINHO_LOGO_CARD}
            seguradora={data.nome_seguradora}
          />
        </StyledLogoDiv>

        <StyledCardInside>
          <Grid container>
            <StyledGrid1 item xs={12} md={12}>
              <StyledH2>{data.nome_seguradora}</StyledH2>
            </StyledGrid1>
            <StyledGrid item xs={12} md={12}>
              <StyledH3>{data.nome_plano}</StyledH3>
            </StyledGrid>
            <StyledGrid item xs={12} md={12}>
              <StyledH4>{data.nome_rede_credenciada}</StyledH4>
            </StyledGrid>
            <StyledGrid item xs={4} md={4}>
              <StyledSVGDiv>
                <StyledCadeadoIMG src={Cadeado} alt="Cadeado" />
                <StyledH5>
                  {data.tipo_adesao === "C" ? "Compulsória" : "Livre"}
                </StyledH5>
              </StyledSVGDiv>
            </StyledGrid>
            <StyledGridPNG item xs={4} md={4}>
              <StyledSVGDiv>
                <StyledCamaIMG src={Cama} alt="Cama hospital" />
                <StyledH5>
                  {data.tipo_acomodacao === "A" ? "Apartamento" : "Enfermaria"}
                </StyledH5>
              </StyledSVGDiv>
            </StyledGridPNG>
            <StyledGridPNG item xs={4} md={4}>
              <StyledSVGDiv>
                <StyledHandsIMG src={Hands} alt="Mapa do Brasil" />
                <StyledH5>
                  {data.tipo_coparticipacao === "C"
                    ? data.porcentagem_coparticipacao
                      ? `${data.porcentagem_coparticipacao}% de`
                      : "Com"
                    : "Sem"}{" "}
                  coparticipação{" "}
                </StyledH5>
              </StyledSVGDiv>
            </StyledGridPNG>
          </Grid>
          <StyledGrid item xs={12} md={12}>
            <StyledH6>A partir de</StyledH6>
            <StyledMonyLabel>{formatCoin(data.custo_total)}*</StyledMonyLabel>
            <StyledH6Bottom>por vida</StyledH6Bottom>
          </StyledGrid>
        </StyledCardInside>
      </StyledCard>
    </StyledGrid>
  );
};

export default PlanoCard;
