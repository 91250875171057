import { createSelector } from "reselect";

export const getSeguradoras = (state) => state.seguradora;

export const getSeguradora = createSelector(
  getSeguradoras,
  (seguradora) => seguradora.seguradoras
);

export const showSeguradora = createSelector(
  getSeguradoras,
  (seguradora) => seguradora.seguradora
);

export const getSeguradoraProcessing = createSelector(
  getSeguradoras,
  (seguradora) => seguradora.processing
);
