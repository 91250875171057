import React from "react";
import {
  AppBar,
  Button,
  Divider,
  Drawer,
  IconButton,
  List,
  Menu,
  Toolbar,
} from "@mui/material";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import { ReactComponent as Logo } from "../../assets/Pick-curvas.svg";
import { PrimaryButton } from "../../components";
import { AccountCircle, Menu as MenuIcon } from "@mui/icons-material";
import { MenuItem } from "../../components";
import DrawerMenu from "./DrawerMenu";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { NestedMenuItem } from "mui-nested-menu";

const StyledAppBar = styled(AppBar)`
  background: transparent linear-gradient(93deg, #324873 0%, #19243a 100%) 0% 0%
    no-repeat padding-box;
  height: 70px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 10px 20px #00000029;
  justify-content: center;
`;

const StyledContainer = styled("div")`
  display: flex;
  font-family: "Patua One", cursive;
  font-size: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const StyledDiv = styled("div")`
  margin-top: 10px;
  display: inline-block;
`;

const StyledLogo = styled(Logo)`
  margin-left: -35px;
  width: 55px;
  height: 55px;

  @media (max-width: 900px) {
    margin-left: -20px;
  }
`;

const StyledButton = styled(PrimaryButton)`
  width: 100px;
  margin-top: -15px;
  margin-left: 25px;
  margin-right: -25px;

  @media (max-width: 900px) {
    width: 95px;

    margin-left: -20px;
    margin-right: -20px;
  }
`;

const StyledAccountCircle = styled(AccountCircle)`
  width: 30px;
  height: 30px;
  margin-right: 5px;
`;
const StyledButton2 = styled(Button)`
  margin: 10px;
  color: #ffffff !important;
  font-family: ${({ theme }) => theme.fonts.family.title};
`;
const StyledButton3 = styled(Button)`
  margin: 10px;
  margin-left: 0px;
  color: #ffffff !important;
  font-family: ${({ theme }) => theme.fonts.family.title};
`;

const StyledDivLarge = styled("div")`
  display: inline-block;
  margin-right: -30px;
  @media (max-width: 900px) {
    display: none;
  }
`;
const StyledDivSmall = styled("div")`
  display: inline-block;
  @media (min-width: 901px) {
    display: none;
  }
`;
const StyledUserContainer = styled("div")`
  padding: 10px;
  margin-top: 20px;
  margin-left: 4px;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: black;
  font-size: 24px;
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: #ffffff;
  height: 35px;
`;

const StyledNestedMenuItem = styled(NestedMenuItem)`
  .MuiTypography-root {
    font-family: ${({ theme }) => theme.fonts.family.title};
    color: #324873;
    font-size: 15px !important;
  }
`;
const StyledMenuItem = styled(MenuItem)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #324873;
  font-size: 15px !important;
`;

const SubMenus = React.forwardRef(
  ({ menus, handleClose, parentMenuOpen }, ref) => {
    return menus.map((item) => {
      if (!!item.menus) {
        return (
          <StyledNestedMenuItem
            ref={ref}
            key={item.id}
            label={item.label}
            parentMenuOpen={parentMenuOpen}
            onClick={handleClose}
          >
            <SubMenus
              menus={item.menus}
              parentMenuOpen={parentMenuOpen}
              handleClose={handleClose}
            />
          </StyledNestedMenuItem>
        );
      } else {
        const onClick = !!item.action
          ? () => item.action(item.route)
          : handleClose;
        return (
          <StyledMenuItem key={item.id} onClick={onClick}>
            {item.label}
          </StyledMenuItem>
        );
      }
    });
  }
);

const Menus = ({ menu, anchorEl, handleClose }) => {
  if (!menu.menus) return null;

  return (
    <Menu
      open={!!anchorEl[menu.id]}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={anchorEl[menu.id]}
    >
      <SubMenus
        menus={menu.menus}
        handleClose={handleClose}
        parentMenuOpen={!!anchorEl[menu.id]}
      />
    </Menu>
  );
};

const Header = ({
  isCotacao,
  menus,
  isLoggedIn,
  username,
  handleClickLogout,
}) => {
  const [anchorEl, setAnchorEl] = React.useState({});
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleMenu = ({ event, id }) => {
    setAnchorEl({
      [id]: {
        top: event.clientY,
        left: event.clientX,
      },
    });
  };

  const handleClose = (id) => {
    setAnchorEl({
      [id]: null,
    });
  };

  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(routeActions.redirectTo(routes.LOGIN));
  };

  return (
    <StyledAppBar>
      <Container>
        <Toolbar>
          <StyledContainer>
            <StyledDiv>
              <StyledLogo />
            </StyledDiv>
            {isLoggedIn && (
              <StyledDivLarge>
                {menus.map((menu) => {
                  let onClick = null;
                  if (!!menu.action) {
                    onClick = () => {
                      menu.action(menu.route);
                    };
                  } else if (!!menu.menus) {
                    onClick = (event) => {
                      handleMenu({ event, id: menu.id });
                    };
                  }

                  return (
                    <span key={menu.id}>
                      <StyledButton2 onClick={onClick}>
                        {menu.label}
                      </StyledButton2>
                      <Menus
                        anchorEl={anchorEl}
                        handleClose={handleClose}
                        menu={menu}
                      />
                    </span>
                  );
                })}
                <StyledButton3
                  aria-haspopup="true"
                  onClick={(event) => handleMenu({ event, id: "user" })}
                  color="inherit"
                >
                  <StyledAccountCircle />
                  {username}
                </StyledButton3>
                <Menu
                  open={!!anchorEl["user"]}
                  onClose={() => handleClose("user")}
                  anchorReference="anchorPosition"
                  anchorPosition={anchorEl["user"]}
                >
                  <StyledMenuItem onClick={handleClickLogout}>
                    SAIR
                  </StyledMenuItem>
                </Menu>
              </StyledDivLarge>
            )}

            {isLoggedIn && (
              <StyledDivSmall>
                <IconButton onClick={() => setDrawerOpen(true)}>
                  <StyledMenuIcon />
                </IconButton>
                <Drawer
                  anchor={"left"}
                  open={drawerOpen}
                  onClose={() => setDrawerOpen(false)}
                >
                  <StyledUserContainer>{username}</StyledUserContainer>
                  <Divider />
                  <List component="div">
                    {menus.map((menu) => {
                      return <DrawerMenu level={0} key={menu.id} menu={menu} />;
                    })}
                    <DrawerMenu
                      menu={{
                        id: "logout",
                        label: "Sair",
                        action: handleClickLogout,
                      }}
                    />
                  </List>
                </Drawer>
              </StyledDivSmall>
            )}

            {isCotacao && (
              <StyledDiv>
                <StyledButton onClick={handleClick}>Acessar</StyledButton>
              </StyledDiv>
            )}
          </StyledContainer>
        </Toolbar>
      </Container>
    </StyledAppBar>
  );
};

export default Header;
