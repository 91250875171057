import BasePage from "../BasePage";
import React, { useState, useEffect } from "react";
import { Card, PrimaryButton } from "../../components";
import {
  ControlledTextField,
  CpfCnpjTextField,
  PhoneTextField,
  PositiveIntegerTextField,
} from "../../components/inputs";
import { styled } from "@mui/material";
import { Grid } from "@mui/material";
import VerticalLinearStepper from "./StepperPage";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import {
  getCotacaoDados,
  getCotacaoInicial,
} from "../../selectors/cotacao.selectors";
import { actions } from "../../reducers/cotacao.actions";
import Spinner from "../../components/spinner/Spinner";
import { toast } from "react-toastify";
import { removeNonDigitsFromString } from "../../utils/basic";
import formatNumber from "../../utils/formatNumber";
import validators from "../../utils/validators";

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 4.8px;
  font-size: 48px;
  margin-bottom: 40px;
  @media (max-width: 900px) {
    font-size: 22px;
    margin-bottom: 10px;
  }
`;

const StyledH2 = styled("h2")`
  margin-top: -10px;
  font-family: ${({ theme }) => theme.fonts.family.title};
  letter-spacing: 1.8px;
  text-align: left;
  margin-bottom: 10px;

  color: #ffffff;
  font-size: 18px;
  @media (max-width: 900px) {
    font-size: 15px;
    margin-bottom: 10px;
  }
`;

const StyledGrid = styled(Grid)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledCard = styled(Card)`
  height: auto;
  padding: 25px;
  margin-bottom: 25px;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledCardLightBlue = styled(Card)`
  height: auto;
  background-color: #849dce;
  padding: 25px;
  margin-bottom: 20px;

  @media (max-width: 900px) {
    height: auto;
  }

  & .MuiGrid-container {
    align-items: center;
    height: auto;
  }
`;

const StyledButton = styled(PrimaryButton)`
  margin-top: -75px;
  @media (max-width: 900px) {
    margin-top: -25px;
  }
`;

const StyledFormGrid = styled(Grid)`
  place-self: flex-start;
`;
const StyledFormContainer = styled(Grid)``;

const StyledControlledTextField = styled(ControlledTextField)`

margin-bottom:1px;
margin-top:1px;

flex: 1!important;

@media (max-width: 900px) {
  margin-bottom:5px;
  margin-top:5px;
}


  & .MuiInputLabel-root {
    background-color: #415781ff;
    color: #ffffff !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  
  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    },
    &:hover fieldset {
      border-color: #ffffff;
    },
    &.Mui-focused fieldset {
      border: 3px solid #ffffff;
    },
    &.Mui-error fieldset {
    border: 2.5px solid #f0e500ff;
    },`;

const StyledGridVidas = styled(Grid)`
  margin-top: 2px;
`;

const StyledControlledTextFieldLightBlue = styled(ControlledTextField)`


  & .MuiInputLabel-root {
    background-color: #849dce;
    color: #ffffff !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff!important;
    margin-bottom: -10px

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    },
    &:hover fieldset {
      border-color: #ffffff;
    },
    &.Mui-focused fieldset {
      border: 3px solid #ffffff;
    },
    &.Mui-error fieldset {
      border: 2.5px solid #f0e500ff;
    },`;

const DadosCadastraisPage = ({ setStep }) => {
  const dispatch = useDispatch();
  const { loading, data } = useSelector(getCotacaoInicial);
  const dataCotacao = useSelector(getCotacaoDados).data;

  const [total, setTotal] = useState(0);

  const planos = {
    ID_REDE_CREDENCIADA: data[0]?.id_rede_credenciada ?? "",
    ID_SEGURADORA_PLANO_VALOR_OPCAO1_INICIAL:
      data[0]?.id_seguradora_plano_valor ?? "",
    ID_SEGURADORA_PLANO_VALOR_OPCAO2_INICIAL:
      data[1]?.id_seguradora_plano_valor ?? "",
    ID_SEGURADORA_PLANO_VALOR_OPCAO3_INICIAL:
      data[2]?.id_seguradora_plano_valor ?? "",
    DT_COTACAO: new Date(),
  };

  const rules = {
    NM_EMPRESA: validators.string({ required: true }),
    NR_CNPJ: validators.cnpj({ required: true }),
    NM_CONTATO: validators.string({ required: true }),
    NR_TELEFONE: validators.phone({ required: true }),
    CD_EMAIL_CONTATO: validators.email({ required: true }),
  };

  const initialValues = {
    NM_EMPRESA: dataCotacao?.NM_EMPRESA ? dataCotacao.NM_EMPRESA : "",
    NR_CNPJ: dataCotacao?.NR_CNPJ ?? "",
    NM_CONTATO: dataCotacao?.NM_CONTATO ?? "",
    NR_TELEFONE:
      (dataCotacao?.NR_AREA_CONTATO ?? "") +
      (dataCotacao?.NR_TELEFONE_CONTATO ?? ""),
    CD_EMAIL_CONTATO: dataCotacao?.CD_EMAIL_CONTATO ?? "",
    QT_VIDAS_00_18: dataCotacao?.QT_VIDAS_00_18 ?? 0,
    QT_VIDAS_19_23: dataCotacao?.QT_VIDAS_19_23 ?? 0,
    QT_VIDAS_24_28: dataCotacao?.QT_VIDAS_24_28 ?? 0,
    QT_VIDAS_29_33: dataCotacao?.QT_VIDAS_29_33 ?? 0,
    QT_VIDAS_34_38: dataCotacao?.QT_VIDAS_34_38 ?? 0,
    QT_VIDAS_39_43: dataCotacao?.QT_VIDAS_39_43 ?? 0,
    QT_VIDAS_44_48: dataCotacao?.QT_VIDAS_44_48 ?? 0,
    QT_VIDAS_49_53: dataCotacao?.QT_VIDAS_49_53 ?? 0,
    QT_VIDAS_54_58: dataCotacao?.QT_VIDAS_54_58 ?? 0,
    QT_VIDAS_59_99: dataCotacao?.QT_VIDAS_59_99 ?? 0,
  };

  const formProps = {
    ...useForm({
      defaultValues: initialValues,
    }),
    rules,
    initialValues,
  };

  const handleSubmit = (values) => {
    if (total === 0) {
      return toast.error("Não é possível gerar uma cotação com 0 vidas", {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#990a26" },
      });
    }
    const cnpj = formProps.getValues("NR_CNPJ");
    const telefone = formProps.getValues("NR_TELEFONE");

    values.NR_CNPJ = removeNonDigitsFromString(cnpj);

    const telefoneNumeros = removeNonDigitsFromString(telefone);

    values.NR_AREA_CONTATO = telefoneNumeros.substring(0, 2);

    values.NR_TELEFONE_CONTATO = telefoneNumeros.substring(
      2,
      telefoneNumeros.length
    );

    values.CD_EMAIL_CONTATO = values.CD_EMAIL_CONTATO.toLowerCase();

    dispatch(actions.criarCotacao.request({ ...values, ...planos }));
    dispatch(actions.obterCotacaoFinal.request({ ...values, ...planos }));
    setStep(3);
  };

  const handleChange = () => {
    setTotal(
      +(formProps.getValues("QT_VIDAS_00_18")
        ? Number(formProps.getValues("QT_VIDAS_00_18"))
        : 0) +
        (formProps.getValues("QT_VIDAS_19_23")
          ? Number(formProps.getValues("QT_VIDAS_19_23"))
          : 0) +
        (formProps.getValues("QT_VIDAS_24_28")
          ? Number(formProps.getValues("QT_VIDAS_24_28"))
          : 0) +
        (formProps.getValues("QT_VIDAS_29_33")
          ? Number(formProps.getValues("QT_VIDAS_29_33"))
          : 0) +
        (formProps.getValues("QT_VIDAS_34_38")
          ? Number(formProps.getValues("QT_VIDAS_34_38"))
          : 0) +
        (formProps.getValues("QT_VIDAS_39_43")
          ? Number(formProps.getValues("QT_VIDAS_39_43"))
          : 0) +
        (formProps.getValues("QT_VIDAS_44_48")
          ? Number(formProps.getValues("QT_VIDAS_44_48"))
          : 0) +
        (formProps.getValues("QT_VIDAS_49_53")
          ? Number(formProps.getValues("QT_VIDAS_49_53"))
          : 0) +
        (formProps.getValues("QT_VIDAS_54_58")
          ? Number(formProps.getValues("QT_VIDAS_54_58"))
          : 0) +
        (formProps.getValues("QT_VIDAS_59_99")
          ? Number(formProps.getValues("QT_VIDAS_59_99"))
          : 0)
    );
  };

  useEffect(() => {
    if (dataCotacao) {
      setTotal(
        Number(dataCotacao?.QT_VIDAS_00_18 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_19_23 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_24_28 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_29_33 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_34_38 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_39_43 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_44_48 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_49_53 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_54_58 ?? 0) +
          Number(dataCotacao?.QT_VIDAS_59_99 ?? 0)
      );
    }
  }, [dataCotacao]);

  if (loading) {
    return <Spinner />;
  }

  return (
    <form onSubmit={formProps.handleSubmit(handleSubmit)}>
      <BasePage>
        <Grid container>
          <StyledGrid item xs={12} md={2}>
            <VerticalLinearStepper setStep={setStep} activeStep={2} />
          </StyledGrid>
          <StyledGrid item xs={12} md={10}>
            <StyledH1>Informe seus dados</StyledH1>
            <StyledCard>
              <StyledFormContainer container spacing={2}>
                <Grid item xs={12} md={12}>
                  <StyledH2>Informe os dados de sua empresa</StyledH2>
                </Grid>
                <StyledFormGrid item xs={12} md={3}>
                  <StyledControlledTextField
                    formProps={formProps}
                    fullWidth
                    name="NR_CNPJ"
                    label="CNPJ"
                    type="cnpj"
                    InputProps={{
                      inputComponent: CpfCnpjTextField,
                    }}
                  />
                </StyledFormGrid>
                <StyledFormGrid item xs={12} md={9}>
                  <StyledControlledTextField
                    formProps={formProps}
                    fullWidth
                    name="NM_EMPRESA"
                    label="Razão Social"
                    inputProps={{ maxLength: 100 }}
                  />
                </StyledFormGrid>
                <StyledFormGrid item xs={12} md={3}>
                  <StyledControlledTextField
                    formProps={formProps}
                    fullWidth
                    name="NM_CONTATO"
                    label="Nome"
                    inputProps={{ maxLength: 100 }}
                  />
                </StyledFormGrid>

                <StyledFormGrid item xs={12} md={3}>
                  <StyledControlledTextField
                    formProps={formProps}
                    fullWidth
                    name="NR_TELEFONE"
                    label="Telefone"
                    InputProps={{
                      inputComponent: PhoneTextField,
                    }}
                  />
                </StyledFormGrid>
                <StyledFormGrid item xs={12} md={6}>
                  <StyledControlledTextField
                    formProps={formProps}
                    fullWidth
                    name="CD_EMAIL_CONTATO"
                    label="E-mail"
                    inputProps={{
                      style: { textTransform: "lowercase" },
                      maxLength: 100,
                    }}
                  />
                </StyledFormGrid>
              </StyledFormContainer>
            </StyledCard>
            <StyledCardLightBlue>
              <Grid container spacing={2}>
                <Grid item xs={12} md={10}>
                  <StyledH2>
                    Indique a quantidade de vidas de acordo com as faixas de
                    idade
                  </StyledH2>
                </Grid>
                <Grid item xs={12} md={2}>
                  <StyledH2>Total: {formatNumber(total)}</StyledH2>
                </Grid>
              </Grid>
              <StyledGridVidas
                container
                spacing={2}
                columns={{ xs: 12, md: 10 }}
              >
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    fullWidth
                    name="QT_VIDAS_00_18"
                    label="0-18"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_19_23"
                    label="19-23"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    inputRef={formProps}
                    fullWidth
                    name="QT_VIDAS_24_28"
                    label="24-28"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_29_33"
                    label="29-33"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_34_38"
                    label="34-38"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_39_43"
                    label="39-43"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_44_48"
                    label="44-48"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_49_53"
                    label="49-53"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_54_58"
                    label="54-58"
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={4} md={1}>
                  <StyledControlledTextFieldLightBlue
                    formProps={formProps}
                    InputProps={{
                      inputComponent: PositiveIntegerTextField,
                    }}
                    fullWidth
                    name="QT_VIDAS_59_99"
                    label="59+"
                    onChange={handleChange}
                  />
                </Grid>
              </StyledGridVidas>
            </StyledCardLightBlue>
          </StyledGrid>
          <StyledGrid item md={9} />
          <StyledGrid item xs={12} md={3}>
            <StyledButton type={"submit"}>VISUALIZAR PLANOS</StyledButton>
          </StyledGrid>
        </Grid>
      </BasePage>
    </form>
  );
};

export default DadosCadastraisPage;
