import { actions } from "../reducers/carga.actions";
import { authenticatedRequest } from "../utils/api";
import asyncFlow from "./asyncHandler";
import { toast } from "react-toastify";
import store from "../store";
import { types as routes } from "../reducers/rotas.actions";
import { routeWatcher } from "./rotas.saga";
import { put } from "redux-saga/effects";
import { saveAs } from "file-saver";

const obterPlanilhaApi = (value) => {
  return authenticatedRequest({
    url: `/carga/artefato/mostrar`,
    isResourceService: true,
    method: "post",
    responseType: "blob",
    body: {
      CD_ARTEFATO: value.CD_ARTEFATO,
    },
  });
};

const obterPlanilha = asyncFlow({
  actionGenerator: actions.download_planilha,
  api: obterPlanilhaApi,
  postSuccess: ({ response, original, values }) => {
    downloadFile(response, original, values);
  },
});

const downloadFile = (response, original, values) => {
  const blob = new Blob([response.data], {
    type: "application/xlsx",
  });
  saveAs(blob, `${values.NM_ARQUIVO}`);
};

const listarHistoricoCargaApi = (values) => {
  return authenticatedRequest({
    url: "/carga/historico/listar",
    method: "post",
    body: {
      pagina: values?.pagina ?? 1,
      limite: 10,
    },
  });
};

const listarHistoricoCarga = asyncFlow({
  actionGenerator: actions.listar_historico,
  api: listarHistoricoCargaApi,
});

function* listarHospitaisRouteWatcher() {
  yield routeWatcher(routes.HISTORICO_CARGA, function* () {
    yield put(actions.listar_historico.request());
  });
}

const cargaSeguradora = asyncFlow({
  actionGenerator: actions.carga_seguradoras,
  api: (values) => {
    const formData = new FormData();
    formData.append("file", values[0]);

    return authenticatedRequest({
      url: "/carga/seguradoras",
      method: "post",
      body: formData,
      headers: { "Content-Type": "multipart/form-data" },
      onDownloadProgress: (progressEvent) => {
        const progress = progressEvent.srcElement.responseText.slice(
          progressEvent.srcElement.responseText.lastIndexOf("@") + 1
        );
        store.dispatch(actions.carga_update_label(progress));
      },
    });
  },
  postSuccess: ({ values }) => {
    const formData = new FormData();
    formData.append("file", values[0]);

    authenticatedRequest({
      url: "/carga/historico",
      method: "post",
      body: formData,
    }).then(
      toast.success(`Arquivo "${values[0].name}" processado com sucesso`, {
        theme: "colored",
        icon: false,
        style: { backgroundColor: "#203d8b" },
      })
    );
  },
});

export const sagas = [
  cargaSeguradora.watcher(),
  listarHistoricoCarga.watcher(),
  obterPlanilha.watcher(),
  listarHospitaisRouteWatcher(),
];
