import React, { useEffect, useState } from "react";
import { Card, InputAdornment, Skeleton, styled } from "@mui/material";
import { Grid } from "@mui/material";
import { AttachMoneyOutlined, Event, ListAlt } from "@mui/icons-material";
import DatePicker from "../../components/inputs/DatePicker";
import { useDispatch } from "react-redux";
import { actions } from "../../reducers/dashboard.actions";

const StyledH1 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 2px;
  font-size: 24px;
  margin: 25px;
  margin-top: -20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 20px;
  }
`;
const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: 300px;
  width: 100%;
  margin: auto;
  margin-top: 20px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  cursor: pointer;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
  }
`;

const StyledSVGDiv = styled("div")`
  margin-top: -15px;
  text-align: center;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 500px;
  margin-top: -90px;
  margin-bottom: -30px;
`;
const StyledDatePicker = styled(DatePicker)`
  width: 140px;
  .MuiInputBase-root {
    font-family: ${({ theme }) => theme.fonts.family.title};
    color: ${({ theme }) => theme.colors.primary};
    margin: auto;
    margin-top: -35px;
    margin-bottom: 10px;
    font-size: 24px;
  }
`;

const DashboardCardDia = ({
  titulo,
  refresh,
  data,
  periodo,
  handleChangePeriodo,
  setFiltroPeriodo,
  loading,
  setLoadingOpen,
  setValuePeriodo,
  setDisplayCotacoes,
  loadingOpen,
}) => {
  const [value, setValue] = useState(new Date());
  const dispatch = useDispatch();

  useEffect(() => {
    setValue(new Date());
  }, [refresh]);

  var month = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ][value.getMonth()];

  useEffect(() => {
    setValuePeriodo(value);
    handleChangePeriodo(`${month} de ${value.getFullYear()}`, "mês", value);
    dispatch(actions.obterPorcentagemMes.request({ dia: value }));

    // eslint-disable-next-line
  }, [value]);

  const handleClick = () => {
    setDisplayCotacoes(true);
    setFiltroPeriodo("mês");
    handleChangePeriodo(`${month} de ${value.getFullYear()}`, "mês", value);
  };

  if (loading) {
    return <StyledSkeleton />;
  }

  return (
    <StyledCard onClick={handleClick}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <StyledH2>{titulo}</StyledH2>
        </Grid>
        <Grid
          onClick={(e) => e.stopPropagation()}
          item
          align={"center"}
          xs={12}
          sm={12}
          md={12}
        >
          <StyledDatePicker
            disableFuture
            value={value}
            onChange={(newValue) => {
              setDisplayCotacoes(true);

              new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0) >
              new Date()
                ? setValue(new Date())
                : setValue(
                    new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0)
                  );
            }}
            fullWidth={false}
            views={["month", "year"]}
            format="MMM/yyyy"
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <Event style={{ color: "#849dce", fontSize: "30px" }} />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledSVGDiv>
            <AttachMoneyOutlined
              style={{
                fontSize: 56,
                color: "#849dce",
              }}
            />
          </StyledSVGDiv>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledSVGDiv>
            <ListAlt style={{ fontSize: 56, color: "#849dce" }} />
          </StyledSVGDiv>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledSVGDiv>
            <StyledH1>
              {" "}
              {data.valor > 0
                ? data.valor / 1000000 > 1
                  ? Math.round(data.valor / 1000000) + "M"
                  : Math.round(data.valor / 1000) + "K"
                : 0}
            </StyledH1>
          </StyledSVGDiv>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledSVGDiv>
            <StyledH1>{data.cotacoes}</StyledH1>
          </StyledSVGDiv>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledSVGDiv>
            <StyledH1
              style={{
                color: `${
                  !!data.porcentagemValor && data.porcentagemValor !== 0
                    ? data.porcentagemValor > 0
                      ? "green"
                      : "red"
                    : "black"
                }`,
              }}
            >
              {!!data.porcentagemValor && data.porcentagemValor !== 0
                ? data.porcentagemValor > 0
                  ? `+${Math.round(data.porcentagemValor)}%`
                  : `${Math.round(data.porcentagemValor)}%`
                : "-"}
            </StyledH1>
          </StyledSVGDiv>
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <StyledSVGDiv>
            <StyledH1
              style={{
                color: `${
                  !!data.porcentagem && data.porcentagem !== 0
                    ? data.porcentagem > 0
                      ? "green"
                      : "red"
                    : "black"
                }`,
              }}
            >
              {!!data.porcentagem && data.porcentagem !== 0
                ? data.porcentagem > 0
                  ? `+${Math.round(data.porcentagem)}%`
                  : `${Math.round(data.porcentagem)}%`
                : "-"}
            </StyledH1>
          </StyledSVGDiv>
        </Grid>
      </Grid>
    </StyledCard>
  );
};

export default DashboardCardDia;
