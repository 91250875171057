import { createSelector } from "reselect";

const getAdministracao = (state) => state.administracao;

export const getCotacoes = createSelector(
  getAdministracao,
  (state) => state.cotacoes
);
export const getDetalhesCotacao = createSelector(
  getAdministracao,
  (state) => state.detalhesCotacao
);
export const getReload = createSelector(
  getAdministracao,
  (state) => state.reload
);
export const getIntegrar = createSelector(
  getAdministracao,
  (state) => state.integrar
);
export const getIntegrarManualmente = createSelector(
  getAdministracao,
  (state) => state.integrarManualmente
);
