import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { styled } from "@mui/material";

const steps = [
  {
    label: "Hospital",
    description: ``,
  },
  {
    label: "Cotação Inicial",
    description: "",
  },
  {
    label: "Dados Cadastrais",
    description: ``,
  },
  {
    label: "Envio da Proposta",
    description: ``,
  },
];

const StyledStepperVertical = styled(Stepper)`
  align-items: center;
  padding-top: 30px;

  & .MuiStepIcon-root.Mui-completed {
    color: #80c070ff;
  }

  & .MuiStepLabel-label.Mui-completed {
    color: #80c070ff;
  }

  & .MuiStepConnector-root.Mui-completed > span {
    border-color: #80c070ff !important;
    background-color: #80c070ff !important;
  }
  & .MuiStepConnector-root.Mui-active > span {
    border-color: #80c070ff !important;
    background-color: #80c070ff !important;
  }

  & .MuiStepIcon-root {
    color: #849dce;
    width: 40px;
    height: 40px;
  }

  & .MuiStepIcon-root.Mui-active {
    color: #324873;
  }

  & .MuiStepIcon-text {
    font-family: ${({ theme }) => theme.fonts.family.title};
  }

  & .MuiStepLabel-label.Mui-active {
    color: #324873;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: 18px;
    font-weight: 600;
  }

  .MuiStepLabel-label.Mui-disabled {
    color: #849dce;
    font-family: ${({ theme }) => theme.fonts.family.default};
    font-size: 16px;
  }

  & .MuiStepLabel-vertical {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  & .MuiStepConnector-line {
    border-left-width: 3px;
    border-right-width: 2px;
    border-right-style: solid;
    width: 5px;
    min-height: 75px;
    border-color: #849dce;
    background-color: #849dce;
    border-radius: 10px;
  }

  & .MuiStepConnector-root {
    margin-left: 0px;
  }

  & .MuiStepLabel-iconContainer {
    padding-right: 0px;
  }
`;

const StyledStepperHorizontal = styled(Stepper)`
  height: 100%;
  margin-bottom: -20px;

  & .MuiStepIcon-root {
    color: #849dce;
  }

  & .MuiStepIcon-root.Mui-active {
    color: #324873;
  }

  & .MuiStepIcon-root.Mui-completed {
    color: #80c070ff;
  }

  & .MuiStepConnector-root.Mui-completed > span {
    border-color: #80c070ff !important;
  }

  & .MuiStepConnector-root.Mui-active > span {
    border-color: #80c070ff !important;
  }

  & .MuiStepLabel-label.Mui-completed {
    color: #80c070ff;
    font-family: ${({ theme }) => theme.fonts.family.default};
  }

  & .MuiStepIcon-text {
    font-family: ${({ theme }) => theme.fonts.family.title};
  }

  & .MuiStepLabel-label.Mui-active {
    color: #324873;
    font-family: ${({ theme }) => theme.fonts.family.default};
  }

  .MuiStepLabel-label.Mui-disabled {
    color: #849dce;
    font-family: ${({ theme }) => theme.fonts.family.default};
  }

  & .MuiStepConnector-line {
    border-color: #849dce;
  }

  & .MuiStepConnector-root {
    margin-left: 0px;
  }

  & .MuiStepLabel-iconContainer {
    padding-right: 0px;
  }
`;

const StyledBoxHorizontal = styled(Box)`
  @media only screen and (min-width: 901px) {
    display: none;
  }
`;
const StyledBoxVertical = styled(Box)`
  @media only screen and (max-width: 900px) {
    display: none;
  }
`;
const StyledClickableDiv = styled("div")`
  cursor: pointer;

  &:hover {
    margin-top: 2px;
    margin-bottom: -2px;
    transform: scale(1.175);
  }
`;

export default function VerticalLinearStepper({ setStep, activeStep, isLast }) {
  const handleClick = (index) => {
    if (index < activeStep) setStep(index);
  };

  return (
    <>
      <StyledBoxVertical sx={{ maxWidth: 400 }}>
        <StyledStepperVertical activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              {isLast ? (
                <StepLabel>{step.label}</StepLabel>
              ) : (
                <StyledClickableDiv
                  key={index}
                  onClick={() => handleClick(index)}
                >
                  <StepLabel>{step.label}</StepLabel>
                </StyledClickableDiv>
              )}
            </Step>
          ))}
        </StyledStepperVertical>
      </StyledBoxVertical>
      <StyledBoxHorizontal sx={{ width: "100%" }}>
        <StyledStepperHorizontal activeStep={activeStep} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label}>
              {isLast ? (
                <StepLabel>{step.label}</StepLabel>
              ) : (
                <StyledClickableDiv
                  key={index}
                  onClick={() => handleClick(index)}
                >
                  <StepLabel>{step.label}</StepLabel>
                </StyledClickableDiv>
              )}
            </Step>
          ))}
        </StyledStepperHorizontal>
      </StyledBoxHorizontal>
    </>
  );
}
