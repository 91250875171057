import basicFlow from "./asyncHandler";
import { authenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { actions as seguradora } from "../reducers/seguradora.actions";
import { put, select } from "redux-saga/effects";

const ListarSeguradorasApi = (values) => {
  return authenticatedRequest({
    url: `/seguradora/listar`,
    isResourceService: true,
    method: "post",
    body: {
      pagina: values?.pagina ?? 1,
      limite: 10,
    },
  });
};

const ListarSeguradoras = basicFlow({
  actionGenerator: seguradora.listarSeguradoras,
  api: ListarSeguradorasApi,
});

const AtualizarCodigoIntegracaoApi = (values) => {
  return authenticatedRequest({
    url: `/seguradora/atualizar/codigo-integracao`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
      CD_INTEGRACAO: values.CD_INTEGRACAO,
    },
  });
};

const AtualizarCodigoIntegracao = basicFlow({
  actionGenerator: seguradora.atualizarCodigoIntegracao,
  api: AtualizarCodigoIntegracaoApi,
});

function* ListarSeguradorasRouteWatcher() {
  yield routeWatcher(routes.SEGURADORA, function* () {
    yield put(seguradora.listarSeguradoras.request());
  });
}

const MostrarSeguradoraApi = (values) => {
  return authenticatedRequest({
    url: `/seguradora/show`,
    isResourceService: true,
    method: "post",
    body: {
      id: values.id,
    },
  });
};

const MostrarSeguradora = basicFlow({
  actionGenerator: seguradora.mostrarSeguradora,
  transform: function* (payload) {
    const id = yield select((state) => state.seguradora.id);
    return { id, ...payload };
  },

  api: MostrarSeguradoraApi,
});

function* MostrarSeguradoraRouteWatcher() {
  yield routeWatcher(routes.EDITAR_SEGURADORA, function* () {
    yield put(seguradora.mostrarSeguradora.request());
  });
}

const EnviarAnexo = basicFlow({
  actionGenerator: seguradora.enviarAnexo,
  api: (values) => {
    const formData = new FormData();
    formData.append("file", values.fileSeguradoras[0]);
    formData.append("id", values.id);

    return authenticatedRequest({
      url: "/seguradora/atualizar/anexo",
      method: "post",
      body: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
});

const EnviarLogoCard = basicFlow({
  actionGenerator: seguradora.enviarLogoCard,
  api: (values) => {
    const formData = new FormData();
    formData.append("file", values.LogoCardSeguradoras[0]);
    formData.append("id", values.id);

    return authenticatedRequest({
      url: "/seguradora/atualizar/logo-card",
      method: "post",
      body: formData,
      headers: { "Content-Type": "multipart/form-data" },
    });
  },
});

export const sagas = [
  ListarSeguradorasRouteWatcher(),
  MostrarSeguradoraRouteWatcher(),
  ListarSeguradoras.watcher(),
  MostrarSeguradora.watcher(),
  EnviarAnexo.watcher(),
  EnviarLogoCard.watcher(),
  AtualizarCodigoIntegracao.watcher(),
];
