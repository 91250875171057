import { actions, types } from "./carga.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  processing: {
    seguradoras: false,
    label: "",
  },
  historico: {
    loading: false,
    data: [],
  },
  download: {
    loading: false,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listar_historico.REQUEST:
    case actions.listar_historico.SUCCESS:
    case actions.listar_historico.FAILURE:
      return {
        ...state,
        historico: {
          ...state.historico,
          loading: action.type === actions.listar_historico.REQUEST,
          data:
            action.type === actions.listar_historico.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.carga_seguradoras.REQUEST:
    case actions.carga_seguradoras.SUCCESS:
    case actions.carga_seguradoras.FAILURE:
      return {
        ...state,
        processing: {
          ...state.processing,
          seguradoras: action.type === actions.carga_seguradoras.REQUEST,
          label: "",
        },
      };
    case actions.download_planilha.REQUEST:
    case actions.download_planilha.SUCCESS:
    case actions.download_planilha.FAILURE:
      return {
        ...state,
        download: {
          ...state.download,
          loading: action.type === actions.download_planilha.REQUEST,
        },
      };
    case types.CARGA_UPDATE_LABEL:
      return {
        ...state,
        processing: {
          ...state.processing,
          label: action.payload,
        },
      };
    case routes.CARGA_SEGURADORA:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default reducer;
