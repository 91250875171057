import BasePage from "../BasePage";
import React, { useEffect, useState } from "react";
import { Grid, styled, Tabs, Box, Tab } from "@mui/material";
import DadosCard from "./DadosCard";
import DetalhesCard from "./DetalhesCard";
import Painel from "./Painel";
import IntegracaoCard from "./IntegracaoCard";
import Spinner from "../../components/spinner/Spinner";

const StyledPage = styled("div")`
  margin-top: 50px;
  @media (max-width: 900px) {
    margin: 15px;
  }
`;
const StyledContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledDiv = styled("div")`
  @media (max-width: 900px) {
    margin-bottom: 5px;
    margin-top: 5px;
  }
`;

const StyledH1 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 3px;
  font-size: 30px;
  margin: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 18px;
  }
`;

const StyledTab = styled(Tabs)`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};

  & .css-1aquho2-MuiTabs-indicator {
    background-color: ${({ theme }) => theme.colors.primary};
  }

  & .Mui-selected {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};
  }
`;

const StyledTabLabel = styled(Tab)`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
`;

const DetalhesCotacaoPage = ({ data, loading }) => {
  const [value, setValue] = useState(0);
  const [dataEnviado, setEnviado] = useState([]);
  const [dataBaixado, setBaixado] = useState([]);

  useEffect(() => {
    if (data.CotacaoResultados) {
      var enviado = data.CotacaoResultados.filter((resultado) => {
        return resultado.TP_ENVIO_PROPOSTA === "E";
      });
      var baixado = data.CotacaoResultados.filter((resultado) => {
        return resultado.TP_ENVIO_PROPOSTA === "D";
      });

      setEnviado(enviado);
      setBaixado(baixado);
    }
  }, [data]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return (
      <BasePage isLoggedIn={true}>
        <Spinner />
      </BasePage>
    );
  }

  return (
    <BasePage isLightBlue={true} isLoggedIn={true}>
      <StyledPage>
        <StyledContainer>
          <StyledDiv>
            <StyledH1>
              #{data.ID_COTACAO} -{" "}
              {new Date(data.DH_CRIACAO).toLocaleDateString("pt-BR")}
            </StyledH1>
          </StyledDiv>
          <StyledDiv>
            <StyledH1>{`${
              data.TP_SITUACAO_FLUXO === "F" ? "Não concluída" : "Enviada"
            }`}</StyledH1>
          </StyledDiv>
        </StyledContainer>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <StyledTab
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                color={({ theme }) => theme.colors.primary}
              >
                <StyledTabLabel label="Dados da cotação" />
                <StyledTabLabel label="Resultados da cotação" />
                <StyledTabLabel label="Integração" />
              </StyledTab>
            </Box>
            <Painel value={value} index={0}>
              <DadosCard dataEnviado={dataEnviado} data={data} />
            </Painel>
            <Painel value={value} index={1}>
              <DetalhesCard
                dataEnviado={dataEnviado}
                dataTotal={data}
                data={dataBaixado}
              />
            </Painel>
            <Painel value={value} index={2}>
              <IntegracaoCard
                loading={loading}
                dataEnviado={dataEnviado}
                data={data}
              />
            </Painel>
          </Grid>
        </Grid>
      </StyledPage>
    </BasePage>
  );
};

export default DetalhesCotacaoPage;
