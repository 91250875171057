import React from "react";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@mui/material";
import { createFilterOptions } from "@mui/core";
import TextField from "./TextField";

const filterOptions = createFilterOptions({
  ignoreAccents: true,
  ignoreCase: true,
  trim: true,
});

const ControlledAutocomplete = ({
  setRedeId,
  defaultValue,
  formProps,
  domain,
  labelField,
  secondLabelField,
  idField = "id",
  name,
  ignoreError = false,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
  } = formProps;
  const isError = errors[name] !== undefined && !ignoreError;

  const initial = domain.find(
    (item) => item.ID_REDE_CREDENCIADA === defaultValue
  );

  const initialDefault = {
    ID_REDE_CREDENCIADA: "",
    NM_REDE_CREDENCIADA: "",
    RegiaoMunicipio: {
      Municipio: {
        NM_MUNICIPIO: "",
      },
    },
  };

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      render={({
        field: { onChange, onBlur, isOptionEqualToValue, value },
      }) => (
        <Autocomplete
          defaultValue={initial ? initial : initialDefault}
          autoComplete={true}
          filterOptions={filterOptions}
          freeSolo={true}
          noOptionsText={"Nenhuma opção correspondente"}
          options={domain}
          value={isOptionEqualToValue}
          getOptionLabel={
            secondLabelField
              ? (option) =>
                  option[labelField] +
                  `${
                    option.RegiaoMunicipio.Municipio.NM_MUNICIPIO !== ""
                      ? ` (${option.RegiaoMunicipio.Municipio.NM_MUNICIPIO})`
                      : ""
                  }`
              : (option) => option[labelField] ?? ""
          }
          onChange={(e, v) => {
            console.log({ v, e });
            if (setRedeId && v === null) setRedeId("");
            onChange(v);
            if (!!otherProps.onChange) {
              otherProps.onChange(v);
            }
          }}
          onBlur={() => {
            onBlur();
            if (!!otherProps.onBlur) {
              otherProps.onBlur();
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              {...otherProps}
              value={value}
              error={isError}
              helperText={!ignoreError ? errors[name]?.message : undefined}
            />
          )}
        />
      )}
    />
  );
};

export default ControlledAutocomplete;
