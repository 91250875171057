import React from "react";
import NumberFormat from "react-number-format";

export default function PositiveIntegerTextField({
  inputRef,
  thousandSeparator = ".",
  onChange,
  ...other
}) {
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value:
              values.value < 0
                ? 0
                : values.value.length > 4
                ? 9999
                : values.value,
          },
        });
      }}
      min={0}
      max={9999}
      thousandSeparator={thousandSeparator}
      decimalSeparator={","}
      decimalScale={0}
    />
  );
}
