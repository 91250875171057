import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/seguradora.actions";
import { getSeguradora } from "../../selectors/seguradora.selectors";
import SeguradoraPage from "./SeguradoraPage";

const SeguradoraPageConnected = () => {
  const { loading, data } = useSelector(getSeguradora);
  const dispatch = useDispatch();

  const handlePagination = (e, page) => {
    dispatch(
      actions.listarSeguradoras.request({
        pagina: page,
      })
    );
  };

  return (
    <SeguradoraPage
      handlePagination={handlePagination}
      data={data}
      loading={loading}
    />
  );
};

export default SeguradoraPageConnected;
