import React from "react";
import {
  Card,
  IconButton,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useDispatch } from "react-redux";

import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { Edit } from "@mui/icons-material";

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: 75px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
  }
`;

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #b4cfe7ff;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
`;

const StyledTableCellTitle = styled(TableCell)`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: ${({ theme }) => theme.colors.primary};
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 18px;

  border-bottom: none !important;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 850px;
  margin-top: -110px;
`;

const TabelaSeguradoras = ({ data, loading }) => {
  const dispatch = useDispatch();

  if (loading) {
    return <StyledSkeleton />;
  }

  return (
    <StyledCard>
      <StyledH2>Seguradoras</StyledH2>
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableCellTitle>Nome</StyledTableCellTitle>
            <StyledTableCellTitle>Situação</StyledTableCellTitle>
            <StyledTableCellTitle></StyledTableCellTitle>
          </TableRow>
        </TableHead>

        <TableBody>
          {data &&
            data.resultado.map((item, index) => {
              const handleClick = () => {
                dispatch(
                  routeActions.redirectTo(routes.EDITAR_SEGURADORA, {
                    id: item.ID_SEGURADORA,
                  })
                );
              };
              return (
                <StyledTableRow key={index}>
                  <StyledTableCell>{item.NM_SEGURADORA}</StyledTableCell>
                  <StyledTableCell>
                    {item.TP_SITUACAO === "A" ? "Ativa" : "Inativa"}
                  </StyledTableCell>
                  <StyledTableCell align="right">
                    <IconButton onClick={handleClick}>
                      <Edit />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </StyledCard>
  );
};

export default TabelaSeguradoras;
