import BasePage from "../BasePage";
import React from "react";
import { Card, Grid, Pagination, Skeleton, styled } from "@mui/material";
import TabelaHistórico from "./TabelaHistorico";

const StyledBasePage = styled(BasePage)`
  padding: 3px;
`;

const StyledCard = styled(Card)`
  background-color: white;
  width: 100%;
  margin: auto;
  margin-top: 75px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
    margin-top: 50px;
  }
`;
const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 700px;
  margin-top: -80px;
`;

const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  margin-top: 20px;
`;
const StyledPagination = styled(Pagination)`
  .MuiButtonBase-root {
    background-color: #849dce !important;
    color: #ffffff;
  }
  & .Mui-selected {
    background-color: #415781 !important;
  }
`;

const HistoricoCargaPage = ({ data, loading, handlePagination }) => {
  if (loading) {
    return (
      <StyledBasePage isLoggedIn={true} isLightBlue={true}>
        <StyledSkeleton />;
      </StyledBasePage>
    );
  }

  return (
    <StyledBasePage isLoggedIn={true} isLightBlue={true}>
      <StyledCard>
        <StyledH2>Histórico de cargas</StyledH2>
        <Grid container>
          <TabelaHistórico data={data} handlePagination={handlePagination} />
        </Grid>
      </StyledCard>
      <Grid container>
        {data?.total > 0 && (
          <>
            <StyledGridPagination item xs={12} md={12}>
              <StyledPagination
                count={Math.ceil(data.total / 10)}
                page={data.paginaAtual}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </StyledGridPagination>
          </>
        )}
      </Grid>
    </StyledBasePage>
  );
};

export default HistoricoCargaPage;
