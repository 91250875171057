import { actions } from "./administracao.actions";
import { types as routes } from "./rotas.actions";

const initialState = {
  id: null,

  cotacoes: {
    loading: false,
    data: [],
  },
  detalhesCotacao: {
    loading: false,
    data: [],
  },
  integrar: {
    loading: false,
  },
  integrarManualmente: {
    loading: false,
  },
  reload: {
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "@@cotacao/FILTRO_REFRESH":
      return {
        ...state,
        reload: {
          ...state.reload,
          data: action.payload.number,
        },
      };

    case actions.obterCotacoes.REQUEST:
    case actions.obterCotacoes.SUCCESS:
    case actions.obterCotacoes.FAILURE:
      return {
        ...state,
        cotacoes: {
          ...state.cotacoes,
          loading: action.type === actions.obterCotacoes.REQUEST,
          data:
            action.type === actions.obterCotacoes.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case routes.DETALHES_COTACAO:
      return {
        ...state,
        id: action.payload.id,
      };

    case actions.obterDetalhesCotacao.REQUEST:
    case actions.obterDetalhesCotacao.SUCCESS:
    case actions.obterDetalhesCotacao.FAILURE:
      return {
        ...state,
        detalhesCotacao: {
          ...state.detalhesCotacao,
          loading: action.type === actions.obterDetalhesCotacao.REQUEST,
          data:
            action.type === actions.obterDetalhesCotacao.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };

    case actions.integrarManualmente.REQUEST:
    case actions.integrarManualmente.SUCCESS:
    case actions.integrarManualmente.FAILURE:
      return {
        ...state,
        integrarManualmente: {
          ...state.integrarManualmente,
          loading: action.type === actions.integrarManualmente.REQUEST,
        },
      };
    case actions.integrar.REQUEST:
    case actions.integrar.SUCCESS:
    case actions.integrar.FAILURE:
      return {
        ...state,
        integrar: {
          ...state.integrar,
          loading: action.type === actions.integrar.REQUEST,
        },
      };

    default:
      return state;
  }
};

export default reducer;
