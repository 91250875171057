import { ControlledTextField } from "../../components/inputs";
import { PrimaryButton } from "../../components";
import { Person } from "@mui/icons-material";
import Reaptcha from "reaptcha";
import React, { useCallback, useState } from "react";
import { InputAdornment } from "@mui/material";
import { styled } from "@mui/system";
import { Grid } from "@mui/material";

const StyledControlledTextField = styled(ControlledTextField)`

margin-bottom:1px;
margin-top:1px;

flex: 1!important;


  & .MuiInputLabel-root {
    background-color: #415781ff;
    color: #ffffff !important;

    padding: 8px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  
  & .MuiFormHelperText-root.Mui-error {
    color: #f0e500ff!important;

  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #ffffff;
    },
    &:hover fieldset {
      border-color: #ffffff;
    },
    &.Mui-focused fieldset {
      border: 3px solid #ffffff;
    },
    &.Mui-error fieldset {
    border: 2.5px solid #f0e500ff;
    },`;

const StyledButton = styled(PrimaryButton)`
  margin-bottom: 20px;
`;
const StyledButtonBottom = styled(PrimaryButton)``;

const StyledFormGrid = styled(Grid)`
  place-self: flex-start;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const EsqueciSenhaForm = ({
  internal,
  loading,
  formProps,
  handleSubmit,
  handleClickBack,
}) => {
  const [token, setToken] = useState(null);
  const formSubmitHandler = useCallback(
    (values) => {
      if (!!token) {
        handleSubmit(values);
      }
    },
    [handleSubmit, token]
  );
  const verifyCallback = useCallback((t) => {
    setToken(t ?? null);
  }, []);

  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(formSubmitHandler)}
    >
      <StyledFormGrid item xs={12} md={12}>
        <StyledControlledTextField
          label="Usuário"
          name={"user"}
          formProps={formProps}
          margin={"none"}
          InputProps={{
            style: { textTransform: "lowercase" },
            startAdornment: (
              <InputAdornment position="start">
                <Person style={{ color: "#ffffff" }} />
              </InputAdornment>
            ),
          }}
        />
      </StyledFormGrid>
      <Reaptcha
        onVerify={verifyCallback}
        onExpire={verifyCallback}
        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
      />
      <div style={{ marginTop: "15px" }}></div>

      {!internal && (
        <StyledButton
          className="button-alternative"
          label={"VOLTAR"}
          onClick={handleClickBack}
        >
          VOLTAR
        </StyledButton>
      )}
      <StyledButtonBottom loading={loading} type={"submit"}>
        ENVIAR
      </StyledButtonBottom>
    </form>
  );
};

export default EsqueciSenhaForm;
