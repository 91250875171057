import React from "react";
import {
  Card,
  Pagination,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { Grid } from "@mui/material";
import { useDispatch } from "react-redux";

import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import formatters from "../../utils/formatters";

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledGridPagination = styled(Grid)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
`;
const StyledPagination = styled(Pagination)`
  .MuiButtonBase-root {
    background-color: #849dce !important;
    color: #ffffff;
  }
  & .Mui-selected {
    background-color: #415781 !important;
  }
`;
const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  margin-top: 0px;
  margin-bottom: 30px;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
  }
`;

const StyledTable = styled(Table)`
  max-height: 10px !important;
  margin-bottom: 20px;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  cursor: pointer;
  &:nth-of-type(odd) {
    background-color: #b4cfe7ff;
    max-height: 10px;
    color: #ffffff !important;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
`;

const StyledSkeleton = styled(Skeleton)`
  margin-top: -155px;
  width: 100%;
  height: 700px;
`;

const TabelaCotacoes = ({
  dataCotacoes,
  handlePagination,
  tituloPeriodo,
  loading,
}) => {
  const dispatch = useDispatch();
  if (loading) {
    return <StyledSkeleton />;
  }

  if (dataCotacoes.resultado.length === 0) {
    return <></>;
  }

  return (
    <StyledCard>
      <StyledH2>Lista de cotações - {tituloPeriodo}</StyledH2>
      <StyledTable>
        <TableBody>
          {dataCotacoes.resultado &&
            dataCotacoes.resultado.map((item, index) => {
              const handleClick = () => {
                dispatch(
                  routeActions.redirectTo(routes.DETALHES_COTACAO, {
                    id: item.ID_COTACAO,
                  })
                );
              };
              return (
                <StyledTableRow onClick={handleClick} key={index}>
                  <StyledTableCell>#{item.ID_COTACAO}</StyledTableCell>
                  <StyledTableCell>{item.NM_EMPRESA}</StyledTableCell>
                  <StyledTableCell>{item.TOTAL_VIDAS} vidas</StyledTableCell>
                  <StyledTableCell>
                    R$ {formatters.numbers.currency(item.CUSTO_TOTAL)}
                  </StyledTableCell>
                  <StyledTableCell>{item.NM_SEGURADORA}</StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </StyledTable>
      <Grid container>
        {dataCotacoes?.total > 0 && (
          <>
            <StyledGridPagination item xs={12} md={12}>
              <StyledPagination
                count={Math.ceil(dataCotacoes.total / 10)}
                page={dataCotacoes.paginaAtual}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </StyledGridPagination>
          </>
        )}
      </Grid>
    </StyledCard>
  );
};

export default TabelaCotacoes;
