import Header from "./Header";
import { getUsuario } from "../../selectors/usuario.selectors";
import { useSelector, useDispatch } from "react-redux";
import { actions as authActions } from "../../reducers/autenticacao.actions";
import {
  types as routes,
  actions as routeActions,
} from "../../reducers/rotas.actions";
import { useCallback } from "react";
import { actions } from "../../reducers/administracao.actions";

const HeaderConnected = ({ isCotacao, isLoggedIn }) => {
  const { nome, perfil } = useSelector(getUsuario);
  const dispatch = useDispatch();

  const handleClickMenu = useCallback(
    (route) => {
      dispatch(
        actions.filtroRefresh({
          momento: Math.floor(Date.now() / 1000),
        })
      );
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );

  const menus = [
    {
      id: "cotacoes",
      label: "Cotações",
      route: routes.COTACOES,
      action: handleClickMenu,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      route: routes.DASHBOARD,
      action: handleClickMenu,
    },
    {
      id: "configuracoes",
      perfil: ["A"],
      label: "Configurações",

      menus: [
        {
          id: "cadastro",
          perfil: ["A"],
          label: "Cadastro",

          menus: [
            {
              id: "usuario",
              label: "Usuários",
              route: routes.USUARIO,
              perfil: ["A"],
              action: handleClickMenu,
            },
            {
              id: "seguradoras",
              label: "Seguradoras",
              route: routes.SEGURADORA,
              perfil: ["A"],
              action: handleClickMenu,
            },
          ],
        },
        {
          id: "carga",
          perfil: ["A"],
          label: "Carga",

          menus: [
            {
              id: "seguradora",
              label: "Seguradoras",
              route: routes.CARGA_SEGURADORA,
              perfil: ["A"],
              action: handleClickMenu,
            },
            {
              id: "histórico",
              label: "Histórico de cargas",
              route: routes.HISTORICO_CARGA,
              perfil: ["A"],
              action: handleClickMenu,
            },
          ],
        },
      ],
    },
  ];

  const handleClickLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const restrictedMenus = restrictMenus(menus, [], perfil);

  return (
    <Header
      isCotacao={isCotacao}
      isLoggedIn={isLoggedIn}
      menus={restrictedMenus}
      username={nome}
      handleClickLogout={handleClickLogout}
    />
  );
};

const restrictMenus = (menus, current, perfil) => {
  menus.forEach((menu) => {
    if (!menu.perfil) {
      if (!menu.menus) {
        current.push(menu);
      } else {
        const restricted = {
          ...menu,
          menus: restrictMenus(menu.menus, [], perfil),
        };
        current.push(restricted);
      }
    } else {
      const canAccess = menu.perfil.indexOf(perfil) > -1;
      if (canAccess) {
        if (!menu.menus) {
          current.push(menu);
        } else {
          const restricted = {
            ...menu,
            menus: restrictMenus(menu.menus, [], perfil),
          };
          current.push(restricted);
        }
      }
    }
  });
  return current;
};

export default HeaderConnected;
