import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { connectRoutes, NOT_FOUND } from "redux-first-router";
import restoreScroll from "redux-first-router-restore-scroll";
import * as pages from "./business/pages";
import { getCurrentRoute } from "./selectors/routes.selectors";
import { isUsuarioLogado } from "./selectors/usuario.selectors";

import {
  types as routes,
  actions as routeActions,
} from "./reducers/rotas.actions";
import { toast } from "react-toastify";

const routePages = {
  [routes.LOGIN]: {
    component: pages.LoginPage,
  },
  [routes.ESQUECI_SENHA]: {
    component: pages.EsqueciSenhaPage,
  },
  [routes.TROCAR_SENHA]: {
    component: pages.TrocarSenhaPage,
  },
  [routes.COTACAO]: { component: pages.Homepage },
  [routes.COTACOES]: {
    component: pages.CotacoesPage,
    restricted: [],
  },
  [routes.DETALHES_COTACAO]: {
    component: pages.DetalhesCotacaoPage,
    restricted: [],
  },
  [routes.DASHBOARD]: {
    component: pages.DashboardPage,
    restricted: [],
  },
  [routes.USUARIO]: {
    component: pages.UsuarioPage,
    restricted: ["A"],
  },
  [routes.EDIT_USUARIOS]: {
    component: pages.EditUsuarioPage,
    restricted: ["A"],
  },
  [routes.CRIAR_USUARIOS]: {
    component: pages.CriarUsuarioPage,
    restricted: ["A"],
  },
  [routes.CARGA_SEGURADORA]: {
    component: pages.CargaSeguradoraPage,
    restricted: ["A"],
  },
  [routes.HISTORICO_CARGA]: {
    component: pages.HistoricoCargaPage,
    restricted: ["A"],
  },
  [routes.SEGURADORA]: {
    component: pages.SeguradoraPage,
    restricted: ["A"],
  },
  [routes.EDITAR_SEGURADORA]: {
    component: pages.EditarSeguradoraPage,
    restricted: ["A"],
  },

  [NOT_FOUND]: { component: pages.Homepage },
};
export const routePaths = {
  [routes.COTACAO]: "/",
  [routes.LOGIN]: "/login",
  [routes.ESQUECI_SENHA]: "/esqueci-senha",
  [routes.TROCAR_SENHA]: "/trocar-senha/:token",
  [routes.COTACOES]: "/cotacoes",
  [routes.DETALHES_COTACAO]: "/cotacoes/:id",
  [routes.DASHBOARD]: "/dashboard",
  [routes.USUARIO]: "/usuarios",
  [routes.EDIT_USUARIOS]: "/usuarios/:id",
  [routes.CRIAR_USUARIOS]: "/criar-usuarios",
  [routes.CARGA_SEGURADORA]: "/carga/seguradora",
  [routes.HISTORICO_CARGA]: "/carga/historico",
  [routes.SEGURADORA]: "/seguradora",
  [routes.EDITAR_SEGURADORA]: "/seguradora/:id",
};

const { reducer, middleware, enhancer } = connectRoutes(routePaths, {
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  notFoundPath: "/erro",
  onBeforeChange: (dispatch, getState, { action }) => {
    const route = routePages[action.type] ?? routePages[NOT_FOUND];
    if (route.restricted !== undefined) {
      const state = getState();
      if (!isUsuarioLogado(state)) {
        dispatch(routeActions.rejectTo(routes.LOGIN));
      } else {
        if (!canAccessRestrictedRoute(route, state.usuario.perfil)) {
          toast.error("Acesso Negado");
          dispatch(routeActions.rejectTo(routes.COTACOES));
        }
      }
    }
  },
});

export { reducer, middleware, enhancer };

const Container = () => {
  const routeCode = useSelector(getCurrentRoute);
  const route = routePages[routeCode] ?? routePages[NOT_FOUND];
  const Component = route.component;

  return <Component />;
};
const canAccessRestrictedRoute = (route, perfil) => {
  if (!perfil) {
    return false;
  }
  if (route.restricted === true) {
    return true;
  }
  if (Array.isArray(route.restricted)) {
    if (
      route.restricted.length === 0 ||
      route.restricted.indexOf(perfil) > -1
    ) {
      return true;
    }
  }
  return false;
};

export default Container;
