import React from "react";
import {
  Card,
  Skeleton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";

const StyledH2 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: left;
  letter-spacing: 2px;
  font-size: 20px;
  margin: 25px;
  margin-left: 0px;
  margin-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 16px;
  }
`;

const StyledCard = styled(Card)`
  background-color: white;
  height: auto;
  width: 100%;
  margin: auto;
  padding: 0px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  box-shadow: 0px 0px !important;
  border: 1.5px solid #b4cfe7ff;
  padding: 25px;
  @media (max-width: 900px) {
    width: 100wh;
    margin: 0px;
  }
`;

const StyledTable = styled(Table)`
  max-height: 10px !important;
`;

const StyledTableRow = styled(TableRow)`
  padding: 0px;
  height: 35px;
  &:nth-of-type(odd) {
    background-color: #b4cfe7ff;
    max-height: 10px;
  }
`;
const StyledTableCell = styled(TableCell)`
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 16px;
  font-family: Nunito;
  border-bottom: none !important;
`;

const StyledSkeleton = styled(Skeleton)`
  width: 100%;
  height: 700px;
  margin-top: -320px;
`;

const TabelaHospitais = ({ data, loading }) => {
  if (loading) {
    return <StyledSkeleton />;
  }

  return (
    <StyledCard>
      <StyledH2>Hospitais mais cotados</StyledH2>
      <StyledTable>
        <TableBody>
          {data &&
            data.map((item, index) => {
              if (index < 10) {
                return (
                  <StyledTableRow key={index}>
                    <StyledTableCell>{index + 1}°</StyledTableCell>
                    <StyledTableCell>{item.rede}</StyledTableCell>
                    <StyledTableCell>
                      {item.cidade} - {item.regiao}
                    </StyledTableCell>
                    <StyledTableCell>{item.total}</StyledTableCell>
                  </StyledTableRow>
                );
              }
              return <></>;
            })}
        </TableBody>
      </StyledTable>
    </StyledCard>
  );
};

export default TabelaHospitais;
