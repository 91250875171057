import { createTheme } from "@mui/material";

const theme = createTheme({
  backgrounds: {
    opacityWhite: "rgb(255, 255, 255)",
  },
  fonts: {
    family: {
      default: "Nunito",
      title: "Patua One",
      buttons: "Patua One",
      subTitle: "Open Sans",
    },
    size: {
      small: "0.8em",
      default: "1em",
      title: "2em",
      buttons: "20px",
      inputs: {
        title: "1em",
        content: "1em",
      },
      cards: {
        title: "1.6em",
      },
      interactions: {
        polls: {
          question: "1em",
          option: "1em",
          timer: "0.8em",
          result: "0.7em",
        },
      },
    },
  },
  colors: {
    primary: "#324873",
    secondary: "#F24F13",
    lightSecondary: "#e8ac85",
    darkSecondary: "#793f19",
    lightPrimary: "#F7EAEE",
    lightAlternate: "#F4F2DE",
    darkPrimary: "#ED196E",
    error: "#D11F44",
    dark: "#231F20",
    green: "#018E06",
    lightGreen: "#c6dec6",
    gray: "#ACB3C0",
    lightGray: "#dbdce0",
    darkGray: "#444445",
    white: "#FFF",
  },
  buttons: {
    primary: {
      background: "#F24F13",
      backgroundHover: "#BB3D0F",
      color: "#FFF",
    },
    secondary: {
      background: "#203d8b",
      backgroundHover: "#192953",
      color: "#FFF",
    },
  },
  shadows: Array(25).fill("0px 10px 15px #00000029"),
});

export default theme;
