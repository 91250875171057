import React from "react";
import { Box, styled } from "@mui/material";
import { CloudUpload, Delete, PictureAsPdf } from "@mui/icons-material";
import ControlledFileInput from "../../components/inputs/ControlledFileInput";
import formatters from "../../utils/formatters";

const StyledH4 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  letter-spacing: 1.2px;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;
const StyledH3 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 1.6px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledBox = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background: repeating-linear-gradient(
    135deg,
    #ffffffff,
    #ffffffff 30px,
    #ebf2f8ff 30px,
    #ebf2f8ff 60px
  );

  border: 2px dashed #b4cfe7ff;
  border-radius: 15px;
`;
const StyledBoxFile = styled(Box)`
  width: 100% !important;
  height: 200px !important;
  text-align: center;
  background-color: #ebf2f8ff;

  border: 2px solid #b4cfe7ff;
  border-radius: 15px;
`;

const StyledForm = styled("div")`
  width: 300px;
  margin-bottom: 20px;
`;

const filesize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return `${formatters.numbers.nFixed(
      Math.round(size / (1024 * 1024), 1),
      1
    )} Mb`;
  }
  if (size > 1024) {
    // Mb
    return `${formatters.numbers.nFixed(size / 1024, 1)} Kb`;
  }
  return `${size} bytes`;
};

const PDFContainer = ({
  data,
  isAtualizar,
  setIsAtualizar,
  formProps,
  setIsFile,
  isFile,
}) => {
  return (
    <StyledForm>
      <StyledH3>Anexo Proposta</StyledH3>
      {isAtualizar && (
        <>
          <StyledBoxFile
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            <StyledH4>
              <PictureAsPdf
                style={{
                  fontSize: "60px",
                  marginTop: "25px",
                  color: "#F40F02",
                  marginBottom: "-5px",
                }}
              />
            </StyledH4>
            <StyledH4
              style={{
                marginLeft: "15px",
                marginRight: "15px",
                fontSize: `${
                  data?.NM_ARQUIVO_ANEXO_PROPOSTA?.length > 25 ? "10px" : "12px"
                }`,
              }}
            >
              {data.NM_ARQUIVO_ANEXO_PROPOSTA}
            </StyledH4>
            <StyledH4>
              {" "}
              <Delete
                onClick={() => {
                  setIsAtualizar(false);
                  formProps.setValue("fileSeguradoras", undefined);
                }}
                style={{
                  color: "#f00e0e",
                  cursor: "pointer",
                  fontSize: "30px",
                }}
              />
            </StyledH4>
            <StyledH4></StyledH4>
            <StyledH4></StyledH4>
          </StyledBoxFile>
        </>
      )}
      {!isAtualizar && (
        <>
          <ControlledFileInput
            name={"fileSeguradoras"}
            formProps={formProps}
            maxFiles={1}
            accept={".pdf"}
            multiple={false}
            renderFile={(file, index, handleClickDeleteIcon) => {
              setIsFile(false);
              return (
                <StyledBoxFile
                  sx={{
                    width: "100%",
                    height: "100%",
                  }}
                  key={index}
                >
                  <StyledH4>
                    <PictureAsPdf
                      style={{
                        fontSize: "60px",
                        marginTop: "5px",
                        color: "#F40F02",
                        marginBottom: "-5px",
                      }}
                    />
                  </StyledH4>
                  <StyledH4
                    style={{
                      marginLeft: "15px",
                      marginRight: "15px",
                      fontSize: `${file.name.length > 25 ? "10px" : "12px"}`,
                    }}
                  >
                    {file.name}
                  </StyledH4>
                  <StyledH4>{filesize(file.size)}</StyledH4>
                  <StyledH4>
                    <Delete
                      onClick={() => {
                        handleClickDeleteIcon(file);
                        setIsFile(true);
                      }}
                      style={{
                        color: "#f00e0e",
                        cursor: "pointer",
                        fontSize: "30px",
                      }}
                    />
                  </StyledH4>
                </StyledBoxFile>
              );
            }}
          >
            {isFile && (
              <StyledBox
                sx={{
                  cursor: "pointer",
                  width: "100%",
                  height: "100%",
                  "&:hover": {
                    opacity: [0.9, 0.8, 0.7],
                  },
                }}
              >
                <StyledH4 style={{ marginTop: "50px" }}>
                  Arraste o arquivo para cá
                </StyledH4>
                <StyledH4>ou clique para selecioná-lo</StyledH4>
                <StyledH4>(somente arquivos com extensão .pdf)</StyledH4>
                <StyledH4>
                  <CloudUpload
                    style={{ fontSize: "30px", marginTop: "-5px" }}
                  />
                </StyledH4>
              </StyledBox>
            )}
          </ControlledFileInput>
        </>
      )}
    </StyledForm>
  );
};

export default PDFContainer;
