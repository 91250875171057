import React from "react";
import { Box, styled } from "@mui/material";

const StyledIMG = styled("img")`
  max-height: 90px !important;
  padding: 20px;
`;

const StyledBoxImg = styled(Box)`
  width: 100% !important;
  min-height: 90px !important;
  max-height: 90px !important;
  text-align: center;
  border: 2px #b4cfe7ff;
  border-radius: 15px;
  position: relative;
`;

const StyledH1 = styled("h1")`
  font-family: ${({ theme }) => theme.fonts.family.title};
  color: #ffffff;
  font-size: 18px;
  letter-spacing: 1.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
`;

const PlanoLogo = ({ url, seguradora }) => {
  if (url && url !== "") {
    return (
      <StyledBoxImg>
        <StyledIMG src={url} />
      </StyledBoxImg>
    );
  }
  return <StyledH1>{seguradora}</StyledH1>;
};

export default PlanoLogo;
