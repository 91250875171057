import { actions } from "./dashboard.actions";

const initialState = {
  porcentagemDia: {
    loading: false,
    data: [],
  },
  porcentagemMes: {
    loading: false,
    data: [],
  },
  porcentagemAno: {
    loading: false,
    data: [],
  },
  cotacoes: {
    loading: false,
    data: [],
  },
  hospitais: {
    loading: false,
    data: [],
  },
  seguradoras: {
    loading: false,
    data: [],
  },
  seguradoraPlanos: {
    loading: false,
    data: [],
  },
  seguradoraPeriodo: {
    loading: false,
    data: [],
  },
  propostasNaoIntegradas: {
    loading: false,
    data: [],
  },
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.obterPorcentagemDia.REQUEST:
    case actions.obterPorcentagemDia.SUCCESS:
    case actions.obterPorcentagemDia.FAILURE:
      return {
        ...state,
        porcentagemDia: {
          ...state.porcentagemDia,
          loading: action.type === actions.obterPorcentagemDia.REQUEST,
          data:
            action.type === actions.obterPorcentagemDia.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterPorcentagemMes.REQUEST:
    case actions.obterPorcentagemMes.SUCCESS:
    case actions.obterPorcentagemMes.FAILURE:
      return {
        ...state,
        porcentagemMes: {
          ...state.porcentagemMes,
          loading: action.type === actions.obterPorcentagemMes.REQUEST,
          data:
            action.type === actions.obterPorcentagemMes.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterPorcentagemAno.REQUEST:
    case actions.obterPorcentagemAno.SUCCESS:
    case actions.obterPorcentagemAno.FAILURE:
      return {
        ...state,
        porcentagemAno: {
          ...state.porcentagemAno,
          loading: action.type === actions.obterPorcentagemAno.REQUEST,
          data:
            action.type === actions.obterPorcentagemAno.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterCotacoes.REQUEST:
    case actions.obterCotacoes.SUCCESS:
    case actions.obterCotacoes.FAILURE:
      return {
        ...state,
        cotacoes: {
          ...state.cotacoes,
          loading: action.type === actions.obterCotacoes.REQUEST,
          data:
            action.type === actions.obterCotacoes.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterHospitais.REQUEST:
    case actions.obterHospitais.SUCCESS:
    case actions.obterHospitais.FAILURE:
      return {
        ...state,
        hospitais: {
          ...state.hospitais,
          loading: action.type === actions.obterHospitais.REQUEST,
          data:
            action.type === actions.obterHospitais.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterSeguradoras.REQUEST:
    case actions.obterSeguradoras.SUCCESS:
    case actions.obterSeguradoras.FAILURE:
      return {
        ...state,
        seguradoras: {
          ...state.seguradoras,
          loading: action.type === actions.obterSeguradoras.REQUEST,
          data:
            action.type === actions.obterSeguradoras.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterSeguradoraPlanos.REQUEST:
    case actions.obterSeguradoraPlanos.SUCCESS:
    case actions.obterSeguradoraPlanos.FAILURE:
      return {
        ...state,
        seguradoraPlanos: {
          ...state.seguradoraPlanos,
          loading: action.type === actions.obterSeguradoraPlanos.REQUEST,
          data:
            action.type === actions.obterSeguradoraPlanos.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterSeguradoraPeriodo.REQUEST:
    case actions.obterSeguradoraPeriodo.SUCCESS:
    case actions.obterSeguradoraPeriodo.FAILURE:
      return {
        ...state,
        seguradoraPeriodo: {
          ...state.seguradoraPeriodo,
          loading: action.type === actions.obterSeguradoraPeriodo.REQUEST,
          data:
            action.type === actions.obterSeguradoraPeriodo.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    case actions.obterPropostasNaoIntegradas.REQUEST:
    case actions.obterPropostasNaoIntegradas.SUCCESS:
    case actions.obterPropostasNaoIntegradas.FAILURE:
      return {
        ...state,
        propostasNaoIntegradas: {
          ...state.propostasNaoIntegradas,
          loading: action.type === actions.obterPropostasNaoIntegradas.REQUEST,
          data:
            action.type === actions.obterPropostasNaoIntegradas.SUCCESS
              ? action.payload.response.data
              : [],
        },
      };
    default:
      return state;
  }
};

export default reducer;
