import { createAsyncAction } from "../utils/actionCreators";

export const types = {
  OBTER_PORCENTAGEM_DIA: "@@dashboard/OBTER_PORCENTAGEM_DIA",
  OBTER_PORCENTAGEM_MES: "@@dashboard/OBTER_PORCENTAGEM_MES",
  OBTER_PORCENTAGEM_ANO: "@@dashboard/OBTER_PORCENTAGEM_ANO",
  OBTER_COTACAO: "@@dashboard/OBTER_COTACAO",
  OBTER_HOSPITAIS: "@@dashboard/OBTER_HOSPITAIS",
  OBTER_SEGURADORAS: "@@dashboard/OBTER_SEGURADORAS",
  OBTER_SEGURADORA_PLANOS: "@@dashboard/OBTER_SEGURADORA_PLANOS",
  OBTER_SEGURADORA_PERIODO: "@@dashboard/OBTER_SEGURADORA_PERIODO",
  OBTER_PROPOSTAS_NAO_INTEGRADAS: "@@dashboard/OBTER_PROPOSTAS_NAO_INTEGRADAS",
};

export const actions = {
  obterPorcentagemDia: createAsyncAction(types.OBTER_PORCENTAGEM_DIA),
  obterPorcentagemMes: createAsyncAction(types.OBTER_PORCENTAGEM_MES),
  obterPorcentagemAno: createAsyncAction(types.OBTER_PORCENTAGEM_ANO),
  obterCotacoes: createAsyncAction(types.OBTER_COTACAO),
  obterHospitais: createAsyncAction(types.OBTER_HOSPITAIS),
  obterSeguradoras: createAsyncAction(types.OBTER_SEGURADORAS),
  obterSeguradoraPlanos: createAsyncAction(types.OBTER_SEGURADORA_PLANOS),
  obterSeguradoraPeriodo: createAsyncAction(types.OBTER_SEGURADORA_PERIODO),
  obterPropostasNaoIntegradas: createAsyncAction(
    types.OBTER_PROPOSTAS_NAO_INTEGRADAS
  ),
};
