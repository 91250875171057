import React from "react";
import { styled } from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { formatCoin } from "../../utils/formatCoin";

const StyledContainer = styled("div")`
  height: 300px;
  .recharts-cartesian-axis-tick-value {
    font-size: 12px;
    font-family: ${({ theme }) => theme.fonts.family.subTitle};
  }
`;

const StyledToolTipDiv = styled("div")`
  margin: 0px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(204, 204, 204);
  white-space: nowrap;
`;

const StyledTip = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: black;
  text-align: left;
  letter-spacing: 1.2px;
  font-size: 15px;
  font-weight: bold;
  margin: 5px;
`;

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <StyledToolTipDiv className="custom-tooltip">
        <StyledTip>{`${label}`}</StyledTip>
        <StyledTip>{`Quantidade : ${payload[0].value}`}</StyledTip>
        <StyledTip>{`Valor : ${formatCoin(
          payload[0].payload.Valor
        )}`}</StyledTip>
      </StyledToolTipDiv>
    );
  }

  return null;
};

const GraficoBarra = ({ data, colorGraficos }) => {
  return (
    <StyledContainer>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={100}
          height={100}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 0,
            bottom: 5,
          }}
        >
          <XAxis tickMargin={3} interval={0} dataKey="plano" />
          <YAxis />
          <Tooltip
            content={<CustomTooltip />}
            labelStyle={{ color: "black" }}
            itemStyle={{ color: "black" }}
            cursor={{ fill: "transparent" }}
          />
          <Bar barSize={50} fill={colorGraficos} dataKey="Quantidade" />
        </BarChart>
      </ResponsiveContainer>
    </StyledContainer>
  );
};
export default GraficoBarra;
