import React from "react";
import {
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

const StyledH3 = styled("p")`
  font-family: ${({ theme }) => theme.fonts.family.subTitle};
  color: ${({ theme }) => theme.colors.primary};
  letter-spacing: 1.6px;
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 900px) {
    letter-spacing: 1.6px;
    font-size: 12px;
  }
`;

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f2f2f2;
  }
`;
const StyledTablHeadRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #324873;
  }
`;

const StyledTableCell = styled(TableCell)``;

const StyledTableHead = styled(TableHead)``;

const StyledLogoContainer = styled("div")`
  min-height: 40px !important;
  max-height: 40px !important;
  text-align: center;
`;

const StyledIMG = styled("img")`
  max-height: 40px !important;
`;

const StyledGrid = styled(Grid)`
  margin-bottom: 20px !important;
`;
const StyledTable = styled(Table)`
  padding: 40px !important;
  max-width: 270px !important;
`;

const LogoTableContainer = ({ data, loading, fileToRender, isAtualizar }) => {
  return (
    <StyledGrid>
      <StyledH3>Logo PDF</StyledH3>
      <StyledTable>
        <StyledTableHead>
          <StyledTablHeadRow>
            <StyledTableCell>
              <StyledLogoContainer>
                <StyledIMG
                  src={
                    !!data.DS_CAMINHO_LOGO_CARD && isAtualizar
                      ? data.DS_CAMINHO_LOGO_CARD
                      : !!fileToRender
                      ? URL.createObjectURL(fileToRender)
                      : ""
                  }
                />
              </StyledLogoContainer>
            </StyledTableCell>
          </StyledTablHeadRow>
        </StyledTableHead>
        <TableBody>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
          <StyledTableRow>
            <StyledTableCell></StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </StyledTable>
    </StyledGrid>
  );
};

export default LogoTableContainer;
