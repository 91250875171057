import React from "react";
import { useSelector } from "react-redux";
import { showSeguradora } from "../../selectors/seguradora.selectors";
import EditarSeguradoraPage from "./EditarSeguradoraPage";

const EditarSeguradoraPageConnected = () => {
  const { data, loading } = useSelector(showSeguradora);

  return <EditarSeguradoraPage data={data} loading={loading} />;
};

export default EditarSeguradoraPageConnected;
