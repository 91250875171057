import React from "react";
import { useSelector } from "react-redux";
import { getDetalhesCotacao } from "../../selectors/administracao.selectors";
import DetalhesCotacaoPage from "./DetalhesCotacaoPage";

const DetalhesCotacaoPageConnected = () => {
  const { loading, data } = useSelector(getDetalhesCotacao);

  return <DetalhesCotacaoPage loading={loading} data={data} />;
};

export default DetalhesCotacaoPageConnected;
