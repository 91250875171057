import React from "react";
import DashboardPage from "./DashboardPage";

import { useSelector } from "react-redux";
import {
  getPorcentagemDia,
  getPorcentagemMes,
  getPorcentagemAno,
  getCotacoes,
  getHospitais,
  getSeguradoras,
  getPropostasNaoIntegradas,
} from "../../selectors/dashboard.selectors";

const DashboardPageConnected = () => {
  const {
    loading: loadingPorcentagemDia,
    data: dataPorcentagemDia,
  } = useSelector(getPorcentagemDia);
  const {
    loading: loadingPorcentagemMes,
    data: dataPorcentagemMes,
  } = useSelector(getPorcentagemMes);
  const {
    loading: loadingPorcentagemAno,
    data: dataPorcentagemAno,
  } = useSelector(getPorcentagemAno);
  const { loading: loadingCotacoes, data: dataCotacoes } = useSelector(
    getCotacoes
  );
  const { loading: loadingHospitais, data: dataHospitais } = useSelector(
    getHospitais
  );
  const { loading: loadingSeguradoras, data: dataSeguradoras } = useSelector(
    getSeguradoras
  );

  const {
    loading: loadingPropostasNaoIntegradas,
    data: dataPropostasNaoIntegradas,
  } = useSelector(getPropostasNaoIntegradas);

  return (
    <DashboardPage
      dataCotacoes={dataCotacoes}
      dataPorcentagemDia={dataPorcentagemDia}
      dataPorcentagemMes={dataPorcentagemMes}
      dataPorcentagemAno={dataPorcentagemAno}
      dataHospitais={dataHospitais}
      dataSeguradoras={dataSeguradoras}
      dataPropostasNaoIntegradas={dataPropostasNaoIntegradas}
      loadingPorcentagemDia={loadingPorcentagemDia}
      loadingPorcentagemMes={loadingPorcentagemMes}
      loadingPorcentagemAno={loadingPorcentagemAno}
      loadingCotacoes={loadingCotacoes}
      loadingHospitais={loadingHospitais}
      loadingSeguradoras={loadingSeguradoras}
      loadingPropostasNaoIntegradas={loadingPropostasNaoIntegradas}
    />
  );
};

export default DashboardPageConnected;
