import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../reducers/carga.actions";
import { getLabel, getProcessing } from "../../selectors/carga.selectors";
import validators from "../../utils/validators";
import CargaSeguradoraPage from "./CargaSeguradoraPage";

const CargaSeguradoraPageConnected = () => {
  const dispatch = useDispatch();
  const processing = useSelector(getProcessing);
  const label = useSelector(getLabel);

  const rules = {
    fileSeguradoras: validators.array({ required: true }),
  };
  const defaultValues = {
    fileSeguradoras: [],
  };

  const formProps = {
    ...useForm({ defaultValues }),
    rules,
  };

  const handleSubmit = (values) => {
    if (!processing.seguradoras) {
      dispatch(actions.carga_seguradoras.request(values.fileSeguradoras));
    }
  };

  return (
    <CargaSeguradoraPage
      submitting={processing.seguradoras}
      formProps={formProps}
      handleSubmit={handleSubmit}
      label={label}
    />
  );
};

export default CargaSeguradoraPageConnected;
